import { IonCol, IonGrid, IonRow, useIonRouter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { add } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../api/networking';
import DesktopWrapper from '../../../components/DesktopWrapper';
import SkeletonTextThreeLines from '../../../components/SkeletonComponents/SkeletonTextThreeLines';
import BigUp from '../../../components/UI';
import toasters from '../../../components/UI/Toasts';
import { useAppSelector } from '../../../hooks';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import CategoryFormModal from '../../Document/Category/Form/CategoryFormModal';
import ActionColumn from '../../Financials/Subpanels/Columns/ActionColumn';

const TagPage: React.FC = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { tag_id } = useParams<{tag_id: string}>();
  const [documentCategoryFormOpen, setDocumentCategoryFormOpen] = useState<boolean>(false);
  const [selectedDocumentCategory, setSelectedDocumentCategory] = useState<E2U.V1.Models.Type|undefined>();
  const [selectedTag, setSelectedTag] = useState<E2U.V1.Models.Tag|undefined>();
  const [lastTimestamp, setLastTimestamp] = useState<number>(Date.now());
  const selectedProjectId = useAppSelector(state => state.project.selectedProject?.id);

  const fetchTag = () => {
    if (!tag_id) {
      return;
    }
    store.dispatch(setIsLoading({ name: 'tag', value: true }));
    networking.get(`/api/v1/tags/${tag_id}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Tag>) => {
        setSelectedTag(response.data.data);
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'tag', value: false }));
      });
  };

  const handleDetachDocumentCategory = (id: string, name?: string) => {
    toasters.promiseToast(
      networking.delete(`/api/v1/tags/${tag_id}/document_types/${id}`),
      {
        pending: {
          message: t(
            'Detaching document category{name}...',
            {
              name: name ? ` ${name}` : ''
            }
          )
        },
        success: {
          message: t('Document category successfully detached')
        },
        error: {
          message: t('Failed to detach document category. Please try again later or contact our support for further assistance.')
        },
      }
    )
      .then(() => {
        setLastTimestamp(Date.now());
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const handleCategoryCreated = (category: E2U.V1.Models.Type) => {
    networking.post(`/api/v1/tags/${tag_id}/document_types/${category.id}`)
      .then(() => {
        setLastTimestamp(Date.now());
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  useEffect(() => {
    fetchTag();
  }, [tag_id]);

  useEffect(() => {
    fetchTag();
  }, []);

  return (
    <DesktopWrapper>
      {(!selectedProjectId || !tag_id || !selectedTag)
        ? <SkeletonTextThreeLines />
        : <>
          <IonGrid>
            <IonRow>
              <IonCol>
                <BigUp.Label.V2Thick label={t('Document categories for {tagName}', {
                  tagName: selectedTag?.name ?? ''
                })}/>
              </IonCol>
              <IonCol size={'auto'}>
                <BigUp.Buttons.Secondary
                  size={'small'}
                  title={t('Create new')}
                  icon={{
                    icon: add,
                    role: 'button'
                  }}
                  onClick={() => setDocumentCategoryFormOpen(true)}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          <BigUp.Table
            callbacks={{
              onRowClick: (model) => router.push(`/tools/${selectedProjectId}/settings/document-types/${model.id}`)
            }}
            columns={[
              {
                key: 'name',
                label: t('Name'),
                alignment: 'left',
                default_sort_direction: 'asc',
                default_sort: true,
                sortable: true,
                sizes: {
                  xs: '9',
                  sm: '10',
                  md: '11',
                  lg: '11',
                  xl: '11'
                },
                body: <BigUp.LockableNameColumn />
              },
              {
                key: 'actions',
                label: t('Actions'),
                sortable: false,
                body: <ActionColumn
                  type={'tags'}
                  routePrefix={'/settings'}
                  typeLabel={t('Tag')}
                  attributes={{} as E2U.V1.Models.Type}
                  callbacks={{
                    onDelete: (id: string, name?: string) => handleDetachDocumentCategory(id, name ?? ''),
                    onEdit: (model: E2U.V1.Models.Type) => {
                      setSelectedDocumentCategory(model);
                      setDocumentCategoryFormOpen(true);
                    }
                  }}/>,
                sizes: {
                  xs: '3',
                  sm: '2',
                  md: '1',
                  lg: '1',
                  xl: '1'
                },
                alignment: 'right',
              }
            ]}
            reducers={{
            }}
            sourceUrl={`/api/v1/tags/${tag_id}/document_types`}
            timestamp={lastTimestamp}
          />
        </>
      }
      <CategoryFormModal
        onDismiss={() => {
          setSelectedDocumentCategory(undefined);
          setDocumentCategoryFormOpen(false);
        }}
        selectedCategory={selectedDocumentCategory}
        isOpen={documentCategoryFormOpen}
        onSaved={
          (category) => {
            setDocumentCategoryFormOpen(false);
            if (selectedDocumentCategory) {
              setSelectedDocumentCategory(undefined);
              setLastTimestamp(Date.now());
            } else {
              handleCategoryCreated(category);
            }
          }
        }
      />
    </DesktopWrapper>
  );
};

export default TagPage;
