import { Share } from '@capacitor/share';
import * as Sentry from '@sentry/capacitor';

// import toasters from '../components/Toasts/Toasts';
import toasters from '../components/UI/Toasts';
import i18n from '../i18n';

const shareUrl = async (
  title: string | undefined,
  text: string | undefined,
  path: string,
  dialogText: string | undefined,
  type: 'share'|'export'
) => {
  await Share.canShare()
    .then(async (result) => {
      if (result.value) {
        await Share.share({
          title: title || i18n.t('Shared from BigUp'),
          text,
          url: `${path}`,
          dialogTitle: dialogText,
        });
      } else {
        if (type === 'share') {
          navigator.clipboard.writeText(path)
            .then(() => {
              toasters.createToast({
                message: i18n.t('Link copied to clipboard'),
                background: 'var(--ion-color-light)'

              }, 'info');
            })

            .catch((error) => Sentry.captureException(error));
        } else if (type === 'export') {
          window.location.assign(`${path}`);
        }
      }
    })
    .catch((error) => {
      Sentry.captureException(error);
    });
};

export default shareUrl;
