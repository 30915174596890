import { IonButton, IonCol, IonGrid, IonRow, IonSpinner } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import { checkmarkSharp, closeSharp } from 'ionicons/icons';
import React, { useEffect } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { networking } from '../../../../api/networking';
import BigUp from '../../../../components/UI';
import type { UpdateToastersOptions } from '../../../../components/UI/Toasts/interface';
import promiseToast from '../../../../components/UI/Toasts/Promise';
import { useAppSelector } from '../../../../hooks';
import { setSelectedWorksite } from '../../../../reducers/worksites';
import store from '../../../../store';
import type { Worksite } from '../../worksite';
import WorksiteInputs from '../WorksiteInputs';
import WorksiteTextarea from '../WorksiteTextarea';

const WorksiteForm: React.FC<Worksite.WorksiteFormProps> = ({ worksite }) => {
  const { t } = useTranslation();
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const history = useHistory();

  const worksite_defaults = {
    project_id: selectedProject?.id,
    name: worksite?.name || '',
    description: worksite?.description || '',
    address: worksite?.address || '',
    zip_code: worksite?.zip_code || '',
    city: worksite?.city || '',
    country: worksite?.country || '',
    latitude: worksite?.latitude || 0,
    longitude: worksite?.longitude || 0
  };

  const methods = useForm({
    mode: 'onChange',
    defaultValues: worksite_defaults
  });

  const toast_config: UpdateToastersOptions = {
    success: {
      icon: checkmarkSharp,
      background: 'var(--ion-color-light)',
      message: worksite?.id
        ? t(`Updated ${worksite.name}`)
        : t('Created worksite {name}', 'Created worksite {name}', { name: methods.getValues('name') }),
    },
    error: {
      icon: closeSharp,
      background: 'var(--ion-color-light)',
      message: t('Could not save worksite'),
      textColour: 'var(--ion-color-dark)'
    }
  };
  const handleSubmit: SubmitHandler<FieldValues> = async (data) => {
    try {
      worksite?.id
        ? await promiseToast(networking.put(`/api/v1/work_sites/${worksite.id}`, data as any), toast_config)
        : await promiseToast(networking.post('/api/v1/work_sites', data), toast_config)
          .then((response) => {
            store.dispatch(setSelectedWorksite(response.data.data));
            methods.reset();
            history.push(`/tools/${selectedProject?.id}/worksites/${response.data.data.id}/upload`);
          });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const setDefaultValues = () => {
    if (worksite) {
      methods.setValue('name', worksite.name);
      methods.setValue('description', worksite.description);
      methods.setValue('address', worksite.address);
      methods.setValue('zip_code', worksite.zip_code);
      methods.setValue('city', worksite.city);
      methods.setValue('country', worksite.country);
      methods.setValue('latitude', worksite.latitude);
      methods.setValue('longitude', worksite.longitude);
    }
  };

  useEffect(() => {
    setDefaultValues();
  }, [worksite]);

  return (
    <FormProvider {...methods} >
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <IonGrid >
          <IonRow>
            <IonCol>
              <BigUp.Title label={t('Create a worksite')} />
            </IonCol>
          </IonRow>
          <WorksiteInputs />
          <IonRow>
            <IonCol>
              <BigUp.CountrySelect default={worksite?.country} />
            </IonCol>
          </IonRow>
          <WorksiteTextarea />
          <IonRow className='ion-justify-content-center'>
            <IonCol size='8'>
              <BigUp.Buttons.Primary
                expand='block'
                title={t('Save')}
                type='submit'
                disabled={!methods.formState.isValid || methods.formState.isSubmitting}
                loading={methods.formState.isSubmitting}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </form>
    </FormProvider>
  );
};

export default WorksiteForm;
