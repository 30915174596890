import { IonChip, IonIcon } from '@ionic/react';
import classNames from 'classnames';
import fontColorContrast from 'font-color-contrast';
import { closeCircle } from 'ionicons/icons';

import BigUp from '..';
import styles from './FilterChips.module.scss';
import type { ColorPatch } from '../Inputs/ColorPatchPicker/colorPatches';

interface FilterChipsProps<T> {
  data: T[];
  renderChipLabel: (item: T) => React.ReactNode;
  chipIcon?: string;
  onChipClick?: (item: T) => void;
  className?: string;
  color?: ColorPatch;
}

function FilterChips<T>(props: FilterChipsProps<T>) {
  const {
    chipIcon,
    className,
    color,
    data,
    onChipClick,
    renderChipLabel
  } = props;

  const handleChipClick = (item: T) => {
    if (onChipClick) {
      onChipClick(item);
    }
  };

  return (
    <>
      {data.map((chip: T | any, i) => {
        const checkDefaultColours = (chip.color === '#5d7292' || chip.color === '#000000')
          ? 'var(--ion-color-light)'
          : '';
        return (
          <IonChip
            key={i}
            className={classNames(styles['filter-chip'], className, color ? styles[`filter-chip--${color}`] : '')}
            onClick={() => handleChipClick(chip)}
          >
            <BigUp.Label.Regular
              label={renderChipLabel(chip) as string}
              className='ion-no-margin'
            />
            {chipIcon && (
              <IonIcon
                icon={chipIcon || closeCircle}
                onClick={() => handleChipClick(chip)}
              />
            )}
          </IonChip>
        );
      })}
    </>
  );
}

export default FilterChips;
