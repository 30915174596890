import { IonButton, IonCol, IonGrid, IonIcon, IonRow, IonSearchbar, useIonPopover } from '@ionic/react';
import { chevronDownOutline, chevronForwardOutline, chevronUpOutline, filterOutline } from 'ionicons/icons';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Table.module.scss';
import BigUp from '../';
import type { DataItemProps, FilterProps, TableColumnProps, TableProps } from './interfaces';
import useCrudResource from '../../../hooks/useCrudResource';
import useScrollbarWidth from '../../../hooks/useScrollbarWidth';
import type { SourceUrlProps } from '../../../interfaces/SourceUrlProps';
import SkeletonItem from '../../SkeletonComponents/SkeletonItem';

const TableColumn: React.FC<TableColumnProps> = (props) => {
  const classes = [
    styles[`text__${props.alignment ?? 'center'}`],
  ];

  if (props.flex) {
    classes.push(styles.text_flex);
  }

  if (props.className) {
    classes.push(props.className);
  }

  return (
    <IonCol onClick={props.onClick}
      className={classes.join(' ')}
      size={props?.size || null}
      sizeLg={props.sizes?.lg || '4'}
      sizeMd={props.sizes?.md || '4'}
      sizeSm={props.sizes?.sm || '4'}
      sizeXl={props.sizes?.xl || '4'}
      sizeXs={props.sizes?.xs || '4'}
    >
      {props.children}
      {props.actions}
    </IonCol>
  );
};

const Table: React.FC<TableProps> = (props) => {
  const { collapsible, columns, filters, filtersCustom, reducers, sourceUrl, timestamp } = props;
  const { t } = useTranslation();
  const { fetch: crudFetch, parseResponse: parseCrudResponse } = useCrudResource();

  const [rows, setRows] = useState(props.rows ?? []);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [allRows, setAllRows] = useState(props.rows ?? []);
  const [isLoading, setIsLoading] = useState(props.loading ?? false);
  const [filtersCustomToggled, setFiltersCustomToggled] = useState(false);
  const [userSelectedSortColumn, setUserSelectedSortColumn] = useState<number | undefined>(undefined);
  const [userSelectedSortDirection, setuserSelectedSortDirection] = useState<'asc' | 'desc' | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const tableHead = useRef<HTMLIonRowElement>(null);
  const tableBody = useRef<HTMLDivElement>(null);
  const [tableScrollYPosition, setTableScrollYPosition] = useState(0);
  const scrollBarWidth = useScrollbarWidth();
  const [lastFetchProps, setLastFetchProps] = useState<string|undefined>(undefined);
  const [present] = useIonPopover(BigUp.Popovers.Default, {
    items: (filters ?? []).map((filter: FilterProps) => {
      return {
        ...filter,
        value: filter.label,
        onClick: () => {
          filter.callback(allRows, setRows);
        },
      };
    })
  });
  const autoSortedColumns = useMemo(() => {
    let correctSortColumn;
    let correctSortDirection;
    columns.forEach((column, i) => {
      if (column?.default_sort) {
        correctSortColumn = i;

        if (column.default_sort_direction) {
          correctSortDirection = column.default_sort_direction;
        }

        return false;
      }
    });
    return {
      correctSortColumn,
      correctSortDirection
    };
  }, [columns]);
  const currentSortColumnIndex = useMemo(() => {
    if (userSelectedSortColumn === undefined) {
      return autoSortedColumns.correctSortColumn;
    }

    return userSelectedSortColumn;
  }, [userSelectedSortColumn, autoSortedColumns.correctSortColumn]);
  const currentSortDirection = useMemo(() => {
    if (userSelectedSortDirection === undefined) {
      return autoSortedColumns.correctSortDirection;
    }

    return userSelectedSortDirection;
  }, [userSelectedSortDirection, autoSortedColumns.correctSortDirection]);

  const fetchRows = (
    url: string | SourceUrlProps
  ) => {
    setIsLoading(true);
    const { correctSortColumn, correctSortDirection } = autoSortedColumns;

    let args = typeof url === 'string' ? {} : url.args;
    const sortDirectionToUse = typeof userSelectedSortDirection === 'undefined' ? correctSortDirection : userSelectedSortDirection;
    const sortColumnToUse = typeof userSelectedSortColumn === 'undefined' ? correctSortColumn : userSelectedSortColumn;
    if (
      typeof sortColumnToUse !== 'undefined' &&
      columns[sortColumnToUse]?.key &&
      typeof sortDirectionToUse !== 'undefined'
    ) {
      const newSortBy = typeof args.sort_by !== 'undefined'
        ? (
          Array.isArray(args.sort_by)
            ? [...args.sort_by, columns[sortColumnToUse]?.key]
            : [args.sort_by, columns[sortColumnToUse]?.key]
        )
        : [
          columns[sortColumnToUse]?.key
        ];
      args = {
        direction: sortDirectionToUse,
        sort_by: newSortBy
      };
    }
    if (searchQuery !== null && searchQuery.length) {
      args = {
        ...args,
        search: searchQuery
      };
    }

    const newLastFetchProps = JSON.stringify({ url, args, timestamp });
    if (lastFetchProps === newLastFetchProps) {
      setIsLoading(false);
      return;
    }
    setLastFetchProps(newLastFetchProps);

    crudFetch(url, args).then((responseRaw) => {
      const rows = parseCrudResponse(responseRaw);

      setRows(rows);
      setAllRows(rows);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const runSearch = (query: string) => {
    setSearchQuery(query);
  };

  const runSort = (i: number) => {
    setUserSelectedSortColumn(i);
    setuserSelectedSortDirection(userSelectedSortDirection === 'asc' ? 'desc' : 'asc');
  };

  const getValueByDotNotation = (
    row: { [key: string]: any },
    key: string,
    strict = false
  ) => {
    if (key.includes('.')) {
      let value = row;

      key.split('.').forEach((key) => {
        value = value[key];
      });

      return value;
    }

    return row[key] ?? (strict ? null : key);
  };

  const handleBodyScroll = () => {
    if (tableBody.current) {
      const { scrollLeft } = tableBody.current;
      setTableScrollYPosition(scrollLeft || 0);
    }
  };

  const handlePropertiesChange = () => {
    if (!sourceUrl) {
      return;
    }
    fetchRows(sourceUrl);
  };

  useEffect(() => {
    handlePropertiesChange();
  }, [sourceUrl, userSelectedSortColumn, userSelectedSortDirection, timestamp]);

  useEffect(() => {
    if (tableHead.current) {
      tableHead.current.scrollLeft = tableScrollYPosition;
    }
  }, [tableScrollYPosition]);

  useEffect(() => {
    if (sourceUrl && searchQuery !== null) {
      if (props.callbacks?.onSearch) {
        props.callbacks?.onSearch(searchQuery, sourceUrl);
      } else {
        fetchRows(sourceUrl);
      }
    }
  }, [searchQuery]);

  useEffect(() => {
    const callback = props.callbacks?.onRenderComplete;

    if (callback && !searchQuery) {
      callback(rows, setRows);
    }
  }, [allRows, searchQuery]);

  return (
    <IonGrid>
      <IonRow className={styles.wrapper}>
        <IonCol className={styles.container}>
          <div className={styles.header}>
            <IonRow>
              <IonCol sizeMd={(filters || filtersCustom) ? '11' : '12'}
                sizeXs={(filters || filtersCustom) ? '10' : '12'}>
                <div className={styles.header__search}>
                  <IonSearchbar onIonInput={(e) => runSearch(e.detail.value as string)}
                    animated={false}
                    placeholder={t('Search')}
                    className={styles.header__search_box}></IonSearchbar>
                </div>
              </IonCol>
              {(filters || filtersCustom) && (
                <IonCol sizeMd='1'
                  sizeXs='2'
                  className={styles.header__filters}
                  onClick={(e) => filtersCustom
                    ? setFiltersCustomToggled(!filtersCustomToggled)
                    : present({
                      event: e.nativeEvent,
                      dismissOnSelect: true
                    })}
                >
                  <IonIcon
                    icon={filterOutline}
                    className={styles.header__filters_icon}
                  />
                </IonCol>
              )}
            </IonRow>
            {filtersCustom && filtersCustomToggled && (
              <IonRow>
                <IonCol size='12'>
                  {filtersCustom}
                </IonCol>
              </IonRow>
            )}
            <IonRow ref={tableHead} className={styles.header__headings} style={{
              marginRight: `${(tableBody.current && tableBody.current.scrollHeight > tableBody.current.clientHeight) ? scrollBarWidth : 0}px`,
            }}>
              {columns.map((column: DataItemProps, i) => {
                const { key, label, sortable, ...rest } = column;

                return (
                  <TableColumn key={i}
                    onClick={() => sortable ? runSort(i) : null}
                    className={`${styles.header__headings_column} ${sortable ? styles.header__headings_column_sortable : ''}`} {...rest}>
                    {label}
                    {sortable && <IonIcon
                      icon={(currentSortColumnIndex === i && currentSortDirection === 'desc') ? chevronUpOutline : chevronDownOutline}
                      className={styles.header__headings_actions_sort} />}
                  </TableColumn>
                );
              })}
            </IonRow>
          </div>

          <div className={styles.body}>
            <div id="body"
              ref={tableBody}
              onScroll={() => handleBodyScroll()}
              className={styles.body__values}
              style={{
                maxHeight: rows.length ? (props.height || '535px') : 'auto',
              }}
            >
              {isLoading
                ? <SkeletonItem
                  sizes={{
                    sizeXs: '12',
                    sizeSm: '12',
                    sizeMd: '12',
                    sizeLg: '12'
                  }}
                  amount={5}
                />
                : (
                  rows.length
                    ? rows.map((row, a) => {
                      const { onRowClick } = props.callbacks ?? {};
                      const classes = [styles.body__values_row];
                      const expanded = expandedRow === a;

                      const collapsibleEnabled = collapsible && (!collapsible.rowIsEnabled || collapsible.rowIsEnabled(row));

                      if ((a % 2 === 0)) {
                        classes.push(styles.body__values_oddrow);
                      }

                      if (onRowClick) {
                        classes.push(styles.body__values_row_clickable);
                      }

                      let collapse;
                      if (collapsibleEnabled && expandedRow === a) {
                        collapse = React.createElement(
                          collapsible.component, {
                            ...{ [collapsible.componentKey]: row[collapsible.key] },
                            row,
                          });
                      }

                      return (
                        <React.Fragment key={a}>
                          <IonRow
                            onClick={(e) => {
                              if (onRowClick) {
                                const target = e.target as HTMLElement;

                                if (!target.dataset?.clickable) {
                                  onRowClick(row);
                                }
                              }
                            }}
                            className={classes.join(' ')}>
                            {columns.map((column: DataItemProps, b) => {
                              const { key, ...rest } = column;

                              let body = (row[key] ?? getValueByDotNotation(row, key, true));

                              if (typeof reducers !== 'undefined' && reducers[key]) {
                                body = reducers[key](body);
                              }
                              if (column.body) {
                                if (typeof column.body === 'string') {
                                  body = React.createElement(column.body, {
                                    value: body,
                                    attributes: row,
                                  });
                                } else if (typeof column.body === 'function') {
                                  body = React.cloneElement(column.body(row), {
                                    value: body,
                                    attributes: row,
                                  });
                                } else {
                                  body = React.cloneElement(column.body, {
                                    value: body,
                                    attributes: row,
                                  });
                                }
                              }

                              return (
                                <TableColumn key={b}
                                  className={[styles.body__values_column].join(' ')}
                                  flex={column.flex} {...rest}>
                                  <span>{body ?? <i>{t('Missing value')}</i>}</span>
                                  {collapsible && (b === columns.length - 1) && (
                                    <IonButton fill="clear" size={'default'} className={'ion-no-margin'}
                                      disabled={!collapsibleEnabled} onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (expandedRow === a) {
                                          setExpandedRow(null);
                                          return;
                                        }
                                        setExpandedRow(a);
                                      }}>
                                      <IonIcon
                                        className={styles.body__cheron_icon}
                                        size={'default'}
                                        icon={
                                          expanded
                                            ? chevronDownOutline
                                            : chevronForwardOutline
                                        }
                                      />
                                    </IonButton>
                                  )}
                                </TableColumn>
                              );
                            })}
                          </IonRow>
                          {collapsibleEnabled && expandedRow === a && (
                            <IonRow key={a + '_collapsible'}
                              className={classes.join(' ')}>
                              <IonCol size="12">
                                {collapse}
                              </IonCol>
                            </IonRow>
                          )}
                        </React.Fragment>
                      );
                    })
                    : (
                      <IonRow className={styles.body__values_empty}>
                        <IonCol className={styles.body__values_empty_text}>
                          {t('No data to display')}
                        </IonCol>
                      </IonRow>
                    )
                )}
            </div>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export const useTableStyles = () => {
  return styles;
};

export default Table;
