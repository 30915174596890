import classNames from 'classnames';
import React from 'react';

import styles from './Badge.module.scss';

interface BadgeProps {
  active?: boolean;
  size?: 'small' | 'normal';
}

const Badge: React.FC<React.PropsWithChildren<BadgeProps>> = (props) => {
  const { active, children, size } = props;
  return (
    <div className={classNames(styles['icon-badge'], active && styles['icon-badge--active'], size === 'small' && styles['icon-badge--small'])}>
      {children}
    </div>
  );
};

export default Badge;
