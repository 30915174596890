// Generated with: https://gdoc2md.com/
export const termsAndConditions = `

## Allmänna villkor

Dessa villkor ("Villkor") gäller när BigUp AB ("BigUp") tillhandahåller ett projektledningssystem över Internet ("Tjänsten") till ett företag ("Kunden") på sin webbplats och via appar, om inte ett separat skriftligt avtal finns. Tjänsten är ett webbaserat/mobilbaserat datasystem.

Genom att registrera ett användarkonto och/eller använda Tjänsten accepterar Kunden dessa villkor för rätten att använda Tjänsten. En användare som registrerar ett konto intygar vid registreringen att han eller hon är behörig av Kunden att acceptera dessa villkor.


## 1 - Användarkonto

Vid registrering för Tjänsten skapas ett personligt användarkonto med ett användar-ID och ett lösenord. Användaren kan när som helst ändra sitt lösenord. Användaren är ansvarig för att hålla inloggningsuppgifterna på ett säkert sätt så att obehöriga inte kan komma åt dem. Om användaren misstänker att en obehörig person har fått tillgång till inloggningsuppgifterna eller användarkontot, ska användaren omedelbart ändra sitt lösenord. Kunden är ansvarig för all användning av Tjänsten som sker från användarkonto(n) som tillhör Kundens användare. Användare som registrerar ett användarkonto är ansvariga för att ha Kundens behörighet att ingå avtal och att utföra de åtgärder som användarna vidtar.


## 2 - Tjänsten

Tjänsten beskrivs på BigUps webbplats. BigUp förbehåller sig rätten att vidareutveckla Tjänstens omfattning, design och funktionalitet. BigUp förbehåller sig rätten att upphöra med att tillhandahålla Tjänsten med trettio (30) dagars varsel. Kunden har inte rätt till någon återbetalning av inbetalda prenumerationsavgifter.


## 3 - Pris

Priset för Tjänsten regleras av särskilt avtal, med förbehåll för följande villkor. BigUp förbehåller sig rätten att ändra priset för Tjänsten när som helst. Prisändringar träder i kraft trettio (30) dagar efter att BigUp meddelar en sådan ändring. Ändringen träder i kraft vid nästa betalningsdatum för Kunder som har en giltig prenumeration. Ytterligare konsultation, utbildning eller modifiering av systemet debiteras med en timtaxa på 1 100 SEK/timme. Alla relaterade levnadskostnader och utgifter på grund av ovanstående debiteras. Vid resor är milersättningen 35 SEK och 500 SEK/timme för restid.


## 4 - Betalning

BigUp har rätt att debitera Kunden antingen via faktura eller med ett kreditkort registrerat av Kunden. BigUp har rätt att debitera Kunden för tjänsten i förskott. Om betalning inte sker kan Tjänsten stängas av utan ytterligare varsel.


## 5 - Användning av tjänsten / God praxis-standarder

Tjänsten får endast användas för det syfte och i den omfattning som anges i dessa villkor. Kunden och deras användare får inte:

Kopiera eller ändra material som rör Tjänsten, eller överföra eller tilldela rätten till Tjänsten eller materialet till en annan part;

publicera eller distribuera material eller meddelanden som orsakar skada eller annan olägenhet för BigUp eller tredje part, bryter mot BigUps eller tredje parts upphovsrätt eller andra immateriella rättigheter, strider mot lag eller myndighetsföreskrift eller beslut, eller strider mot moralisk kod eller mot BigUps vid tidpunkten gällande regler och policyer;

utge sig för att vara en annan person eller felaktigt utge sig för att representera ett företag, eller försöka få tillgång till en annan användares konto, eller på annat sätt agera bedrägligt i förhållande till BigUp eller en leverantör;

ta bort, kringgå, inaktivera, skada eller på annat sätt manipulera säkerhetsrelaterade funktioner i Tjänsten;

Kopiera eller på annat sätt försöka avslöja eller återskapa källkoden till Tjänsten; eller

störa eller skada tjänstens funktionalitet eller kapacitet.


## 6 - Support

Öppettider för support finns på BigUps webbplats. Support ges via telefon, chatt eller e-post enligt informationen på webbplatsen. Supportfrågor via chatt och e-post besvaras vanligtvis senast nästa arbetsdag. Supportärenden som tas emot via telefon prioriteras efter ankomsttid. Dagen före helgdag förbehåller sig BigUp rätten att hålla support stängd. Om detta inträffar meddelas detta på BigUps webbplats.


### Exempel på vad som ingår:

Support vid användning av programmets funktionalitet

Felmeddelanden

Support vid programfel


### Exempel på vad som inte ingår:

Frågor utanför programmets funktionalitet

Administration eller konfiguration av programmet

Konsulttjänster

Support för andra program och system, såsom Windows, MS Office, skrivare och e-postservrar.


## 7 - Kundinformation

BigUp kan ändra inloggningsuppgifterna av tekniska, operativa eller andra specifika skäl eller på grund av myndighetsföreskrift eller beslut. Kunden ska i förväg informeras om sådan ändring. Kunden ska på BigUps begäran tillhandahålla de uppgifter som BigUp behöver för tjänsten. Kunden ansvarar för att sådan information är korrekt vid tidpunkten och att Kundens användare har informerats om att deras data lämnas till BigUp och för vilka ändamål BigUp behandlar denna information. Kunden intygar att de har rätt att tillhandahålla BigUp med sådan data. Kunden har läst och accepterar BigUps integritetspolicy och personuppgiftsbiträdesavtal, som finns tillgängliga via följande länkar:

Integritetspolicy

Personuppgiftsbiträdesavtal


## 8 - Stängning och avregistrering av användarkonto

BigUp förbehåller sig rätten att omedelbart och utan varsel stänga Kundens konto(n) vid misstanke om brott mot avsnitt 5. Efter stängning eller avregistrering av Kundens konto(n) kommer Kunden inte längre att kunna köpa, få tillgång till eller administrera Tjänsten. På Kundens begäran kan BigUp mot en avgift extrahera data i CSV- eller Excel-format. Sådana begäranden måste göras innan kontot stängs. BigUp förbehåller sig rätten att säga upp Kundens konto med tre (3) månaders varsel.


## 9 - Avtalsperiod och uppsägning

Avtalet för tillhandahållande av tjänster gäller i ett år med en uppsägningstid på 60 dagar. Om avtalet inte sägs upp förlängs avtalet med ett år i taget. Uppsägning från Kunden måste göras skriftligen via e-post till **jorgen\@e2c.se**;. Vid ökning av antalet användare under avtalsperioden skapas en ny avtalsperiod för det nyss tillagda antalet användare. En minskning av antalet användare är endast möjlig efter att avtalsperioden har löpt ut. Om Kunden önskar minska prenumerationen och täcka färre användare måste detta meddelas skriftligen senast 60 dagar före nästa avtalsperiod. Observera att Kunden är ansvarig för att spara det elektroniska kvittot på det uppsagda avtalet, som alltid skickas till Kunden via e-post.


## 10 - Immateriella rättigheter

Användningen av Tjänsten innebär inte att någon upphovsrätt eller andra immateriella rättigheter överförs till Kunden.


## 11 - Skadestånd

Kunden har ingen rätt till ersättning för indirekt skada, såsom förlorad inkomst, oåterkalleliga kostnader eller andra följdskador. BigUps totala ansvar för varje fullt kalenderår är begränsat till ett belopp motsvarande hälften av ett svenskt prisbasbelopp. Oaktat ovanstående ansvarar BigUp inte för förlust eller skada av data eller annan information under användningen av Tjänsten. BigUp ansvarar inte heller för skada orsakad av datavirus eller liknande, försening, datakorruption, dataförlust eller för Kundens eventuella ansvar gentemot tredje part.


## 12 - Ändring av användarvillkor

BigUp har rätt att när som helst ändra dessa Villkor. En ändring träder i kraft trettio (30) dagar efter att BigUp, via e-post, meddelar en sådan ändring.


## 13 - Klagomål

För att ett klagomål ska vara giltigt ska Kunden lämna in klagomålet inom två (2) månader efter att felet eller skadan upptäckts eller borde ha upptäckts. Klagomål måste göras skriftligen.


## 14 - Force majeure

Parterna är befriade från ansvar för att betala skadestånd eller att fullgöra vissa förpliktelser enligt detta avtal, om skadan eller underlåtenheten beror på omständigheter utanför partens kontroll ("Force Majeure-händelse") och omständigheten förhindrar, väsentligt försvårar eller försenar fullgörandet därav. Detsamma gäller om skadan eller underlåtenheten beror på försenade leveranser från en parts underleverantör som orsakas av en Force Majeure-händelse. En Force Majeure-händelse kan bland annat vara myndighetsåtgärd eller underlåtenhet, ny eller ändrad lagstiftning, arbetskonflikt, blockad, krig, upplopp, sabotage, extremt väder, blixtnedslag, brand, explosion, översvämning, naturkatastrof, olycka eller kabelskada orsakad av tredje part.


## 15 - Tillämplig lag och tvist

Parternas rättigheter och skyldigheter vid tolkning och tillämpning av detta avtal ska ske i enlighet med svensk lag. Eventuell tvist, kontrovers eller krav som uppstår ur eller i samband med detta avtal, eller brott, uppsägning eller ogiltighet därav, ska slutligen avgöras genom skiljedom i enlighet med den svenska lagen om skiljeförfarande. Skiljenämnden ska bestå av en ensam skiljeman. Skiljeförfarandets plats är Norrköping. Språket som ska användas i skiljeförfarandet ska vara svenska.


## 16 - Giltighet

Dessa villkor gäller från och med den 16 augusti 2024 tills vidare.

\


Denna version av texten har uppdaterats för att reflektera BigUp AB som avsändare och inkluderar de nya datumen från 2024.

\
\
\


användarvillkor BigUp

När ditt företag blir användare av BigUp, godkänner ni BigUps användarvillkor och tar del av BigUps tillämpning av GDPR.

Utöver detta måste varje användare (medarbetare, underentreprenör, partner) godkänna användarvillkoren. Detta gör man genom att börja använda systemet eller appen.


För att använda fältrapportering och annan rapportering optimalt, behöver platsåtkomst /GPS vara påslagen i mobilen. Detta är en inställning i användarens mobil utanför BigUps kontroll.


Om företag kontots inställningar "Kräv platsspårning för rapporter" är aktiverade, behövs detta medgivande. Det är upp till företaget (arbetsgivaren/uppdragsgivaren) om rapportering ska förutsätta att platstjänsten påslagen i mobilapparna.
`;
