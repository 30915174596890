
import React from 'react';

import type { LabelProps } from './Labels/interface';
import type { ColourVariables } from './variables';

import BigUp from '.';

interface CalculationLegendProps {
  label: {
    start: LabelProps;
    end: LabelProps;
  }
  color: ColourVariables;
}
const CalculationLegend: React.FC<CalculationLegendProps> = (props) => {
  return (
    <BigUp.Label.Indicator
      label={{
        gap: '8px',
        start: { label: props.label.start.label },
        end: { label: props.label.end.label }
      }}
      indicator={{ color: props.color, shape: 'rectangular' }}
    />
  );
};

export default CalculationLegend;
