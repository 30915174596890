import { IonCol, IonGrid, IonRow, useIonRouter } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { add } from 'ionicons/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import TagGroupFormModal from './Group/TagGroupFormModal';
import TagGroupTitleColumn from './GroupTitleColumn';
import { networking } from '../../api/networking';
import DesktopWrapper from '../../components/DesktopWrapper';
import SkeletonTextThreeLines from '../../components/SkeletonComponents/SkeletonTextThreeLines';
import BigUp from '../../components/UI';
import toasters from '../../components/UI/Toasts';
import { useAppSelector } from '../../hooks';
import { setIsLoading } from '../../reducers/loading';
import store from '../../store';
import type { DocumentCategory } from '../Document/Category/Form/CategoryFormModal';
import ActionColumn from '../Financials/Subpanels/Columns/ActionColumn';

const TagGroupsPage: React.FC = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const selectedProjectId = useAppSelector(state => state.project.selectedProject?.id);
  const [categoryFormModalOpen, setCategoryFormModalOpen] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<E2U.V1.Models.TagCategory | undefined>();
  const [lastTimestamp, setLastTimestamp] = useState<number>(Date.now());

  const handleDeleteTagCategory = (id: string, name?: string) => {
    toasters.promiseToast(
      networking.delete(`/api/v1/projects/${selectedProjectId}/tag_categories/${id}`),
      {
        pending: {
          message: t(
            'Removing tag group{name}...',
            {
              name: name ? ` ${name}` : ''
            }
          )
        },
        success: {
          message: t('Group successfully removed')
        },
        error: {
          message: t('Failed to remove tag group. Please try again later or contact our support for further assistance.')
        },
      }
    )
      .then(() => {
        setLastTimestamp(Date.now());
      })
      .catch((error) => {
        store.dispatch(setIsLoading({ isLoading: false }));
        console.error(error);
      });
  };

  return (
    <DesktopWrapper>
      {(!selectedProjectId)
        ? <SkeletonTextThreeLines />
        : <>
          <IonGrid>
            <IonRow>
              <IonCol>
                <BigUp.Label.V2Thick label={t('Tag groups')} />
              </IonCol>
              <IonCol size={'auto'}>
                <BigUp.Buttons.Secondary
                  size={'small'}
                  title={t('Create new')}
                  icon={{
                    icon: add,
                    role: 'button'
                  }}
                  onClick={() => setCategoryFormModalOpen(true)}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          <BigUp.Table
            callbacks={{
              onRowClick: (model) => router.push(`/tools/${selectedProjectId}/settings/tags/${model.id}`)
            }}
            columns={[
              {
                key: 'name',
                label: t('Name'),
                alignment: 'left',
                default_sort_direction: 'asc',
                default_sort: true,
                sortable: true,
                sizes: {
                  xs: '9',
                  sm: '10',
                  md: '11',
                  lg: '11',
                  xl: '11'
                },
                body: <TagGroupTitleColumn />
              },
              {
                key: 'actions',
                label: t('Actions'),
                sortable: false,
                body: <ActionColumn
                  type={'tags'}
                  routePrefix={'/settings'}
                  typeLabel={t('Tag group')}
                  attributes={{} as E2U.V1.Models.TagCategory}
                  callbacks={{
                    onDelete: (id: string, name?: string) => handleDeleteTagCategory(id, name ?? ''),
                    onEdit: (model: DocumentCategory) => {
                      setSelectedCategory(model);
                      setCategoryFormModalOpen(true);
                    }
                  }} />,
                sizes: {
                  xs: '3',
                  sm: '2',
                  md: '1',
                  lg: '1',
                  xl: '1'
                },
                alignment: 'right',
              }
            ]}
            reducers={{
            }}
            sourceUrl={{
              url: `/api/v1/projects/${selectedProjectId}/tag_categories`,
              args: {
                filters: JSON.stringify([
                  {
                    field: 'is_document_type',
                    value: false
                  }
                ])
              }
            }}
            timestamp={lastTimestamp}
          />
        </>
      }
      <TagGroupFormModal
        onDismiss={() => {
          setSelectedCategory(undefined);
          setCategoryFormModalOpen(false);
        }}
        selectedCategory={selectedCategory}
        isOpen={categoryFormModalOpen}
        onSaved={
          (category) => {
            setCategoryFormModalOpen(false);
            setLastTimestamp(Date.now());
            if (selectedCategory) {
              setSelectedCategory(undefined);
            } else {
              router.push(`/tools/${selectedProjectId}/settings/tags/${category.id}`);
            }
          }
        }
      />
    </DesktopWrapper>
  );
};

export default TagGroupsPage;
