import { IonButton, IonCol, IonGrid, IonIcon, IonRow, useIonRouter } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { chevronBack, downloadOutline } from 'ionicons/icons';
import fileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../api/networking';
import BigUp from '../../../components/UI';
import toasters from '../../../components/UI/Toasts';
import { useAppSelector } from '../../../hooks';
import i18n from '../../../i18n';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';

interface PreviewHeaderProps {
  fileMetaData: E2U.V1.Models.File & { mime_type: string; } | null
}

const PreviewHeader: React.FC<PreviewHeaderProps> = (props) => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  const downloadFile = (file: PreviewHeaderProps['fileMetaData']) => {
    if (file === null || typeof file.download_url === 'undefined' || typeof file === 'undefined' || typeof file.path === 'undefined') return;
    store.dispatch(setIsLoading({ key: 'downloading_file', value: true }));
    toasters.promiseToast(
      networking.get(file.download_url, {
        responseType: 'blob'
      }), {
        pending: { message: i18n.t('Downloading file') },
        success: { message: i18n.t('Downloaded Document') },
        error: { message: i18n.t('Error downloading file') }
      })
      .then((response: any) => {
        fileDownload(response.data, file.path);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ key: 'downloading_file', value: false }));
      });
  };

  const handleNavigateBack = () => {
    if (router.canGoBack()) {
      router.goBack();
      return;
    }
    router.push('/tools');
  };

  return (
    <>
      {isDesktop && (
        <IonGrid className='ion-margin-top'>
          <IonRow className='ion-justify-content-between ion-align-items-center'>
            <IonCol size='auto'>
              <IonButton size='default' color='dark' fill={'clear'} onClick={handleNavigateBack}>
                <IonIcon color='dark' slot={'start'} icon={chevronBack} />
                {t('Back')}
              </IonButton>
            </IonCol>
            <IonCol size='auto'>
              <BigUp.Title label={props.fileMetaData?.name} />
            </IonCol>
            <IonCol size='auto' className='ion-margin-end'>
              <IonButton
                size='default'
                color='dark'
                fill='clear'
                onClick={() => props.fileMetaData && downloadFile(props.fileMetaData)}
              >
                <IonIcon icon={downloadOutline} slot='end' />
                {t('Download')}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </>
  );
};

export default PreviewHeader;
