import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslation } from 'react-i18next';

import { networking } from '../api/networking';
import toasters from '../components/Toasts/Toasts';
import type { SourceUrlProps } from '../interfaces/SourceUrlProps';

export interface BaseCrudResponseProps {
  id: string;
  name: string;
}

const useCrudResource = () => {
  const { t } = useTranslation();
  const fetch = async (url: string | SourceUrlProps, defaultArgs?: object) => {
    let args = { per_page: 9999, ...defaultArgs };

    if (typeof url === 'object') {
      args = {
        ...args,
        ...url.args
      };
      url = url.url;
    }

    const urlSearchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(args)) {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          urlSearchParams.append(key, v);
        });
      } else {
        urlSearchParams.set(key, value);
      }
    }

    return toasters.promise(
      networking.get(`${url}?${urlSearchParams.toString()}`), {
        error: t('Something went wrong. Please try again later')
      }
    ).catch((error: E2U.V1.Response.Error) => {
      Sentry.captureException(error);
    });
  };

  const parseResponse = (response: E2U.V1.Response.Success<any>): BaseCrudResponseProps[] => {
    if (!response) {
      return [];
    }

    if (typeof response.data?.data?.current_page !== 'undefined') {
      return response.data?.data?.records;
    } else if (typeof response.data?.data?.rows !== 'undefined') {
      return response.data?.data?.rows;
    }

    return response.data?.data;
  };

  return {
    fetch,
    parseResponse
  };
};

export default useCrudResource;
