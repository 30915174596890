import { IonContent } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import styles from './styles/Documents.module.scss';
import bigUpFolder from '../../../../../components/icons/bigUpFolder/bigupFolder.svg';
import EmptyList from '../../../EmptyList';
const Documents = () => {
  const { t } = useTranslation();
  return (
    <IonContent
      fullscreen
      className="ion-padding"
      style={{
        '--background': 'var(--color-white)'
      }}
    >
      <EmptyList
        icon={{
          icon: bigUpFolder,
          size: 100,
        }}
        title={t('Coming soon!')}
        message={t('This feature is not yet available. Check back soon for updates.')}
      />
    </IonContent>
  );
};

export default Documents;
