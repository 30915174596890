import { IonCol, IonGrid, IonRow, useIonRouter } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { add } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import TagGroupFormModal from './Group/TagGroupFormModal';
import TagGroupTitleColumn from './GroupTitleColumn';
import TagFormModal from './Tags/TagFormModal';
import { networking } from '../../api/networking';
import DesktopWrapper from '../../components/DesktopWrapper';
import SkeletonTextThreeLines from '../../components/SkeletonComponents/SkeletonTextThreeLines';
import BigUp from '../../components/UI';
import toasters from '../../components/UI/Toasts';
import { useAppSelector } from '../../hooks';
import { setIsLoading } from '../../reducers/loading';
import store from '../../store';
import ActionColumn from '../Financials/Subpanels/Columns/ActionColumn';

const TagsPage: React.FC = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const selectedProjectId = useAppSelector(state => state.project.selectedProject?.id);
  const { tag_category_id } = useParams<{ tag_category_id: string }>();
  const [tagFormModalOpen, setTagFormModalOpen] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<E2U.V1.Models.TagCategory | undefined>();
  const [selectedTag, setSelectedTag] = useState<E2U.V1.Models.Tag | undefined>();
  const [lastTimestamp, setLastTimestamp] = useState<number>(Date.now());

  const fetchTagCategory = () => {
    if (!tag_category_id || !selectedProjectId) {
      store.dispatch(setIsLoading({ isLoading: false }));
      return;
    }
    store.dispatch(setIsLoading({ name: 'tagCategory', value: true }));
    networking.get(`/api/v1/tag_categories/${tag_category_id}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.TagCategory>) => {
        setSelectedCategory(response.data.data);
      })
      .catch((error) => {
        store.dispatch(setIsLoading({ isLoading: false }));
        console.error(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'tagCategory', value: false }));
      });
  };

  const handleDeleteTag = (id: string, name?: string) => {
    toasters.promiseToast(
      networking.delete(`/api/v1/tags/${id}`),
      {
        pending: {
          message: t(
            'Removing tag{name}...',
            {
              name: name ? ` ${name}` : ''
            }
          )
        },
        success: {
          message: t('Tag successfully detached')
        },
        error: {
          message: t('Failed to remove tag. Please try again later or contact our support for further assistance.')
        },
      }
    )
      .then(() => {
        setLastTimestamp(Date.now());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchTagCategory();
  }, [tag_category_id, selectedProjectId]);

  useEffect(() => {
    fetchTagCategory();
  }, []);

  return (
    <DesktopWrapper>
      {(!selectedProjectId || !tag_category_id || !selectedCategory)
        ? <SkeletonTextThreeLines />
        : <>
          <IonGrid>
            <IonRow>
              <IonCol>
                <BigUp.Label.V2Thick label={t('Tags for {categoryName}', {
                  categoryName: selectedCategory?.name ?? ''
                })} />
              </IonCol>
              <IonCol size={'auto'}>
                <BigUp.Buttons.Secondary
                  size={'small'}
                  title={t('Create new')}
                  icon={{
                    icon: add,
                    role: 'button'
                  }}
                  onClick={() => setTagFormModalOpen(true)}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          <BigUp.Table
            callbacks={{
              onRowClick: (model) => router.push(`/tools/${selectedProjectId}/settings/tag/${model.id}`)
            }}
            columns={[
              {
                key: 'name',
                label: t('Name'),
                alignment: 'left',
                sizes: {
                  xs: '9',
                  sm: '10',
                  md: '11',
                  lg: '11',
                  xl: '11'
                },
                body: <BigUp.LockableNameColumn />
              },
              {
                key: 'actions',
                label: t('Actions'),
                sortable: false,
                body: (row) => (!row.is_protected && !row.is_system_record)
                  ? (<ActionColumn
                    type={'tags'}
                    routePrefix={'/settings'}
                    typeLabel={t('Tag')}
                    attributes={{} as E2U.V1.Models.Tag}
                    callbacks={{
                      onDelete: (id: string, name?: string) => handleDeleteTag(id, name ?? ''),
                      onEdit: (model: E2U.V1.Models.Tag) => {
                        setSelectedTag(model);
                        setTagFormModalOpen(true);
                      }
                    }}
                  />)
                  : <></>,
                sizes: {
                  xs: '3',
                  sm: '2',
                  md: '1',
                  lg: '1',
                  xl: '1'
                },
                alignment: 'right',
              }
            ]}
            reducers={{
            }}
            sourceUrl={{
              url: `/api/v1/tag_categories/${tag_category_id}/tags`,
              args: {
                'sort_by[]': ['is_system_record', 'protected_at', 'name'],
              }
            }}
            timestamp={lastTimestamp}
          />
        </>
      }
      <TagFormModal
        onDismiss={() => {
          setSelectedTag(undefined);
          setTagFormModalOpen(false);
        }}
        selectedTag={selectedTag}
        isOpen={tagFormModalOpen}
        onSaved={
          (tag) => {
            setTagFormModalOpen(false);
            setLastTimestamp(Date.now());
            if (selectedTag) {
              setSelectedTag(undefined);
            } else {
              router.push(`/tools/${selectedProjectId}/settings/tag/${tag.id}`);
            }
          }
        }
        tag_category_id={tag_category_id}
      />
    </DesktopWrapper>
  );
};

export default TagsPage;
