import { IonContent } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import type { NavigatedComponentProps } from './types';
import clock from '../../../../../components/icons/clock/clock.svg';
import EmptyList from '../../../EmptyList';
const Latest: React.FC<NavigatedComponentProps> = (props) => {
  const { t } = useTranslation();
  return (
    <IonContent
      fullscreen
      className="ion-padding"
      style={{
        '--background': 'var(--color-white)'
      }}
    >
      <EmptyList
        icon={{
          icon: clock,
          size: 100,
        }}
        title={t('Coming soon!')}
        message={t('This feature is not yet available. Check back soon for updates.')}
      />
    </IonContent>
  );
};

export default Latest;
