import React, { type ReactElement, useEffect, useState } from 'react';

import type { MultiSelectorGroupValuesProps, MultiSelectorItemProps, MultiSelectorProps } from './interfaces';

export const MultiSelectorGroup: React.FC<{
  callbacks?: {
    onSelectionChange?: (values: MultiSelectorGroupValuesProps) => void;
  },
  children: ReactElement<MultiSelectorProps> | ReactElement<MultiSelectorProps>[];
}> = (props) => {
  const { callbacks } = props;
  const [values, setValues] = useState<MultiSelectorGroupValuesProps>({});
  const children = (Array.isArray(props.children) ? props.children : [props.children]);

  const generateRelatedValuesQueryString = (values: MultiSelectorGroupValuesProps) => {
    const args: { [key: string]: string } = {};

    Object.keys(values).forEach((key: string) => {
      args[`others[${key}]`] = values[key].join(',');
    });

    return args;
  };

  useEffect(() => {
    if (callbacks?.onSelectionChange) {
      callbacks.onSelectionChange(values);
    }
  }, [values]);

  return (
    <>
      {children.map((child, index) => {
        const { sourceUrl, ...rest } = child.props;

        return React.cloneElement(child, {
          key: index,
          sourceUrl: (
            (typeof sourceUrl === 'object' && 'url' in sourceUrl)
              ? {
                ...sourceUrl,
                args: {
                  ...sourceUrl.args,
                  ...generateRelatedValuesQueryString(values)
                }
              }
              : sourceUrl
          ),
          group: {
            callbacks: {
              notify: (items: MultiSelectorItemProps[], identifier: string) => {
                const newValues: MultiSelectorGroupValuesProps = {};

                if (items.length) {
                  newValues[identifier] = items;
                }

                Object.keys(values).forEach((key: string) => {
                  if (values[key].length && key !== identifier) {
                    newValues[key] = values[key];
                  }
                });

                setValues(newValues);
              }
            }
          },
          ...rest
        });
      })}
    </>
  );
};

export default MultiSelectorGroup;
