import { IonItem, IonText, useIonRouter } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './LatestFileList.module.scss';
import BigUp from '../../../components/UI';
import { useAppSelector } from '../../../hooks';
import useFilePreviews from '../../../hooks/useFilePreview';
import { formatToNiceDate } from '../../../tools/formatDates';
import getPropertiesByType, { getDocumentRoute } from '../../Document/DocumentsListTable/documentSwitch';

const LatestFileList: React.FC<{ files: E2U.V1.Models.File[], row: E2U.V1.Models.Document }> = ({ files, row }) => {
  const selectedProjectId = useAppSelector(state => state.project.selectedProject?.id);
  const { t } = useTranslation();
  const router = useIonRouter();
  const { fileUrls, loadingPreview } = useFilePreviews(files, [files]);

  const handleOpenFile = (file: E2U.V1.Models.File) => {
    router.push(`/tools/${selectedProjectId}/file/${file.id}`);
  };
  return (
    <>
      {files.map((file: E2U.V1.Models.File) => {
        return (
          file.id && (
            <section key={file.id} className={styles['preview-item-container']}>
              <BigUp.Preview.PreviewItem
                onClick={() => handleOpenFile(file)}
                lines='full'
                previewLoading={loadingPreview}
                fileUrl={fileUrls[file.id]}
                fileName={file.name}
                fileType={file.type}
                endLabel={formatToNiceDate(file.created_at)}
              />
            </section>
          )
        );
      })}
      <IonItem className='ion-no-padding' button detail={false} lines='none'
        onClick={() => {
          router.push(getDocumentRoute(row, selectedProjectId ?? ''));
        }}>
        <IonText
          style={{
            width: '100%',
            textAlign: 'center'
          }}
        >
          <p
            style={{
              color: '#F36F10',
              fontSize: '14px',
              fontWeight: 500,
            }}
          >{t('Show content')}</p>
        </IonText>
      </IonItem >
    </>

  );
};

export default LatestFileList;
