import { IonButton, IonIcon } from '@ionic/react';
import { trash } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Card.module.scss';
import type { FilePreviewProps } from './types';

const PreviewCard: React.FC<FilePreviewProps> = ({ fileName, fileType, fileUrl, onDelete, previewLoading }) => {
  const { t } = useTranslation();
  return (
    <div className={styles['file-preview-card']}>
      {fileType.includes('image') && !previewLoading
        ? <img src={fileUrl} alt={fileName} className={styles['file-preview-card__image']} />
        : <div className={styles['file-preview-card__placeholder']}>
          {fileType.toUpperCase()}
          <span>{t('Could not load preview')}</span>
        </div>
      }
      <div className={styles['file-preview-card__details']}>
        <span className={styles['file-preview-card__name']}>
          {fileName}
        </span>
        <IonButton fill='clear' className='ion-no-padding' onClick={onDelete}>
          <IonIcon slot='icon-only' icon={trash} color='dark' />
        </IonButton>
      </div>
    </div>
  );
};

export default PreviewCard;
