import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonList,
  IonPage,
  IonRow
} from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './UserPhoto.module.scss';
import ButtonComponent from '../../components/ButtonComponent';
import ImagePicker from '../../components/ImagePicker';
import LogoThumbnail from '../../components/LogoThumbnail';
import PageMenuHeader from '../../components/PageMenuHeader/PageMenuHeader';
import RegisterSteps from '../../components/RegisterSteps';
import i18n from '../../i18n';

export interface Images {
  src: string;
  text: string;
}
const items: Images[] = [{ src: '', text: 'logo' }];

const UserPhoto: React.FC = () => {
  const { t } = useTranslation();
  return (

    <>
      <LogoThumbnail />
      <RegisterSteps
        registerActive={false}
        userPhotoActive={true}
        roleSelectActive={false}
        functionSelectActive={false}
      />

      <section className={styles['page-container']}>
        <h1>{t('Profile Image')}</h1>

        <IonList className={styles['image-container']}>
          {items.map((image, index) => {
            return (
              <div key={index}>
                <IonImg className={styles['user-image']} src={image.src} />
              </div>
            );
          })}
        </IonList>

        <ImagePicker />

        <IonGrid>
          <IonRow>
            <IonCol>
              <ButtonComponent
                text={i18n.t('Previous')}
                link={'/register'}
                outline={true}
                type={'button'}
              />
            </IonCol>
            <IonCol>
              <ButtonComponent
                text={i18n.t('Next')}
                link={'#'}
                outline={false}
                type={'button'}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </section>
    </>

  );
};

export default UserPhoto;
