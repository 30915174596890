import { IonItem, IonList, IonSelect, IonSelectOption } from '@ionic/react';
import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

interface SelectDropdownProps<T> extends React.HTMLAttributes<HTMLIonSelectElement> {
  data: T[];
  placeholder: string;
  label: string;
  onValueChange?: (value: string) => void;
  otherCategory?: any;
  setOtherCategory?: (val: any) => void;
}

interface SelectDataProps {
  label: string;
  name: string;
  id: string;
}

const SelectDropdown: React.FC<SelectDropdownProps<SelectDataProps>> = ({ data, label, onValueChange, placeholder }) => {
  const selectRef = useRef<HTMLIonSelectElement>(null);
  const { register } = useFormContext();

  const handleSelectChange = (e: CustomEvent) => {
    const value = e.detail.value;
    if (onValueChange) onValueChange(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const selectElement = selectRef.current;
    if (selectElement) {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        event.preventDefault();
        selectElement.open();
      }
    }
  };

  return (
    <React.Fragment>
      <IonList>
        <IonItem>
          <IonSelect
            label={label}
            labelPlacement='stacked'
            {...register('project_category_id')}
            placeholder={placeholder}
            onIonChange={handleSelectChange}
            ref={selectRef}
            onKeyDown={handleKeyDown}
          >
            {data.map((option: SelectDataProps, i) => {
              return (
                <IonSelectOption
                  onKeyDown={handleKeyDown}
                  key={option.id || i} value={option.id}>
                  {option.name}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </IonItem>
      </IonList>
    </React.Fragment>
  );
};

export default SelectDropdown;
