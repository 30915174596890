import { useCallback, useState } from 'react';

export type FileReaderResult = string | ArrayBuffer | null;

export interface UseFileReaderResult {
  thumbnails: Record<number, string>;
  readFiles: (files: File[]) => void;
  loading: boolean;
  error: Error | null;
  result: FileReaderResult;
}

export const useFileReader = (): UseFileReaderResult => {
  const [thumbnails, setThumbnails] = useState<Record<number, string>>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [result, setResult] = useState<string | ArrayBuffer | null>(null);

  const readFiles = useCallback((files: File[]) => {
    setLoading(true);
    setError(null);

    const promises = files.map((file, index) => {
      return new Promise<void>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (file.type.startsWith('image/')) {
            setThumbnails((prevThumbnails) => ({
              ...prevThumbnails,
              [index]: reader.result as string,
            }));
          }
          setResult(reader.result);
          resolve();
        };
        reader.onerror = () => {
          reject(new Error(`Failed to read file: ${file.name}`));
        };
        reader.readAsDataURL(file);
      });
    });

    Promise.all(promises)
      .then(() => setLoading(false))
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  return {
    thumbnails,
    readFiles,
    loading,
    error,
    result
  };
};
