import { IonButton, IonIcon } from '@ionic/react';
import { toggle } from 'ionicons/icons';

import BigUp from '..';

interface ListToggleProps {
  toggle: boolean;
  handleClick: () => void;
  disable?: boolean;
}

const ListToggle: React.FC<ListToggleProps> = ({
  disable = false,
  handleClick,
  toggle
}) => {
  return (
    <IonButton fill='clear' onClick={handleClick} disabled={disable}>
      <IonIcon
        slot='icon-only'
        size='large'
        icon={
          toggle
            ? BigUp.Icons.Svg.GridIcon
            : BigUp.Icons.Svg.ListIcon
        }
      />
    </IonButton>
  );
};

export default ListToggle;
