import { IonCol, IonGrid, IonItem, IonLabel, IonRow, useIonViewWillEnter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import classNames from 'classnames';
import { pencil, shareSocialSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import styles from './styles.module.scss';
import { networking } from '../../../../api/networking';
import FileButtons from '../../../../components/FileList/FileButtons';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import DeleteRecord from '../../../../components/UI/DeleteRecord';
import { useAppSelector } from '../../../../hooks';
import useAcl from '../../../../hooks/useAcl';
import useAlert from '../../../../hooks/useAlert';
import i18n from '../../../../i18n';
import formatNumber from '../../../../tools/formatNumber';
import { downloadAndShareFile } from '../../../../tools/shareAndDownloadFile';
import shareUrl from '../../../../tools/shareUrl';

const Deviation: React.FC = () => {
  const [deviation, setDeviation] = useState<E2U.V1.Models.EconomyDeviation>();
  const { deviation_uuid, uuid } = useParams<{ uuid: string | undefined, deviation_uuid: string | undefined }>();
  const history = useHistory();
  const { alert } = useAlert();
  const { t } = useTranslation();
  const { project } = useAcl();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  const getDeviation = () => {
    networking.get(`/api/v1/economy_deviations/${deviation_uuid}`)
      .then((response) => {
        setDeviation(response.data.data);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };
  const editDeviation = () => {
    history.push(`/financials/${project?.id}/deviations/${deviation_uuid}/edit`);
  };

  const shareDeviation = () => {
    shareUrl(
      undefined,
      `${t('Here is the link to deviation')} ${deviation?.name}: `,
      `${process.env.REACT_APP_SHARE_BASE_URL}/financials/${uuid}/deviations/${deviation_uuid}`,
      '',
      'share'
    );
  };

  const deleteDeviation = (id: string | undefined) => {
    toasters.promise(networking.delete(`/api/v1/economy_deviations/${deviation_uuid}`), {
      success: i18n.t('Deviation deleted'),
      error: i18n.t('Could not delete deviation'),
    })
      .finally(() => {
        history.goBack();
      });
  };

  const confirmDeleteDeviation = () => {
    alert(
      t(`Deleting change: ${deviation?.name}`),
      t(`Are you sure you want to delete change: ${deviation?.name}?`),
      () => deleteDeviation(deviation?.id),
      t('Cancel'),
      t('Delete')
    );
  };

  useEffect(() => {
    getDeviation();
  }, []);

  return (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'stretch', marginBottom: 20 }}>
        <div className={styles.deviationPageContainer}
          style={{
            ...isDesktop && {
              display: 'flex',
              flexDirection: 'row'
            }
          }}
        >
          <div style={{ ...isDesktop && { width: '70%' } }}>
            <IonGrid>

              <IonRow
                className={classNames([
                  styles.deviationPageHeaderContainer,
                  'ion-padding ion-no-margin ion-justify-content-center ion-align-items-center'
                ])}
              >
                <IonCol size='6' className={styles.nameContainer}>
                  {(typeof deviation?.name !== 'undefined') &&
                    <h3 className="ion-no-margin">{deviation.name}</h3>
                  }
                </IonCol>
                <IonCol size='3' className='ion-text-right'>
                  <BigUp.Buttons.Responsive
                    padding={20}
                    expand={'block'}
                    isDesktop={isDesktop}
                    color={isDesktop ? 'secondary' : 'none'}
                    onClick={() => shareDeviation()}
                    icon={{ icon: shareSocialSharp, color: 'secondary' }}
                    title={i18n.t('Share')}
                  />
                </IonCol>
                <IonCol size={'3'} className='ion-text-left'>
                  <BigUp.Buttons.Responsive
                    padding={20}
                    expand={'block'}
                    isDesktop={isDesktop}
                    color={isDesktop ? 'primary' : 'none'}
                    onClick={() => editDeviation()}
                    icon={{ icon: pencil, color: 'primary' }}
                    title={i18n.t('Edit')}
                  />
                </IonCol>
              </IonRow>

              <BigUp.Table columns={[
                {
                  key: 'name',
                  label: t('Activity Code'),
                  alignment: 'left',
                  sizes: {
                    xl: '6',
                    xs: '6',
                  }
                },
                {
                  key: 'cost_per_unit',
                  label: t('Cost/unit'),
                  sizes: {
                    xl: '2',
                    xs: '4',
                  }
                },
                {
                  key: 'quantity',
                  label: t('Quantity'),
                  sizes: {
                    xl: '2',
                    xs: '4',
                  }
                },
                {
                  key: 'unit',
                  label: t('Unit'),
                  sizes: {
                    xl: '2',
                    xs: '4',
                  }
                },
              ]}
              reducers={{
                cost_per_unit: (value: number) => {
                  return value > 999 ? formatNumber(value) : value;
                },
                quantity: (value: number) => {
                  return value > 999 ? formatNumber(value) : value;
                },
              }}
              sourceUrl={{
                url: `/api/v1/economy_deviations/${deviation_uuid}`,
                args: {
                  'with[]': ['rows', 'files']
                }
              }} />
            </IonGrid>
          </div>
          <div style={{
            ...isDesktop && { width: '30%' }
          }}>
            <IonGrid className='ion-padding'>
              <IonRow className={styles.commentContainer}>
                <IonCol size="12">
                  <BigUp.Label.Thick label={i18n.t('Comment')} className='ion-no-margin' />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <span>{deviation?.comment}</span>
                </IonCol>
              </IonRow>

            </IonGrid>
            <IonGrid className={'ion-padding-horizontal'}>
              <IonRow>
                <IonCol size='12'>
                  <BigUp.Label.Thick label={i18n.t('Files')} className='ion-no-margin' />
                </IonCol>
              </IonRow>
              {deviation?.files?.map((file) => {
                return (
                  <IonRow key={file.file_hash}>
                    <IonCol>
                      <IonItem className={'ion-no-padding'}>
                        <IonLabel className={'ion-no-margin ion-margin-vertical'}>
                          {file.name}
                        </IonLabel>
                        <FileButtons
                          file={file}
                          edit={false}
                          downloadFile={() => downloadAndShareFile(file, 'download', uuid)}
                          shareFile={() => downloadAndShareFile(file, 'share', uuid)}
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                );
              })}
            </IonGrid>
            <DeleteRecord
              label={i18n.t('Want to delete this deviation?')}
              record={deviation?.id as string}
              onDelete={() => {
                confirmDeleteDeviation();
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Deviation;
