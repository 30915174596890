import { IonCol, IonGrid, IonHeader, IonIcon, IonRow, IonToolbar } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { closeOutline } from 'ionicons/icons';
import { useLocation } from 'react-router';

import { useAppSelector } from '../../../../hooks';
import type { OnboardingInterface } from '../../interfaces/Onboarding.interface';
import styles from '../containers/styles/ModalFormContainer.module.scss';

const ModalFormContainerHeader: React.FC<OnboardingInterface> = (props) => {
  const location = useLocation();

  const selected: E2U.V1.Models.Project | undefined = useAppSelector(state => state.onboarding.selectedProject);
  const project: E2U.V1.Models.Project | undefined = useAppSelector(state => state.project.selectedProject);

  const selectedProject = selected || project;
  const lastSubstring = location.pathname.split('/').filter(Boolean).pop();

  return (
    <div id={styles['onboarding-header-container']}>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonGrid >
            <IonRow className="ion-justify-content-between ion-align-items-center ion-margin-horizontal ion-margin-top">
              <IonCol className='ion-text-start '> .
              </IonCol>
              <IonCol className='ion-text-right '>
                <IonIcon size='large' color='light' icon={closeOutline} onClick={() => props.toggleOnboarding!()} />
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonGrid>
            <IonRow className="ion-justify-content-center ion-text-center" >
              <IonCol size="12">
                <h5>{typeof selectedProject !== 'undefined' && selectedProject.name}</h5>
              </IonCol>
              <div className={styles['onboarding-progress']}>
                {props.onboardingRouting?.map((e) => {
                  return (
                    <IonCol size="3" key={e.stage}>
                      <p className={`ion-text-center ${lastSubstring === e.url ? styles.active : ''}`}>
                        {e.stage}
                      </p>
                    </IonCol>
                  );
                })}
              </div>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>
    </div>

  );
};
export default ModalFormContainerHeader;
