import { IonCol, IonRow, IonSkeletonText } from '@ionic/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useAppSelector } from '../../../../../hooks';
import { formatToYYYYMMDD } from '../../../../../tools/formatDates';

interface PrecalculationTitleProps {
}

const PrecalculationTitle: React.FC<PrecalculationTitleProps> = () => {
  const { t } = useTranslation();
  const selectedPrecalculation = useAppSelector((state) => state.precalculations.selectedPrecalculation);
  const selectedVersion = useAppSelector(state => state.precalculations.selectedVersion);
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  const selectedProject = useAppSelector(state => state.project?.selectedProject);
  const {
    precalculationId,
    uuid
  } = useParams<{
    precalculationId: string | undefined,
    uuid: string | undefined,
    historicalPrecalculationId: string | undefined
  }>();
  const base = useMemo(
    () => precalculationId
      ? `${formatToYYYYMMDD(selectedPrecalculation?.created_at)} ${selectedPrecalculation?.name}`
      : `${formatToYYYYMMDD(selectedVersion?.created_at)} ${selectedVersion?.name}`,
    [precalculationId, selectedPrecalculation, selectedVersion]
  );

  if (!selectedPrecalculation && !selectedVersion) {
    return <IonSkeletonText animated style={{ width: '100px' }} />;
  }

  if (uuid) {
    if (selectedProject?.name) {
      if (isDesktop) {
        return (<>
          {t('{precalculationName} (Project {name})', {
            precalculationName: base,
            name: selectedProject?.name
          })
          }
        </>);
      }
      return (<IonRow className={'ion-no-padding ion-no-margin ion-align-items-start ion-justify-content-start'}>
        <IonCol className={'ion-no-padding ion-no-margin'} size={'auto'}>{base}</IonCol>
        <IonCol className={'ion-no-padding ion-no-margin'} size={'auto'}>
          {t('Project: {name}', { name: selectedProject?.name })}
        </IonCol>
      </IonRow>);
    }
    return (<IonRow className={'ion-no-padding ion-no-margin ion-align-items-center ion-justify-content-start'}>
      <IonCol className={'ion-no-padding ion-no-margin'} size={'auto'}>{base}</IonCol>
      <IonCol className={'ion-no-padding ion-no-margin ion-margin-start'} size={'auto'}><IonSkeletonText animated style={{ width: '60px' }} /></IonCol>
    </IonRow>);
  }
  return <>{ base }</>;
};

export default PrecalculationTitle;
