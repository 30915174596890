import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { useIonPopover } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import React, { useEffect } from 'react';
import type { Accept } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import BigUp from '..';
import type { CameraUploadResult } from '../../../hooks/useCameraUpload';
import { importData } from '../../../tools/importData';
import type { PopoverItem } from '../Popovers';
import styles from './DragAndDrop.module.scss';

interface DragAndDropProps {
  onFilesSelect: (files: File[]) => void;
  multiple?: boolean;
  files?: File[];
  children: React.ReactNode;
  acceptedFileTypes?: Accept;
  uploadOnDrop?: (files: File[]) => React.ReactNode;
  cameraProps?: CameraUploadResult;
  allowCamera?: boolean
}

const DragAndDrop: React.FC<DragAndDropProps> = (
  { acceptedFileTypes = undefined, allowCamera = false, cameraProps, children, multiple = true, onFilesSelect, uploadOnDrop }: DragAndDropProps
) => {
  const { t } = useTranslation();
  const { acceptedFiles, getRootProps, inputRef } = useDropzone({
    multiple,
    maxSize: 1073741824,
    accept: acceptedFileTypes
  });

  const takePhoto = () => {
    Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
      quality: 100,
    })
      .then(photo => cameraProps?.handleTakenPhoto([photo]))
      .catch(error => Sentry.captureException(error));
  };

  const uploadOptions: PopoverItem[] = [
    {
      value: 'device-files',
      label: t('From library'),
      icon: {
        icon: BigUp.Icons.Svg.FolderOutlined
      },
      onClick: () => {
        importData(onFilesSelect);
      }
    },
    {
      value: 'take-photo',
      label: t('Take photo'),
      icon: {
        icon: BigUp.Icons.Svg.CameraOutlined
      },
      onClick: () => {
        takePhoto();
      }
    },
  ];

  const [presentOptions] = useIonPopover(BigUp.Popovers.Default, {
    items: uploadOptions,
    mode: 'md',
  });

  const showPopover = (present: (options: any) => void, e: React.MouseEvent) => {
    present({
      event: e as any,
      mode: 'md',
      dismissOnSelect: true,
    });
  };

  const handleDropBoxClick = (e: any) => {
    if (allowCamera) {
      showPopover(presentOptions, e);
    } else {
      importData(onFilesSelect);
    }
  };

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      onFilesSelect(acceptedFiles);
      acceptedFiles.length = 0;
      acceptedFiles.splice(0, acceptedFiles.length);
      if (inputRef && inputRef.current) {
        inputRef.current.value = '';
      }
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if ((acceptedFiles && acceptedFiles.length > 0) && uploadOnDrop) {
      uploadOnDrop && uploadOnDrop(acceptedFiles);
    }
  }, [acceptedFiles]);

  return (
    <div
      className={styles['drag-and-drop--container']}
      {...getRootProps()}
      onClick={(e) => handleDropBoxClick(e)}
    >
      {children}
    </div>
  );
};

export default DragAndDrop;
