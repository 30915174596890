export const importData = (handleFileSelect: (files: File[]) => void, accepted?: string) => {
  const input = document.createElement('input');
  input.type = 'file';
  input.multiple = true;
  if (accepted) {
    input.accept = accepted;
  }
  input.onchange = _ => {
    const files: any = Array.from(input.files as any);
    handleFileSelect(files);
  };
  input.click();
};
