import ActionSheet from './ActionSheets';
import MediaUpload from './ActionSheets/MediaUpload';
import Buttons from './Buttons/Buttons';
import MediaUploader from './Buttons/MediaUploader';
import Alert from './Cards/Alert';
import FileCard from './Cards/FileCard';
import Checkbox from './Checkboxes/Checkbox';
import DatepickerOutlined from './Datepickers/DatepickerOutlined';
import DateTimeContent from './Dates/DatetimeContent';
import RadialChart from './Graphs/RadialChart';
import RadialChartWidget from './Graphs/RadialChartWidget';
import Header from './Header/Header';
import HeaderSimple from './Header/HeaderSimple';
import Badge from './Icons/Badge';
import ColorPicker from './Inputs/Colorpicker';
import Input from './Inputs/Input';
import OutlinedInput from './Inputs/InputOutlined';
import MetadataItem from './Items/MetadataItems/MetadataItem';
import Label from './Labels/Label';
import ItemList from './List/ItemList';
import BottomSheet from './Modal/BottomSheet';
import FileUpload from './Modal/FileUpload/index';
import InputModal from './Modal/InputModal';
import ListModal from './Modal/ListModal/ListModal';
import ResetPassword from './Modal/ResetPassword';
import Controls from './Pannable/Controls/Controls';
import Popover from './Popovers';
import Message from './Popovers/Message';
import { ProjectSelectionPopover } from './Popovers/ProjectSelectionPopover';
import { Range } from './Range/Range';
import ReorderableList from './Reorder/Reorder';
import CountrySelect from './Select/CountrySelect';
import Select from './Select/Select';
import Table from './Table';
import CheckmarkIcon from '../icons/checkmark.svg';
import TabBar from './Tabs/TabBar/TabBar';
import Textarea from './Textareas/Textarea';
import TextareaOutlined from './Textareas/TextareaOutlined';
import Title from './Titles/Title';
import toasters from './Toasts';
import Toggle from './Toggles/Toggle';
import Description from './Typography/Description';
import CameraOutlined from '../icons/cameraOutlined.svg';
import DocumentFolderBlueIcon from '../icons/documents/folder-blue.svg';
import DocumentFolderGreenIcon from '../icons/documents/folder-green.svg';
import DocumentFolderGreyIcon from '../icons/documents/folder-grey.svg';
import DocumentFolderLightblueIcon from '../icons/documents/folder-lightblue.svg';
import DocumentFolderOrangeIcon from '../icons/documents/folder-orange.svg';
import DocumentFolderPurpleIcon from '../icons/documents/folder-purple.svg';
import DocumentFolderRedIcon from '../icons/documents/folder-red.svg';
import DocumentFolderYellowIcon from '../icons/documents/folder-yellow.svg';
import TagBlueIcon from '../icons/documents/tag-blue.svg';
import TagGreenIcon from '../icons/documents/tag-green.svg';
import TagGreyIcon from '../icons/documents/tag-grey.svg';
import TagLightblueIcon from '../icons/documents/tag-lightblue.svg';
import TagOrangeIcon from '../icons/documents/tag-orange.svg';
import TagPurpleIcon from '../icons/documents/tag-purple.svg';
import TagRedIcon from '../icons/documents/tag-red.svg';
import TagYellowIcon from '../icons/documents/tag-yellow.svg';
import JPG from '../icons/extensions/jpg.svg';
import FolderOutlined from '../icons/folderOutlined.svg';
import GridIcon from '../icons/grid.svg';
import ImageOutlined from '../icons/imageOutlined.svg';
import ListIcon from '../icons/list.svg';
import PreviewZoomOut from '../icons/preview/zoom-minus.svg';
import { Calculation } from '../icons/tools/Calculation';
import CalculationIcon from '../icons/tools/calculation.svg';
import { Cashflow } from '../icons/tools/Cashflow';
import CashflowIcon from '../icons/tools/cashflow.svg';
import { Changes } from '../icons/tools/Changes';
import ChangesIcon from '../icons/tools/changes.svg';
import ControlOfExecutionIcon from '../icons/tools/control-of-execution.svg';
import { ControlOfExecution } from '../icons/tools/ControlOfExecution';
import { Deviations } from '../icons/tools/Deviations';
import DeviationsIcon from '../icons/tools/deviations.svg';
import { Document } from '../icons/tools/Document';
import DocumentIcon from '../icons/tools/document.svg';
import DocumentsPrimaryIcon from '../icons/tools/documents-primary.svg';
import EmergencyContactIcon from '../icons/tools/emergency-contact.svg';
import EmergencyIcon from '../icons/tools/emergency.svg';
import FavouritesIcon from '../icons/tools/favourites.svg';
import HomeIcon from '../icons/tools/home.svg';
import { Map } from '../icons/tools/Map';
import PaymentScheduleIcon from '../icons/tools/payment-schedule.svg';
import { PaymentSchedule } from '../icons/tools/PaymentSchedule';
import ProductionBudgetIcon from '../icons/tools/production-budget.svg';
import { ProductionBudget } from '../icons/tools/ProductionBudget';
import { Prognosis } from '../icons/tools/Prognosis';
import PrognosisIcon from '../icons/tools/prognosis.svg';
import ProjectInformationIcon from '../icons/tools/project-information.svg';
import { ProjectInformation } from '../icons/tools/ProjectInformation';
import SiteAccessRequestIcon from '../icons/tools/site-access-requests.svg';
import { SiteAccessRequest } from '../icons/tools/SiteAccessRequests';
import DarkmodeToggle from '../Toggle/DarkmodeToggle';
import FileItem from './Items/FileItem';
import FortnoxIcon from '../../assets/fortnox/logo.svg';
import WorkPreparationIcon from '../icons/tools/work-preparation.svg';
import WorkingEnvironmentIcon from '../icons/tools/working-environment.svg';
import WorkingEnvironment from '../icons/tools/WorkingEnvironment';
import { WorkPreparation } from '../icons/tools/WorkPreparation';
import ButtonContainer from './Widgets/Containers/ButtonContainer';
import ChartContainer from './Widgets/Containers/ChartContainer';
import Logo from '../../assets/svg/bigup-white.svg';
import AttachmentsIcon from '../icons/attachments.svg';
import Wrapper from './Modal/Wrapper';
import MapIcon from '../icons/tools/map.svg';
import SettingsIcon from '../icons/tools/settings.svg';
import { ModalHeader } from './Modal/ModalParts/Header';
import FilePreviewModal from './modals/FilePreviewModal';
import PreviewCard from './Preview/Card';
import PreviewItem from './Preview/Item';
import PreviewListWrapper from './Preview/Wrapper';
import SelectOutlined from './Select/SelectOutlined';
import CSV from '../icons/extensions/CSV.svg';
import DOCX from '../icons/extensions/DOCX.svg';
import DVG from '../icons/extensions/DVG.svg';
import DWG from '../icons/extensions/DWG.svg';
import PDF from '../icons/extensions/pdf.svg';
import PNG from '../icons/extensions/png.svg';
import XLS from '../icons/extensions/XLS.svg';
import XLSX from '../icons/extensions/XLSX.svg';
import PreviewZoomIn from '../icons/preview/zoom-plus.svg';
import ProjectsIcon from '../icons/tools/projects.svg';
import { Reports } from '../icons/tools/Reports';
import ReportsIcon from '../icons/tools/reports.svg';
import { Teams } from '../icons/tools/Teams';
import TeamsIcon from '../icons/tools/teams.svg';
import ToolsIcon from '../icons/tools/tools.svg';
import LockableNameColumn from './Table/Columns/LockableName';
import TabToolbar from './Tabs/TabToolbar/TabToolbar';

export const BigUp = {
  Cards: {
    FileCard,
    Alert
  },
  Widget: {
    ButtonContainer,
    ChartContainer
  },
  Graphs: {
    RadialChart,
    RadialChartWidget
  },
  Typography: {
    Description
  },
  Range,
  Icons: {
    CheckmarkIcon,
    WorkingEnvironment,
    ControlOfExecution,
    Calculation,
    Document,
    Teams,
    WorkPreparation,
    Map,
    SiteAccessRequest,
    ProjectInformation,
    PaymentSchedule,
    ProductionBudget,
    Reports,
    Cashflow,
    Prognosis,
    Deviations,
    Changes,
    Svg: {
      GridIcon,
      ListIcon,
      Extensions: {
        Jpg: JPG,
        Pdf: PDF,
        Png: PNG,
        Csv: CSV,
        Docx: DOCX,
        Dvg: DVG,
        Dwg: DWG,
        Xls: XLS,
        Xlsx: XLSX
      },
      ImageOutlined,
      CameraOutlined,
      FolderOutlined,
      Checkmark: CheckmarkIcon,
      EmergencyContact: EmergencyContactIcon,
      Attachments: AttachmentsIcon,
      WorkingEnvironment: WorkingEnvironmentIcon,
      ControlOfExecution: ControlOfExecutionIcon,
      Calculation: CalculationIcon,
      Document: DocumentIcon,
      Teams: TeamsIcon,
      WorkPreparation: WorkPreparationIcon,
      Map: MapIcon,
      SiteAccessRequest: SiteAccessRequestIcon,
      ProjectInformation: ProjectInformationIcon,
      PaymentSchedule: PaymentScheduleIcon,
      ProductionBudget: ProductionBudgetIcon,
      Reports: ReportsIcon,
      Cashflow: CashflowIcon,
      Prognosis: PrognosisIcon,
      Deviations: DeviationsIcon,
      Changes: ChangesIcon,
      Projects: ProjectsIcon,
      DocumentPrimary: DocumentsPrimaryIcon,
      Favourites: FavouritesIcon,
      Home: HomeIcon,
      Tools: ToolsIcon,
      Emergency: EmergencyIcon,
      Settings: SettingsIcon,
      DocumentFolderGreen: DocumentFolderGreenIcon,
      DocumentFolderLightblue: DocumentFolderLightblueIcon,
      DocumentFolderPurple: DocumentFolderPurpleIcon,
      DocumentFolderBlue: DocumentFolderBlueIcon,
      DocumentFolderRed: DocumentFolderRedIcon,
      DocumentFolderOrange: DocumentFolderOrangeIcon,
      DocumentFolderGrey: DocumentFolderGreyIcon,
      DocumentFolderYellow: DocumentFolderYellowIcon,
      TagGreen: TagGreenIcon,
      TagLightblue: TagLightblueIcon,
      TagPurple: TagPurpleIcon,
      TagBlue: TagBlueIcon,
      TagRed: TagRedIcon,
      TagOrange: TagOrangeIcon,
      TagGrey: TagGreyIcon,
      TagYellow: TagYellowIcon,
      FortnoxIcon,
      Preview: {
        ZoomIn: PreviewZoomIn,
        ZoomOut: PreviewZoomOut
      }
    }
  },
  IconBadge: Badge,
  Modal: {
    ListModal,
    FilePreviewModal,
    InputModal,
    Wrapper,
    ModalHeader,
    ResetPassword,
    FileUpload
  },
  Datepicker: {
    DatepickerOutlined
  },
  Date: {
    DateTimeContent
  },
  ItemList,
  Input,
  toasters,
  Textareas: {
    Textarea,
    TextareaOutlined
  },
  Textarea,
  TextareaOutlined,
  Select,
  SelectOutlined,
  Checkbox,
  ReorderableList,
  Label,
  Buttons,
  CountrySelect,
  MetadataItem,
  Title,
  Table,
  Preview: {
    PreviewCard,
    PreviewItem,
    PreviewListWrapper
  },
  LockableNameColumn,
  Toggles: {
    Toggle,
    DarkmodeToggle,
  },
  Tabs: {
    TabBar,
    TabToolbar
  },
  Pannable: {
    Controls
  },
  Item: {
    FileItem
  },
  MediaUploader,
  BottomSheet,
  ColorPicker,
  Header,
  Popovers: {
    Default: Popover,
    ProjectSelectionPopover,
    Message,
  },
  ActionSheets: {
    ActionSheet,
    MediaUpload
  },
  OutlinedInput,
  HeaderSimple,
  Logo,
};

export default BigUp;
