import { IonIcon, useIonAlert, useIonPopover, useIonRouter } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import type { EconomyChange } from '@techlove/easy2use-typings/src/V1/Models/EconomyChange';
import { ellipsisHorizontalOutline } from 'ionicons/icons';
import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { handleSource } from '../../../../components/ListsComponents/rowList/handlers';
import type { Sources } from '../../../../components/ListsComponents/rowList/interface';
import BigUp from '../../../../components/UI';
import useAcl from '../../../../hooks/useAcl';
import shareUrl from '../../../../tools/shareUrl';

interface ActionColumnProps {
  type: Sources;
  attributes: object & { id?: string, name: string, [column: string]: any };
  callbacks: {
    onDelete(id: string, name?: string): void;
    onEdit?(model: object & { id?: string, name: string }): void;
    onDetails?(model: object & { id?: string, name: string }): void;
  };
  typeLabel?: string;
  baseType?: 'financials' | 'tools' | string;
  routePrefix?: string;
  customIdentifierColumn?: string;
  hideShare?: boolean;
}

const ActionColumn: React.FC<ActionColumnProps> = (props) => {
  const { attributes, baseType, callbacks, customIdentifierColumn, hideShare, routePrefix, type, typeLabel } = props;
  const { project } = useAcl();
  const { t } = useTranslation();
  const [presentAlert] = useIonAlert();
  const router = useIonRouter();
  const baseUrl = process.env.REACT_APP_SHARE_BASE_URL;
  const source = handleSource({ type, uuid: project?.id, title: typeLabel, baseType, routePrefix });
  const [present] = useIonPopover(BigUp.Popovers.Default, {
    items: [
      ...(!hideShare
        ? [{
          label: t('Share'),
          value: t('Share'),
          onClick: () => {
            if (typeof source === 'object') {
              return shareUrl(
                undefined,
                ['monitoring', 'deviations', 'changes'].includes(type)
                  ? t('Here is the link to {type} {name}', 'Here is the link to {type} {name}', {
                    type: type === 'deviations' ? 'deviation' : 'change',
                    name: (props.attributes as EconomyChange).name
                  })
                  : t('Here is the link to {name}', 'Here is the link to {name}', {
                    name: props.attributes.name
                  }),
                `${baseUrl}${source.routes.base}/${attributes[customIdentifierColumn ?? 'id']}`,
                t(`Let me know if there is something you are wondering about.`),
                'share'
              );
            }
          }
        }]
        : []
      ),
      {
        label: t('Delete'),
        value: t('Delete'),
        onClick: () => {
          presentAlert({
            header: t('Are you sure?'),
            message: t('This action can not be undone!'),
            buttons: [
              {
                text: t('Cancel'),
                role: 'cancel'
              },
              {
                text: t('Delete'),
                role: 'confirm',
                handler: () => callbacks.onDelete(attributes.id as string, attributes?.name)
              },
            ],
          });
        }
      },
      {
        label: t('Details'),
        value: t('Details'),
        onClick: callbacks.onDetails
          ? () => {
            if (!callbacks.onDetails) return;
            callbacks.onDetails(attributes);
          }
          : () => {
            if (typeof source === 'object') {
              router.push(`${source.routes.base}/${attributes[customIdentifierColumn ?? 'id']}`);
            }
          }
      },
      ...(callbacks.onEdit
        ? [
          {
            label: t('Edit'),
            value: t('Edit'),
            onClick: () => {
              callbacks.onEdit && callbacks.onEdit(attributes);
            }
          }
        ]
        : [])
    ]
  });

  const handlePopover = (e: React.MouseEvent<HTMLIonIconElement>) => {
    e.stopPropagation();
    present({
      event: e.nativeEvent,
      dismissOnSelect: true
    });
  };

  return (
    <div data-clickable={true}>
      <IonIcon
        data-clickable
        onClick={handlePopover}
        icon={ellipsisHorizontalOutline}
      />
    </div>
  );
};

export default ActionColumn;
