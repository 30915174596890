import { IonPage, IonRouterOutlet } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React from 'react';
import { Redirect, Route } from 'react-router';

import MobilePanels from '../../components/MobilePanels';
import SideMenuV2Layout from '../../components/UI/SideMenu/V2/SideMenuV2Layout';
import { matchUuid } from '../../constants/matchUuid';
import { useAppSelector } from '../../hooks';
import i18n from '../../i18n';
import AppLayout from '../../layout/AppLayout';
import TabBar from '../../layout/TabBar';
import AccessManagementPage from '../../pages/ClientAccount/AccessManagementPage';
import MassAssignmentPage from '../../pages/ClientAccount/MassAssignmentPage';
import DocumentPage from '../../pages/Document';
import CategoryPage from '../../pages/Document/Category';
import ShowDocumentCategory from '../../pages/Document/Category/Show';
import DocumentsEditPage from '../../pages/Document/DocumentEditPage';
import DocumentsListTable from '../../pages/Document/DocumentsListTable';
import FilePreviewPage from '../../pages/Document/Preview';
import EmergencyPage from '../../pages/Emergency';
import EmergencyContactsPage from '../../pages/EmergencyContacts';
import ProjectReportsPage from '../../pages/ProjectReports';
import ProjectCreate from '../../pages/Projects/ProjectCreate/ProjectCreate';
import ObservationReport from '../../pages/Projects/ReportsPage/ObvervationReports/ObservationReport';
import IncidentReport from '../../pages/Reports/IncidentReport';
import TagPage from '../../pages/Tags/Tag';
import TagGroupsPage from '../../pages/Tags/TagGroupsPage';
import TagsPage from '../../pages/Tags/TagsPage';
import Teams from '../../pages/Teams';
import TeamInvite from '../../pages/Teams/Invite/TeamInvite';
import Team from '../../pages/Teams/Team/Team';
import TeamForm from '../../pages/Teams/TeamForm/TeamForm';
import ControlOfExecution from '../../pages/Tools/ControlOfExecution';
import ControlOfExecutionDetailed from '../../pages/Tools/ControlOfExecution/ControlOfExecutionDetailed';
import DocumentCategoryPage from '../../pages/Tools/DocumentCategory/DocumentCategoryPage';
import FortnoxPage from '../../pages/Tools/FortnoxPage';
import ProjectMappingPage from '../../pages/Tools/FortnoxPage/ProjectMappingPage';
import SettingsPage from '../../pages/Tools/SettingsPage';
import CategoriesSettingPage from '../../pages/Tools/SettingsPage/WorkPreparationSettingsPage/CategoriesSettingPage';
import NodesSettingPage from '../../pages/Tools/SettingsPage/WorkPreparationSettingsPage/NodesSettingPage';
import RowsSettingPage from '../../pages/Tools/SettingsPage/WorkPreparationSettingsPage/RowsSettingPage';
import SectionsSettingPage from '../../pages/Tools/SettingsPage/WorkPreparationSettingsPage/SectionsSettingPage';
import SiteAccessRequests from '../../pages/Tools/SiteAccessRequests';
import SiteAccessRequestsSettings from '../../pages/Tools/SiteAccessRequests/Settings/Settings';
import ToolList from '../../pages/Tools/ToolList';
import WorkingEnviroment from '../../pages/Tools/WorkingEnviroment';
import WorkingEnvironmentReport from '../../pages/Tools/WorkingEnviroment/WorkingEnvironmentReport';
import AssessmentList from '../../pages/WorkPreparation/AssessmentList';
import PreparationDetails from '../../pages/WorkPreparation/PreparationDetails';
import PreparationForm from '../../pages/WorkPreparation/PreparationForm';
import WorkPreparations from '../../pages/WorkPreparation/Preparations';
import WorksitePage from '../../pages/Worksite';
import WorkSiteFormPage from '../../pages/Worksite/form';
import LayerUpload from '../../pages/Worksite/form/LayerUpload/LayerUpload';
import WorksiteMapPage from '../../pages/Worksite/map';
import { AbilitiesWatchedRoute } from '../AbilitiesWatchedRoute';

const ToolsRouteGroup: React.FC = () => {
  const selected: E2U.V1.Models.Project | undefined = useAppSelector((state) => state.onboarding.selectedProject);
  const project: E2U.V1.Models.Project | undefined = useAppSelector((state) => state.project.selectedProject);
  const selectedProject = selected || project;

  return (
    <IonPage>
      <IonRouterOutlet>
        <Route path={'/tools'} component={ToolList} exact={true} />

        <AbilitiesWatchedRoute
          path={`/tools/create-project`}
          exact={true}
          requiredAbilities={['can_create_projects']}
        >
          <ProjectCreate />
        </AbilitiesWatchedRoute>

        <Route path={`/tools/work-preparation`} component={PreparationForm} exact={true} />

        <AbilitiesWatchedRoute
          path={`/tools/work-preparation/:uuid(${matchUuid})`}
          exact={true}
          requiredAbilities={['can_store_protocols_in_projects']}>
          <PreparationForm />
        </AbilitiesWatchedRoute>

        <Route path={`/tools/preparation/:preparationId(${matchUuid})`}>
          <PreparationDetails />
        </Route>

        <Route path={`/tools/:uuid(${matchUuid})/reports/incidents/:incident_uuid(${matchUuid})`} exact={true}>
          <AppLayout>
            <IncidentReport title={''} />
          </AppLayout>
        </Route>

        <AbilitiesWatchedRoute exact={true}
          path={`/tools/work-preparations/:uuid(${matchUuid})`}
          requiredAbilities={['can_store_protocols_in_projects']}>
          <IonPage>
            <WorkPreparations />
            <TabBar />
          </IonPage>
        </AbilitiesWatchedRoute>

        <Route exact={true} path={`/tools/work-preparations`}>
          <IonPage>
            <WorkPreparations />
            <TabBar />
          </IonPage>
        </Route>

        <Route path={`/tools/work-preparation/assessments/*`}>
          <AssessmentList />
        </Route>

        <Route path={`/tools/:uuid(${matchUuid})/reports/observations/:observation_uuid(${matchUuid})`} exact={true}>
          <AppLayout>
            <ObservationReport />
          </AppLayout>
        </Route>

        <Route path={`/tools/:uuid(${matchUuid})/reports`} exact={true}>
          <AppLayout scroll>
            <ProjectReportsPage />
          </AppLayout>
        </Route>

        {/* CONTROL OF EXECUTION */}
        <AbilitiesWatchedRoute
          requiredAbilities={['can_view_control_of_executions_in_projects']}
          path={`/tools/:uuid(${matchUuid})/control-of-execution/:coe_id(${matchUuid})`}
          exact={true}
        >
          <SideMenuV2Layout>
            <ControlOfExecutionDetailed />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          requiredAbilities={['can_view_emergency_contacts_in_projects']}
          path={`/tools/:uuid(${matchUuid})/emergency-contacts`}
          exact={true}
        >
          <SideMenuV2Layout>
            <EmergencyContactsPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          requiredAbilities={['can_view_control_of_executions_in_projects']}
          path={`/tools/:uuid(${matchUuid})/control-of-execution`}
          exact={true}
        >
          <SideMenuV2Layout paddedPage={false}>
            <ControlOfExecution />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        {/* WORKING ENVIRONMENT */}
        <AbilitiesWatchedRoute
          requiredAbilities={['can_view_working_environments_in_projects']}
          path={`/tools/:uuid(${matchUuid})/working-environment/`}
        >
          <SideMenuV2Layout paddedPage={false}>
            <WorkingEnviroment />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        {/* SITE ACCESS REQUESTS */}
        <AbilitiesWatchedRoute
          requiredAbilities={['can_view_access_requests_in_projects']}
          path={`/tools/:uuid(${matchUuid})/site-access-requests/settings`}
          exact={true}
        >
          <SideMenuV2Layout>
            <SiteAccessRequestsSettings />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          requiredAbilities={['can_view_access_requests_in_projects']}
          path={`/tools/:uuid(${matchUuid})/site-access-requests/`}
          exact={true}
        >
          <SideMenuV2Layout>
            <SiteAccessRequests />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        {/* DOCUMENTS */}
        <AbilitiesWatchedRoute
          requiredAbilities={['can_view_documents_in_projects']}
          path={`/tools/:uuid(${matchUuid})/documents/reports/working_environment/:working_environment_uuid(${matchUuid})/:entry_uuid(${matchUuid})?`}
          exact={true}
        >
          <SideMenuV2Layout>
            <WorkingEnvironmentReport />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          requiredAbilities={['can_view_documents_in_projects']}
          path={`/tools/:uuid(${matchUuid})/documents/reports/working_environment_entry/:working_environment_entry_uuid(${matchUuid})`}
          exact={true}
        >
          <SideMenuV2Layout>
            <WorkingEnvironmentReport />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          requiredAbilities={['can_view_documents_in_projects']}
          path={`/tools/:uuid(${matchUuid})/documents/:documentUuid(${matchUuid})`}
          exact={true}
        >
          <SideMenuV2Layout paddedPage={false} contentColour='var(--color-white)'>
            <DocumentPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/documents/:documentUuid(${matchUuid})/edit`}
          exact={true}
          requiredAbilities={['can_view_documents_in_projects', 'can_store_documents_in_projects']}
        >
          <SideMenuV2Layout paddedPage={false} contentColour='var(--color-white)'>
            <DocumentsEditPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/documents/category/:category_id(${matchUuid}|uncategorized)`}
          exact={true}
          requiredAbilities={['can_view_documents_in_projects']}
        >
          <DocumentCategoryPage />
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/documents`}
          exact={true}
          requiredAbilities={['can_view_documents_in_projects']}
        >
          <DocumentsListTable />
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/file/:file_id(${matchUuid})`}
          exact={true}
        // requiredAbilities={['can_view_files']}
        >
          <FilePreviewPage />
        </AbilitiesWatchedRoute>

        {/* WORKSITES */}
        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/worksites/:worksite_id(${matchUuid}/upload)`}
          requiredAbilities={['can_view_work_sites_in_projects', 'can_store_work_sites_in_projects']}
        >
          <SideMenuV2Layout paddedPage={false}>
            <LayerUpload />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/worksites/:worksite_id(${matchUuid}|add)`}
          exact={true}
          requiredAbilities={['can_view_work_sites_in_projects', 'can_store_work_sites_in_projects']}
        >
          <SideMenuV2Layout title={i18n.t('Worksites')} paddedPage={false}>

            <WorkSiteFormPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/worksites`}
          exact={true}
          requiredAbilities={['can_view_work_sites_in_projects']}
        >
          <SideMenuV2Layout title={i18n.t('Worksites')} paddedPage={false}>
            <WorksitePage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/map/:worksite_layer_id?`}
          requiredAbilities={['can_view_work_sites_in_projects']}
        >
          <SideMenuV2Layout canReturn title={i18n.t('Map')}>
            <WorksiteMapPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        {/* TAGS */}
        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/tags/:tag_category_id(${matchUuid})`}
          requiredAbilities={['can_view_tag_categories_in_projects', 'can_store_tag_categories_in_projects']}
        >
          <SideMenuV2Layout paddedPage={false} canReturn title={i18n.t('Tags')}>
            <TagsPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/tags`}
          exact={true}
          requiredAbilities={['can_view_tag_categories_in_projects', 'can_store_tag_categories_in_projects']}
        >
          <SideMenuV2Layout paddedPage={false} canReturn title={i18n.t('Tags')}>
            <TagGroupsPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/tag/:tag_id(${matchUuid})`}
          requiredAbilities={['can_view_document_types_in_tags', 'can_store_document_types_in_projects', 'can_store_document_types_in_tags']}
        >
          <SideMenuV2Layout paddedPage={false} canReturn title={i18n.t('Tag')}>
            <TagPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/document-types/:category_id(${matchUuid})/`}
          exact={true}
          requiredAbilities={['can_view_document_types_in_projects', 'can_update_document_types_in_projects']}
        >
          <SideMenuV2Layout paddedPage={false} canReturn title={i18n.t('Document categories')}>
            <ShowDocumentCategory />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        {/* CATEGORIES */}
        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/document-types/`}
          exact={true}

          requiredAbilities={['can_view_document_types_in_projects', 'can_update_document_types_in_projects']}

        >
          <SideMenuV2Layout paddedPage={false} canReturn title={i18n.t('Document categories')}>
            <CategoryPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        {/* TEAM */}
        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/team/:team_id(${matchUuid})`}
          exact={true}
          requiredAbilities={['can_update_teams_in_projects']}
        >
          <SideMenuV2Layout canReturn title={i18n.t('Team')}>
            <Team />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/team/:team_id(${matchUuid})/invite`}
          exact={true}

          requiredAbilities={['can_update_teams_in_projects']}

        >
          <SideMenuV2Layout canReturn title={i18n.t('Invite')}>
            <TeamInvite />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/team/:team_id(${matchUuid})/edit`}
          exact={true}
          requiredAbilities={['can_update_teams_in_projects']}

        >
          <SideMenuV2Layout canReturn title={i18n.t('Edit team')}>
            <TeamForm />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/team/create`}
          exact={true}
          requiredAbilities={['can_store_teams_in_projects']}

        >
          <SideMenuV2Layout canReturn title={i18n.t('Create team')}>
            <TeamForm />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/teams`}
          requiredAbilities={['can_view_teams_in_projects']}
          exact={true}
        >
          <SideMenuV2Layout canReturn title={i18n.t('Teams')}>
            <Teams />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/fortnox`}
          exact={true}
          requiredAbilities={['can_initialize_fortnox_authorization_projects']}
        >
          <SideMenuV2Layout canReturn title={i18n.t('Fortnox')}>
            <FortnoxPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/fortnox/integration/:integration_id(${matchUuid})`}
          exact={true}
          requiredAbilities={['can_store_mappings_in_fortnox_project_integrations']}>
          <SideMenuV2Layout canReturn title={i18n.t('Fortnox')}>
            <ProjectMappingPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          exact={true}
          path={`/tools/:uuid(${matchUuid})/settings/access`}
        // requiredAbilities={['can_create_projects']}
        >
          <SideMenuV2Layout canReturn title={i18n.t('Access management')}>
            <AccessManagementPage type={'projects'} />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/access/add/:tool?`}

          requiredAbilities={['can_create_projects']}

        >
          <SideMenuV2Layout canReturn title={i18n.t('Mass assignment')}>
            <MassAssignmentPage type={'projects'} />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          exact={true}
          path={`/tools/:uuid(${matchUuid})/settings/work-preparation`}
          requiredAbilities={['can_view_protocols_in_projects']}>
          <Redirect to={`/tools/${selectedProject?.id}/settings/work-preparation/nodes`} />
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          exact={true}
          path={`/tools/:uuid(${matchUuid})/settings/work-preparation/nodes`}
          requiredAbilities={['can_view_protocols_in_projects']}>
          <SideMenuV2Layout canReturn>
            <NodesSettingPage type={'projects'} />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          exact={true}
          path={`/tools/:uuid(${matchUuid})/settings/work-preparation/node/:node_id(${matchUuid})`}
          requiredAbilities={['can_view_protocols_in_projects']}>
          <SideMenuV2Layout canReturn>
            <SectionsSettingPage type={'projects'} />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          exact={true}
          path={`/tools/:uuid(${matchUuid})/settings/work-preparation/section/:section_id(${matchUuid})`}
          requiredAbilities={['can_view_protocols_in_projects']}>
          <SideMenuV2Layout canReturn>
            <CategoriesSettingPage type={'projects'} />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          exact={true}
          path={`/tools/:uuid(${matchUuid})/settings/work-preparation/category/:category_id(${matchUuid})`}
          requiredAbilities={['can_view_protocols_in_projects']}>
          <SideMenuV2Layout canReturn>
            <RowsSettingPage type={'projects'} />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings`}
          exact={true}
          requiredAbilities={['can_create_projects']}
        >
          <SideMenuV2Layout title={i18n.t('Settings')}>
            <SettingsPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/emergency`}
          exact={true}
        >
          <SideMenuV2Layout canReturn>
            <EmergencyPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <Route path={'/tools/:uuid(new)'} exact={true}>
          <AppLayout scroll content_id='create_project'>
            <ProjectCreate />
          </AppLayout>
        </Route>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default ToolsRouteGroup;
