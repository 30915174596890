import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { add, imageOutline, scan } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './DocumentActionsButton.module.scss';
import { networking } from '../../api/networking';
import { useAppSelector } from '../../hooks';
import useCameraUpload from '../../hooks/useCameraUpload';
import useFileUpload from '../../hooks/useFileUpload';
import useModal from '../../hooks/useModal';
import { importData } from '../../tools/importData';
import DocumentFormModal from '../DocumentFormModal';
import BigUp from '../UI';
import FabButton from '../UI/Buttons/Fabs/FabButton';
import type { ListItems } from '../UI/Modal/ListModal/types';
import toasters from '../UI/Toasts';

const DocumentActionsButton: React.FC = () => {
  const modal = useModal();
  const [modalOpen, setModalOpen] = useState(false);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [documentFormOpen, setDocumentFormOpen] = useState(false);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const selectedDocumentCategory = useAppSelector(state => state.document.selectedCategory);
  const { t } = useTranslation();

  const { getUploadedFiles, handleFileSelection, onlyUploadFiles, resetFilesToUpload, setUploadedFiles } = useFileUpload();
  const files = getUploadedFiles() ?? [];
  const cameraProps = useCameraUpload();

  const categoryTags = useMemo(() => selectedDocumentCategory?.category_tags ?? [], [selectedDocumentCategory]);

  const createDocument = (fileName: string) => {
    return networking
      .post(`/api/v1/documents`, {
        name: fileName,
        description: '',
        project_id: selectedProject?.id,
      })
      .then((response) => {
        const { id } = response.data.data;
        return id;
      });
  };

  const takePhoto = () => {
    Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
      quality: 100,
    })
      .then(photo => {
        const fileName = new Date().getTime() + '.' + photo.format;
        const blob: any = cameraProps.dataURLToBlob(photo.dataUrl);
        if (!blob) {
          throw new Error('Failed to convert data URL to Blob');
        }
        const file: any = new File([blob], fileName, {
          type: 'image/jpeg'
        });

        cameraProps.handleTakenPhoto([photo])
          .then(() => {
            uploadTakenPhoto([file]);
          });
      })
      .catch(error => Sentry.captureException(error));
  };

  const uploadTakenPhoto = (photos: File[]) => {
    if (!photos || photos.length === 0) return;

    const fileName = photos[0]?.name;
    createDocument(fileName)
      .then((documentId) => {
        const uploadPromises = photos.map((photo) => cameraProps.uploadPhoto(photo));

        Promise.all(uploadPromises)
          .then((responses) => {
            const fileIds = responses.map((response) => response?.data?.data?.id).filter(id => id !== null);

            if (fileIds.length === 0) return;

            const attachPromises = fileIds.map((fileId) =>
              attachPhotoToDocument(documentId, fileId)
            );
            toasters.createToast({
              message: t('Created document from photo: {filename}', {
                filename: fileName
              })
            }, 'success');
            return Promise.all(attachPromises);
          })
          .catch((error) => {
            Sentry.captureException(error);
          });
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const handleToasters = (successful: boolean) => {
    if (successful) {
      setUploadedFiles([]);
    }
  };

  const createDocumentFromFiles = () => {
    const uploadPromises = onlyUploadFiles()
      .map((filePromise) =>
        filePromise
          .then((fileResponse) =>
            networking.post(`/api/v1/projects/${selectedProject?.id}/documents`, {
              name: fileResponse.data.data.name,
              description: '',
            })
              .then((response: E2U.V1.Response.Success<E2U.V1.Models.Document>) => {
                const documentId = response.data.data.id;
                const fileId = fileResponse.data.data.id;
                return networking.post(`/api/v1/documents/${documentId}/files/${fileId}`)
                  .then(() => {
                    resetFilesToUpload();
                    if (categoryTags && categoryTags.length) {
                      categoryTags.forEach((tag: E2U.V1.Models.Tag) => {
                        networking.post(`/api/v1/documents/${documentId}/related_tags/${tag.id}`);
                      });
                    }
                  });
              })
          )
      );

    const allUploads = Promise.allSettled(uploadPromises);

    toasters.promiseToast(
      allUploads,
      {
        pending: {
          message: t('Uploading files'),
        },
        success: {
          message: t('All files uploaded successfully'),
        },
        error: {
          message: t('Some files could not be uploaded'),
        },
      }
    )
      .then((results: PromiseSettledResult<E2U.V1.Models.Document>[]) => {
        const successful = results.every((result) => result.status === 'fulfilled');
        handleToasters(successful);
      })
      .catch((error) => {
        handleToasters(false);
        Sentry.captureException(error);
      })
      .finally(() => {
      });
  };

  const attachPhotoToDocument = (documentId: E2U.V1.Models.Document, fileId: E2U.V1.Models.File['id']) => {
    return networking
      .post(`/api/v1/documents/${documentId}/files/${fileId}`)
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const handlePopoverPress = (callback: () => void) => {
    setModalOpen(false);
    callback();
  };

  const fileUploadOptions: ListItems[] = [
    {
      value: 'file-upload-modal',
      label: t('Upload from device'),
      icon: {
        icon: BigUp.Icons.Svg.FolderOutlined
      },
      onClick: () => handlePopoverPress(modal.openModal),
    },
    {
      value: 'file-upload-photos-and-files',
      label: t('Upload photos or video'),
      icon: {
        color: 'dark',
        icon: imageOutline
      },
      onClick: () => handlePopoverPress(() => importData(handleFileSelection, 'image/*, video/*'))
    },
    {
      value: 'use-camera',
      label: t('Take a photo'),
      icon: {
        icon: BigUp.Icons.Svg.CameraOutlined
      },
      onClick: () => handlePopoverPress(() => takePhoto())
    },
    {
      value: 'scan-document',
      label: t('Scan document (coming soon)'),
      disabled: true,
      icon: {
        icon: scan
      },
      onClick: () => toasters.createToast({
        message: t('This feature is coming soon!')
      }, 'warning'),
    },
  ];

  useEffect(() => {
    if (files.length > 0) {
      createDocumentFromFiles();
    }
  }, [files]);

  return (
    <>
      <FabButton
        icon={{
          icon: add,
        }}
        onClick={() => setOptionsVisible(!optionsVisible)}
        vertical={'bottom'}
        horizontal={'end'}
        className={`
          ${styles['documents-action-button']} 
          ${isDesktop && styles['DocumentActionsButton--desktop']}
        `}
        list={{
          buttons: [
            {
              icon: {
                icon: BigUp.Icons.Svg.Document,
              },
              label: t('Create document'),
              onClick: () => setDocumentFormOpen(true)
            },
            {
              icon: {
                icon: BigUp.Icons.Svg.Attachments,
              },
              onClick: () => setModalOpen(true),
              label: t('Upload files'),
            }
          ],
          side: 'top'
        }}
      />
      <BigUp.Modal.ListModal
        isOpen={modalOpen}
        dismiss={() => setModalOpen(false)}
        // trigger='file-upload-modal'
        items={fileUploadOptions}
        title={t('Upload files')}
        subTitle={t(`Generate document from files`)}
      />
      <DocumentFormModal
        open={documentFormOpen}
        onClose={() => setDocumentFormOpen(false)}
        onSaved={() => setDocumentFormOpen(false)}
      />
      <BigUp.Modal.FileUpload
        modal={{
          isOpen: modal.isModalOpen,
          dismiss: modal.closeModal
        }}
      />
    </>
  );
};

export default DocumentActionsButton;
