import { IonText } from '@ionic/react';
import type { ComponentProps } from 'react';
import React from 'react';
interface DescriptionProps {
  ionText?: ComponentProps<typeof IonText>;

}
const Description: React.FC<React.PropsWithChildren<DescriptionProps>> = ({
  children,
  ionText
}) => {
  return (
    <IonText {...ionText} style={{ width: '100%', opacity: 0.4 }}>
      <span>{children}</span>
    </IonText>
  );
};

export default Description;
