import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { checkmark, chevronBack, chevronForward, close } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import type { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { networking } from '../../../../../../api/networking';
import BigUp from '../../../../../../components/UI';
import toasters from '../../../../../../components/UI/Toasts';
import i18n from '../../../../../../i18n';

interface FileInterface {
  onReadFile: (file: E2U.V1.Models.ProjectAccessRequirement) => void;
  file: E2U.V1.Models.ProjectAccessRequirement | undefined;
  onDismiss: () => void;
  onDecline: (file: E2U.V1.Models.ProjectAccessRequirement) => void;
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const File: React.FC<FileInterface> = ({ file, onDecline, onReadFile }) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [fileContent, setFileContent] = useState<string>('');
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

  const getFileContent = (id: string) => {
    const endpoint = `/api/v1/files/${id}/export?base64=true`;
    networking.get(endpoint)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.File>) => {
        setFileContent(`data:${response.headers['content-type']};base64,${response.data}`);
      })
      .catch((error) => {
        Sentry.captureMessage('Failed to get file content', error);
        setFileContent('');
        toasters.createToast({
          message: i18n.t('Failed to get file content'),
          background: 'var(--ion-color-light)'
        }, 'error');
      });
  };

  const onDocumentLoadSuccess = (numPages: number) => {
    setNumPages(numPages);
    setPageNumber(1);
  };
  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  useEffect(() => {
    if (file) {
      getFileContent(file.file_id);
    }
  }, [file]);

  return (
    <React.Fragment>
      {file
        ? (
          <>
            <IonHeader className='ion-padding-vertical' >
              <IonToolbar>
                <IonButtons slot="start">
                  <IonButton onClick={() => onDecline(file)}>
                    <IonIcon icon={close} color='danger' />
                  </IonButton>
                </IonButtons>
                <IonTitle>{file?.name}</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => onReadFile(file)}>
                    <IonIcon icon={checkmark} color='success' />
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-no-padding" >
              <TransformWrapper pinch={{ step: 5 }} ref={transformComponentRef} initialScale={1}>
                <TransformComponent
                  wrapperStyle={{
                    width: '100%',
                    height: numPages && numPages > 1 ? '90%' : '100%'
                  }}
                >
                  {file.file?.type === 'pdf' && (
                    <Document
                      file={fileContent}
                      options={options}
                      onLoadSuccess={({ numPages }) => onDocumentLoadSuccess(numPages)}
                    >
                      <Page
                        pageNumber={pageNumber}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                      />
                    </Document>
                  )}
                  {file.file?.type === 'image' && (
                    <IonImg
                      src={`${fileContent}`}
                      alt={i18n.t('File: {name}', 'File: {name}', { name: file.file.name })}
                    />
                  )}
                </TransformComponent>
                {numPages && numPages > 1 && (
                  <IonFooter className='ion-no-border'>
                    <IonGrid>
                      <IonRow className='ion-align-items-center ion-justify-content-center'>
                        <IonCol size='4'>
                          <BigUp.Buttons.Icon
                            disabled={pageNumber === 1}
                            icon={{ icon: chevronBack }}
                            onClick={() => changePage(-1)}
                          />
                        </IonCol>
                        <IonCol size='4' className='ion-text-center'>
                          <BigUp.Label.Regular label={`${pageNumber} / ${numPages}`} />
                        </IonCol>
                        <IonCol size='4'>
                          <BigUp.Buttons.Icon
                            disabled={pageNumber === numPages}
                            icon={{ icon: chevronForward }}
                            onClick={() => changePage(1)}
                          />
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonFooter>
                )}
              </TransformWrapper>
            </IonContent>
          </>
        )
        : <React.Fragment />
      }
    </React.Fragment>
  );
};
export default File;
