import type { RouterDirection } from '@ionic/react';
import { useIonRouter } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import classNames from 'classnames';
import { add, chevronBack, chevronForward, homeSharp, layersSharp } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { Router } from 'workbox-routing';

import styles from './WorksiteMaps.module.scss';
import FabButton from '../../../components/UI/Buttons/Fabs/FabButton';
import { useAppSelector } from '../../../hooks';
import { setIsBackdropVisible } from '../../../reducers/navigation';
import store from '../../../store';

interface BottomButtonProps {
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  numPages: number;
  onClick: () => void;
  selectedProject: E2U.V1.Models.Project | undefined;
  hasLayers?: boolean;
}
const BottomButtons: React.FC<BottomButtonProps> = ({ hasLayers, numPages, onClick, pageNumber, selectedProject, setPageNumber }) => {
  const router = useIonRouter();

  const handleRedirects = (route?: string, direction?: RouterDirection) => {
    if (route) {
      router.push(route, direction);
    } else {
      router.goBack();
    }

    store.dispatch(setIsBackdropVisible(false));
  };
  const handlePageBack = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1);
  };

  const handlePageForward = () => {
    if (pageNumber < numPages) setPageNumber(pageNumber + 1);
  };

  return (
    <>
      <FabButton
        className={classNames(styles.sectionSelectButton, styles.sectionSelectButtonLeft)}
        icon={{ icon: homeSharp }}
        onClick={() => handleRedirects('/tools', 'back')}
      />
      {numPages > 1 && (
        <>
          <FabButton
            className={styles.sectionSelectButton}
            color={'none'}
            style={{ left: '33%', zIndex: 40 }}
            icon={{ icon: chevronBack }}
            onClick={handlePageBack}
            button={{ disabled: pageNumber === 1 }}
          />

          <FabButton
            className={styles.sectionSelectButton}
            color={'none'}
            style={{ right: '33%', zIndex: 40 }}
            icon={{ icon: chevronForward }}
            onClick={handlePageForward}
            button={{ disabled: pageNumber === numPages }}
          />
        </>

      )}
      {hasLayers
        ? (
          <FabButton
            className={classNames(styles.sectionSelectButton, styles.sectionSelectButtonRight)}
            onClick={onClick}
            icon={{ icon: layersSharp }}
          />
        )
        : (
          <FabButton
            className={classNames(styles.sectionSelectButton, styles.sectionSelectButtonRight)}
            icon={{ icon: add }}
            onClick={() => handleRedirects(`/tools/${selectedProject?.id}/worksites`, 'forward')}
          />
        )
      }

    </>
  );
};

export default BottomButtons;
