import { IonButton, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonText, IonToolbar, isPlatform } from '@ionic/react';
import { close } from 'ionicons/icons';
import { useRef } from 'react';

import BigUp from '../..';
import styles from './ListModal.module.scss';
import type { ListModalProps } from './types';

const ListModal: React.FC<ListModalProps> = (props) => {
  // const modal = useRef<HTMLIonModalElement>(null);
  const isDesktop = isPlatform('desktop');

  const generateClassNameForDevice = () => {
    return isDesktop
      ? styles['list-modal-container-desktop']
      : styles['list-modal-container'];
  };
  return (
    <IonModal
      isOpen={props.isOpen}
      onIonModalDidDismiss={() => props.dismiss()}
      // ref={modal}
      // trigger={props.trigger}
      {...(!isDesktop
        ? {
          style: {
            '--width': '100%',
            '--height': '350px',
            '--min-height': '280px'
          },
          initialBreakpoint: 1,
          breakpoints: [0, 1]
        }
        : {
          style: {
            '--width': '100%',
            '--max-width': '400px',
            '--height': '300px',
            '--min-height': '280px'
          }
        })}
      className={generateClassNameForDevice()}
    >
      <IonToolbar>
        <IonHeader>
          <div>
            <IonText className={styles['header-labels']}>
              <section className={styles['header-labels--container']}>
                <IonLabel className={`ion-no-margin ${styles['header-labels--container__thick']}`}>{props.title}</IonLabel>
                <IonLabel className={`ion-no-margin ${styles['header-labels--container__thin']}`}>{props.subTitle}</IonLabel>
              </section>
            </IonText>

            <IonButton fill="clear" className={styles['close-button']} onClick={() => props.dismiss()}>
              <IonIcon color='medium' size={'large'} slot="icon-only" icon={close}></IonIcon>
            </IonButton>
          </div>
        </IonHeader>
      </IonToolbar>
      <IonContent>
        {props.items.map((item, i) => {
          return (
            <IonItem
              disabled={item.disabled && item.disabled}
              className={styles['modal-list-item']}
              key={i}
              button
              lines='full'
              onClick={item.onClick}
            >
              <BigUp.Label.Regular
                label={item.label}
                className='ion-no-margin ion-margin-vertical'
              />
              <IonIcon slot='end' icon={item.icon?.icon} {...item.icon} />
            </IonItem>
          );
        })}
      </IonContent>
    </IonModal>
  );
};

export default ListModal;
