import { IonCol, IonGrid, IonItem, IonLabel, IonRow, IonText, useIonViewWillEnter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import classNames from 'classnames';
import { pencil, shareSocialSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import styles from './styles.module.scss';
import { networking } from '../../../../api/networking';
import FileButtons from '../../../../components/FileList/FileButtons';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import DeleteRecord from '../../../../components/UI/DeleteRecord';
import { useAppSelector } from '../../../../hooks';
import useAcl from '../../../../hooks/useAcl';
import useAlert from '../../../../hooks/useAlert';
import i18n from '../../../../i18n';
import formatNumber from '../../../../tools/formatNumber';
import { downloadAndShareFile } from '../../../../tools/shareAndDownloadFile';
import shareUrl from '../../../../tools/shareUrl';
import ActivityCodeTile from '../ActivityCodeTile';

const Change: React.FC = () => {
  const [change, setChange] = useState<E2U.V1.Models.EconomyChange>();
  const { change_uuid, uuid } = useParams<{ uuid: string | undefined, change_uuid: string | undefined }>();
  const history = useHistory();
  const { t } = useTranslation();
  const { alert } = useAlert();
  const { project } = useAcl();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  const getChange = () => {
    networking.get(`/api/v1/economy_changes/${change_uuid}`)
      .then((response) => {
        setChange(response.data.data);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const editChange = (id: string | undefined) => {
    history.push(`/financials/${project?.id}/changes/${id}/edit`);
  };

  const deleteChange = (id: string | undefined) => {
    toasters.promise(networking.delete(`/api/v1/economy_changes/${id}`), {
      success: i18n.t('Change deleted'),
      error: i18n.t('Could not delete change'),
    })
      .finally(() => {
        history.goBack();
      });
  };

  const confirmDeleteChange = () => {
    alert(
      t(`Deleting change: ${change?.name}`),
      t(`Are you sure you want to delete change: ${change?.name}?`),
      () => deleteChange(change_uuid),
      t('Cancel'),
      t('Delete')
    );
  };

  const shareChange = () => {
    shareUrl(
      undefined,
      `${t('Here is the link to the economy change, ')} ${change?.name}: `,
      `${process.env.REACT_APP_SHARE_BASE_URL}/financials/${uuid}/change/${change_uuid}`,
      '',
      'share'
    );
  };

  useEffect(() => {
    getChange();
  }, []);

  return (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className={styles.changePageContainer} style={{
          ...isDesktop && {
            display: 'flex',
            flexDirection: 'row'
          }
        }}>
          <div style={{
            ...isDesktop && { width: '70%' }
          }}>
            <IonGrid>
              <IonRow
                className={classNames([
                  styles.changePageHeaderContainer,
                  'ion-padding ion-no-margin ion-justify-content-center ion-align-items-center'
                ])}
              >
                <IonCol size='6' className={styles.nameContainer}>
                  {(typeof change?.name !== 'undefined') &&
                    <h3 className="ion-no-margin">{change.name}</h3>
                  }
                </IonCol>
                <IonCol size='3' className='ion-text-right'>
                  <BigUp.Buttons.Responsive
                    padding={24}
                    expand={'block'}
                    isDesktop={isDesktop}
                    color={isDesktop ? 'secondary' : 'none'}
                    onClick={() => shareChange()}
                    icon={{ icon: shareSocialSharp, color: 'secondary', size: 'large' }}
                    title={i18n.t('Share')}
                  />
                </IonCol>
                <IonCol size={'3'} className='ion-text-left'>
                  <BigUp.Buttons.Responsive
                    padding={24}
                    expand={'block'}
                    isDesktop={isDesktop}
                    color={isDesktop ? 'primary' : 'none'}
                    onClick={() => editChange(change_uuid)}
                    icon={{ icon: pencil, color: 'primary', size: 'large' }}
                    title={i18n.t('Edit')}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>

            <BigUp.Table columns={[
              {
                key: 'name',
                label: t('Activity Code'),
                alignment: 'left',
                sizes: {
                  xl: '3',
                  xs: '6',
                }
              },
              {
                key: 'description',
                label: t('Description'),
                alignment: 'left',
                sizes: {
                  xl: '3',
                  xs: '6',
                }
              },
              {
                key: 'cost_per_unit',
                label: t('Cost/unit'),
                sizes: {
                  xl: '2',
                  xs: '4',
                }
              },
              {
                key: 'quantity',
                label: t('Quantity'),
                sizes: {
                  xl: '2',
                  xs: '4',
                }
              },
              {
                key: 'unit',
                label: t('Unit'),
                sizes: {
                  xl: '2',
                  xs: '4',
                }
              },
            ]}
            reducers={{
              cost_per_unit: (value: number) => {
                return value > 999 ? formatNumber(value) : value;
              },
              quantity: (value: number) => {
                return value > 999 ? formatNumber(value) : value;
              },
            }}
            sourceUrl={{
              url: `/api/v1/economy_changes/${change_uuid}`,
              args: {
                'with[]': ['rows', 'files']
              }
            }} />
          </div>
          <IonGrid className={'ion-padding-horizontal'}>
            <IonRow>
              <BigUp.Label.Thick label={i18n.t('Files')} className='ion-no-margin' />
            </IonRow>
            {change?.files?.map((file) => {
              return (
                <IonRow key={file.file_hash}>
                  <IonCol>
                    <IonItem className={'ion-no-padding'}>
                      <IonLabel className={'ion-no-margin ion-margin-vertical'}>
                        {file.name}
                      </IonLabel>
                      <FileButtons
                        file={file}
                        edit={false}
                        downloadFile={() => downloadAndShareFile(file, 'download', uuid)}
                        shareFile={() => downloadAndShareFile(file, 'share', uuid)}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
              );
            })}
          </IonGrid>
          <DeleteRecord
            label={i18n.t('Do you want to delete this change?')}
            record={change?.id as string}
            onDelete={confirmDeleteChange}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Change;
