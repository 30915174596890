import { IonCol, IonGrid, IonRow, useIonPopover, useIonRouter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { add, lockClosed } from 'ionicons/icons';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './Category.module.scss';
import CategoryTitleColumn from './CategoryTitleColumn';
import type { DocumentCategory } from './Form/CategoryFormModal';
import CategoryFormModal from './Form/CategoryFormModal';
import { networking } from '../../../api/networking';
import DesktopWrapper from '../../../components/DesktopWrapper';
import SkeletonTextThreeLines from '../../../components/SkeletonComponents/SkeletonTextThreeLines';
import BigUp from '../../../components/UI';
import toasters from '../../../components/UI/Toasts';
import { useAppSelector } from '../../../hooks';
import ActionColumn from '../../Financials/Subpanels/Columns/ActionColumn';

const CategoryPage: React.FC = () => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const [lastTimestamp, setLastTimestamp] = useState<number>(Date.now());
  const selectedProjectId = useAppSelector((state) => state.project.selectedProject?.id);
  const [present] = useIonPopover(BigUp.Popovers.Message, {
    children: <BigUp.Label.Regular
      label={t('Locked categories match documents created by the system, and cannot be changed.')}
      className={'ion-no-margin'}
    />,
  });
  const [selectedCategory, setSelectedCategory] = useState<DocumentCategory | undefined>();
  const [categoryFormModalOpen, setCategoryFormModalOpen] = useState<boolean>(false);

  const handleShowLockMessage = (e: React.MouseEvent) => {
    present({
      dismissOnSelect: true,
      event: e.nativeEvent,
      arrow: true,
      showBackdrop: false,
    });
  };

  const handleDeleteDocumentType = (id: string, name?: string) => {
    toasters.promiseToast(
      networking.delete(`/api/v1/document_types/${id}`),
      {
        pending: {
          message: t(
            'Removing document category{name}...',
            {
              name: name ? ` ${name}` : ''
            }
          )
        },
        success: {
          message: t('Category successfully removed')
        },
        error: {
          message: t('Failed to remove document category. Please try again later or contact our support for further assistance.')
        }
      }
    )
      .catch((err) => Sentry.captureException(err))
      .finally(() => {
        setLastTimestamp(Date.now());
      });
  };

  return (
    <DesktopWrapper>
      {(!selectedProjectId)
        ? <SkeletonTextThreeLines />
        : <>
          <IonGrid>
            <IonRow className={'ion-align-items-center'}>
              <IonCol>
                <BigUp.Label.V2Thick label={t('Custom categories')} />
              </IonCol>
              <IonCol size={'auto'}>
                <BigUp.Buttons.Secondary
                  size={'small'}
                  title={t('Create new')}
                  icon={{
                    icon: add,
                    role: 'button'
                  }}
                  onClick={() => setCategoryFormModalOpen(true)}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          <BigUp.Table
            callbacks={{
              onRowClick: (model) => router.push(`/tools/${selectedProjectId}/settings/document-types/${model.id}`)
            }}
            columns={[
              {
                key: 'name',
                label: t('Name'),
                alignment: 'left',
                default_sort_direction: 'asc',
                default_sort: true,
                sizes: {
                  xs: '9',
                  sm: '10',
                  md: '11',
                  lg: '11',
                  xl: '11'
                },
                body: <CategoryTitleColumn />
              },
              {
                key: 'actions',
                label: t('Actions'),
                sortable: false,
                body: <ActionColumn
                  type={'document-types'}
                  routePrefix={'/settings'}
                  typeLabel={t('Document types')}
                  attributes={{} as E2U.V1.Models.Type}
                  callbacks={{
                    onDelete: (id: string, name?: string) => handleDeleteDocumentType(id, name ?? ''),
                    onEdit: (model: DocumentCategory) => {
                      setSelectedCategory(model);
                      setCategoryFormModalOpen(true);
                    }
                  }}
                />,
                sizes: {
                  xs: '3',
                  sm: '2',
                  md: '1',
                  lg: '1',
                  xl: '1'
                },
                alignment: 'right',
              }
            ]}
            reducers={{
            }}
            sourceUrl={{
              url: `/api/v1/projects/${selectedProjectId}/document_types`,
              args: {
                filters: JSON.stringify([
                  {
                    field: 'is_system_record',
                    value: [false]
                  }
                ]),
              }
            }}
            timestamp={lastTimestamp}
          />
          <IonGrid className='ion-margin-top'>
            <IonRow>
              <IonCol>
                <BigUp.Label.V2Thick
                  label={t('System categories')}
                  icon={{
                    icon: lockClosed,
                    onClick: (e: React.MouseEvent) => handleShowLockMessage(e),
                    role: 'button'
                  }}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          <BigUp.Table
            columns={[
              {
                key: 'name',
                label: t('Name'),
                alignment: 'left',
                default_sort_direction: 'asc',
                default_sort: true,
                sizes: {
                  xs: '12',
                  sm: '12',
                  md: '12',
                  lg: '12',
                  xl: '12'
                },
                body: <CategoryTitleColumn />
              }
            ]}
            reducers={{
            }}
            sourceUrl={{
              url: `/api/v1/projects/${selectedProjectId}/document_types`,
              args: {
                filters: JSON.stringify([
                  {
                    field: 'is_system_record',
                    value: [true]
                  }
                ]),
              }
            }}
            timestamp={lastTimestamp}
          />
        </>
      }
      <CategoryFormModal
        onDismiss={() => {
          setSelectedCategory(undefined);
          setCategoryFormModalOpen(false);
        }}
        selectedCategory={selectedCategory}
        isOpen={categoryFormModalOpen}
        onSaved={
          (category) => {
            setCategoryFormModalOpen(false);
            setLastTimestamp(Date.now());
            if (selectedCategory) {
              setSelectedCategory(undefined);
            } else {
              router.push(`/tools/${selectedProjectId}/settings/document-types/${category.id}`);
            }
          }
        }
      />
    </DesktopWrapper>
  );
};

export default CategoryPage;
