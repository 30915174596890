import { IonIcon, IonItem, IonLabel, IonList, IonSearchbar, useIonAlert, useIonRouter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import type { AxiosResponse } from 'axios';
import { add, checkmark } from 'ionicons/icons';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { useParams } from 'react-router';
import type { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { validate as isValidUUID } from 'uuid';

import BottomButtons from './BottomButtons';
import styles from './WorksiteMaps.module.scss';
import { networking } from '../../../api/networking';
import SkeletonItem from '../../../components/SkeletonComponents/SkeletonItem';
import toasters from '../../../components/Toasts/Toasts';
import BigUp from '../../../components/UI';
import EmptyList from '../../../components/UI/EmptyList';
import { useAppSelector } from '../../../hooks';
import i18n from '../../../i18n';
import { setIsLoading } from '../../../reducers/loading';
import { setIsBackdropVisible } from '../../../reducers/navigation';
import { setShouldHideMenu } from '../../../reducers/project';
import storage from '../../../storage';
import store from '../../../store';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const WorksiteMapPage: React.FC = () => {
  const { t } = useTranslation();
  const [worksites, setWorksites] = useState<(E2U.V1.Models.WorkSite & {
    layers: E2U.V1.Models.WorkSiteLayer[];
  })[]>([]);
  const [selectedLayer, setSelectedLayer] = useState<E2U.V1.Models.WorkSiteLayer | null>(null);
  const [search, setSearch] = useState<string>('');
  const [file, setFile] = useState<E2U.V1.Models.File | null>(null);
  const [fileContent, setFileContent] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const isLoadingWorksiteLayer = useAppSelector(state => state.loading.isLoading.work_site_layer);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  const { uuid, worksite_layer_id } = useParams<{ uuid: string, worksite_layer_id: string }>();
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();

  const handleModalClose = () => {
    setIsOpen(false);
    store.dispatch(setIsBackdropVisible(false));
  };

  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  };

  const fetchSelectedFile = (file_id: string) => {
    networking.get(`/api/v1/files/${file_id}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.File>) => {
        setFile(response.data.data);
      })
      .catch((error) => {
        Sentry.captureException(error);
        toasters.error(t('Failed to fetch map file data'));
      });
  };

  const fetchSelectedLayer = (layer_id: string) => {
    if (!isValidUUID(layer_id)) return;

    store.dispatch(setIsLoading({ name: 'work_site_layer', value: true }));
    const searchParams = new URLSearchParams();
    searchParams.append('with[]', 'file');
    networking.get(`/api/v1/work_site_layers/${layer_id}?${searchParams.toString()}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.WorkSiteLayer>) => {
        setFile(response.data.data.file);
        setSelectedLayer(response.data.data);
        getFileContent(response.data.data.file);
      })
      .catch((error) => {
        Sentry.captureException(error);
        toasters.error(t('Failed to fetch map file data'));
        router.push(`/tools/${selectedProject?.id}/map`);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'work_site_layer', value: false }));
      });
  };

  const fetchAvailableWorksites = () => {
    if (!selectedProject || uuid !== selectedProject.id) return;
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('with[]', 'layers');
    urlSearchParams.append('search', search);
    networking.get(`/api/v1/projects/${selectedProject.id}/work_sites?${urlSearchParams.toString()}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<(E2U.V1.Models.WorkSite & {
        layers: E2U.V1.Models.WorkSiteLayer[];
      })>>) => {
        setWorksites(response.data.data.records);
        if (search !== '' && response.data.data.records.length === 0) {
          toasters.info(t('No map available for search {search}', 'No map available for search {search}', {
            search
          }));
          return;
        }
        storage.get(`selected_layer_for_${selectedProject?.id}`)
          .then((selectedLayerId) => {
            if (selectedLayerId && !worksite_layer_id) {
              router.push(`/tools/${selectedProject?.id}/map/${selectedLayerId}`, 'forward', 'replace');
            }
          });
      })
      .catch((error) => {
        Sentry.captureException(error);
        toasters.error(t('Failed to fetch worksite data'));
      });
  };

  const getFileContent = (file: E2U.V1.Models.File) => {
    networking.get(`/api/v1/files/${file.id}/export?base64=true`)
      .then((response: AxiosResponse<string>) => {
        setFileContent(`data:application/pdf;base64,${response.data}`);
      })
      .catch((error) => {
        Sentry.captureException(error);
        toasters.error(t('Failed to fetch file content'));
      });
  };

  const addMoreLayers = () => {
    setIsOpen(false);
    setShouldRedirect(true);
  };

  const handleSelectedLayer = (layer: E2U.V1.Models.WorkSiteLayer) => {
    handleModalClose();
    storage.set(`selected_layer_for_${selectedProject?.id}`, layer.id);

    router.push(
      `/tools/${selectedProject?.id}/map/${layer.id}`,
      'none',
      'replace'
    );
  };

  const handleSelectedLayerUpdate = () => {
    if (selectedLayer) {
      setIsOpen(false);
      if (!worksite_layer_id) {
        /**
         * Quite weird but can happen due to the way the "map" is
         * linked (it requires layer, which is not present
         * on non-"working location" page).
         */
        handleSelectedLayer(selectedLayer);
        return;
      }

      if (!file || file.id !== selectedLayer.file_id) {
        fetchSelectedFile(selectedLayer.file_id);
      }
    }
  };

  useEffect(() => {
    fetchAvailableWorksites();
  }, [selectedProjectId, search]);

  useEffect(() => {
    handleSelectedLayerUpdate();
  }, [selectedLayer]);

  useEffect(() => {
    store.dispatch(setShouldHideMenu(true));
    fetchAvailableWorksites();

    if (worksite_layer_id) {
      fetchSelectedLayer(worksite_layer_id);
    }

    return () => {
      store.dispatch(setShouldHideMenu(false));
    };
  }, [worksite_layer_id]);

  const handleProtectedPdf = (callback: (password: string) => void) => {
    presentAlert({
      header: i18n.t('Password required'),
      subHeader: i18n.t('This document is password protected'),
      message: i18n.t('Please enter the password to view the document'),
      inputs: [
        {
          name: 'password',
          type: 'password',
          placeholder: i18n.t('Password')
        }
      ],
      buttons: [
        {
          text: i18n.t('Cancel'),
          role: 'cancel'
        },
        {
          text: i18n.t('Ok'),
          handler: (data) => {
            callback(data.password);
          }
        }
      ]
    });
  };

  if (isLoadingWorksiteLayer) {
    return <SkeletonItem amount={5} />;
  }

  return (
    <>
      <div className={styles.mapContainer}>
        {!file
          ? (
            <EmptyList
              title={t('No map available')}
              message={t('No map available for this worksite, upload layers to worksite')}
              helperLinks={{ url: `/tools/${selectedProject?.id}/worksites`, title: t('Add layers to worksite') }}
            />
          )
          : (
            <TransformWrapper pinch={{ step: 5 }} ref={transformComponentRef} initialScale={1.5} >
              {() => (
                <React.Fragment>
                  <TransformComponent wrapperStyle={{ width: '100%', height: '65vh' }}>
                    {(file && file.type === 'image')
                      ? <img src={fileContent} alt={file.name} />
                      : ((file && file.filetype === 'pdf')
                        ? <Document
                          onLoadSuccess={onDocumentLoadSuccess}
                          file={fileContent}
                          options={options}
                          onPassword={(callback) => handleProtectedPdf(callback)}
                        >
                          <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
                        </Document>
                        : <p>{t('Document type not supported')}</p>
                      )
                    }
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
          )
        }

        <BottomButtons
          onClick={() => setIsOpen(true)}
          hasLayers={!!file} pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          numPages={numPages ?? 0}
          selectedProject={selectedProject}
        />
      </div>

      <BigUp.BottomSheet
        isOpen={isOpen}
        onIonModalDidDismiss={() => {
          handleModalClose();
          shouldRedirect && router.push(`/tools/${selectedProjectId}/worksites/${selectedLayer?.work_site_id}`);
        }}
        {...isDesktop && { style: { height: '100%' } }}
      >
        <div className='ion-margin-top ion-padding-horizontal'>
          <IonSearchbar
            mode='ios'
            value={search}
            onIonChange={(e) => setSearch(e.detail.value!)}
            placeholder={t('Search map layers')}
            debounce={300}
          />
        </div>
        <IonList className='ion-padding'>
          <IonItem
            className='ion-no-padding'
            onClick={() => {
              addMoreLayers();
            }}
            button
            detailIcon={add}
            slot="end"
          >
            <IonLabel>{t('Add more layers')}</IonLabel>
          </IonItem>
          {worksites.filter((w) => w.layers.length).map((worksite, i) => (
            <React.Fragment key={worksite.id} >
              {worksite.layers.map((layer: E2U.V1.Models.WorkSiteLayer) => {
                return (
                  <>
                    {layer.is_visible
                      ? (
                        <IonItem className='ion-no-padding' key={layer.id} onClick={() => handleSelectedLayer(layer)}>
                          <IonLabel>{worksite.name}: {layer.name}</IonLabel>
                          {layer.id === selectedLayer?.id && <IonIcon aria-hidden="true" icon={checkmark} slot="end" />}
                        </IonItem>
                      )
                      : <></>
                    }
                  </>
                );
              })}
            </React.Fragment>
          ))}
        </IonList>
      </BigUp.BottomSheet>
    </>
  );
};

export default WorksiteMapPage;
