import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../api/networking';
import BigUp from '../../../components/UI';
import {
  patchColors,
} from '../../../components/UI/Inputs/ColorPatchPicker/colorPatches';
import toasters from '../../../components/UI/Toasts';
import { useAppSelector } from '../../../hooks';

interface TagFormModalProps {
  isOpen?: boolean;
  selectedTag?: E2U.V1.Models.Tag;
  onDismiss: () => void;
  onSaved: (tag: E2U.V1.Models.Tag) => void;
  tag_category_id: string;
}

const TagFormModal: React.FC<TagFormModalProps> = ({ isOpen, onDismiss, onSaved, selectedTag, tag_category_id }) => {
  const { t } = useTranslation();
  const methods = useForm<E2U.V1.Models.Tag & { project_id: string }>();

  const updateFormValuesIfApplicable = () => {
    if (!selectedTag) {
      return;
    }
    if (tag_category_id) {
      methods.setValue('category_id', tag_category_id);
    }
    if (selectedTag.id) {
      methods.setValue('id', selectedTag.id);
    }
    if (selectedTag.name) {
      methods.setValue('name', selectedTag.name);
    }
  };

  const handleDismiss = () => {
    methods.reset({});
    if (onDismiss) {
      onDismiss();
    }
  };

  const handleSubmit: SubmitHandler<E2U.V1.Models.Tag> = (data) => {
    const call = data.id
      ? networking.put(`/api/v1/tags/${data.id}`, data)
      : networking.post(`/api/v1/tag_categories/${tag_category_id}/tags`, data);
    return toasters.promiseToast(
      call,
      {
        pending: {
          message: t('Saving tag'),
        },
        success: {
          message: t('Tag saved!')
        },
        error: {
          message: t('Failed to save tag')
        }
      }
    )
      .catch(
        (err) => {
          Sentry.captureException(err);
        }
      )
      .then(
        (response: E2U.V1.Response.Success<E2U.V1.Models.Tag>) => onSaved && onSaved(response.data.data)
      );
  };

  useEffect(() => {
    updateFormValuesIfApplicable();
  }, [selectedTag, isOpen, tag_category_id]);

  return (
    <FormProvider {...methods}>
      <BigUp.Modal.InputModal
        modal={{
          isOpen: !!isOpen,
          onDismiss: () => handleDismiss(),
          title: selectedTag ? t('Edit tag') : t('Create new tag')
        }}
        button={{
          title: selectedTag ? t('Save') : t('Create'),
          disabled: methods.formState.isSubmitting || !methods.formState.isValid,
          loading: false,
          type: 'submit',
        }}
        input={[
          {
            id: 'name',
            name: 'name',
            register: 'name',
            customLabel: t('Name'),
            placeholder: t('Enter tag name'),
            validation: {
              required: {
                value: true,
                message: t('Tag name is required')
              }
            }
          }
        ]}
        onSubmit={methods.handleSubmit(handleSubmit)}
        textAlignment={'left'}
        maxHeight={'260px'}
      />
    </FormProvider>
  );
};

export default TagFormModal;
