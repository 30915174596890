import { IonIcon } from '@ionic/react';
import React, { useMemo } from 'react';

import styles from './GroupTitleColumn.module.scss';
import BigUp from '../../../components/UI';
import type { PatchColors } from '../../../components/UI/Inputs/ColorPatchPicker/colorPatches';

interface CategoryTitleColumnProps {
  value?: string;
  attributes?: object & { color: PatchColors; tag_count: number };
}

export const getTagGroupColor = (color: PatchColors) => {
  const colorImportName = color.charAt(0).toUpperCase() + color.slice(1);
  return BigUp.Icons.Svg[`Tag${colorImportName}`] ?? BigUp.Icons.Svg.TagGrey;
};

const TagGroupTitleColumn: React.FC<React.PropsWithChildren<CategoryTitleColumnProps>> = ({ attributes, value }) => {
  const { color, tag_count } = attributes || { color: 'purple', tag_count: 0 };

  const folderIcon = useMemo(() => {
    return getTagGroupColor(color);
  }, [color]);

  return (
    <div>
      <BigUp.IconBadge size={'small'}>
        <IonIcon src={folderIcon} />
      </BigUp.IconBadge>
      {value}
      <span className={styles['tag-group-title-column__amount']}>
        {` (${tag_count})`}
      </span>
    </div>
  );
};

export default TagGroupTitleColumn;
