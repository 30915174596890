import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import type { RouteProps } from 'react-router-dom';

import { useAppSelector } from '../hooks';
import ProtectedRoute from './ProtectedRoute';
import { WrappedRouteListener } from './WrappedRoute';
import { optionalOnboardingRoute } from '../constants/onboarding';
import useAcl from '../hooks/useAcl';

interface AbilitiesWatchedRouteParams extends RouteProps {
  path: string;
  type?: 'project' | 'client';
  requiredAbilities?: string[];
}

export const AbilitiesWatchedRoute: React.FC<AbilitiesWatchedRouteParams> = ({
  path,
  type = 'project',
  ...rest
}) => {
  const sanitisedPath = (path.endsWith('/') ? path.slice(0, -1) : path);
  return (
    <ProtectedRoute
      {...rest}
      path={
        rest.exact
          ? [`${sanitisedPath}`, `${sanitisedPath}${optionalOnboardingRoute}`]
          : path
      }
    >
      {rest.children}

      <WrappedRouteListener path={sanitisedPath} />

      <AbilitiesWatchedRouteListener
        path={path}
        requiredAbilities={rest.requiredAbilities}
        type={type}
      />
    </ProtectedRoute>
  );
};

const AbilitiesWatchedRouteListener: React.FC<AbilitiesWatchedRouteParams> = (props) => {
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const history = useHistory();
  const [permissionsFetchedForRoute, setPermissionsFetchedForRoute] = useState(false);
  const { client, fetchPermissions, isOnboarded, project, scopedAbilities: abilities } = useAcl();
  const requiredAbilities = props.requiredAbilities || [];
  const isLoadingUser = useAppSelector(state => state.loading.isLoading.user);
  const isLoadingAbilities = useAppSelector(state => state.loading.isLoading.abilities);
  const sanitisedPath = (props.path.endsWith('/') ? props.path.slice(0, -1) : props.path);

  const checkAbilities = () => {
    if (
      (props.type === 'client' && !client) ||
      (props.type === 'project' && !project) ||
      (isOnboarded() && (!abilities || abilities.length === 0))
    ) {
      return;
    }

    if (requiredAbilities.length > 0) {
      const hasAbilities = requiredAbilities.every((ability) => abilities.includes(ability));

      if (!hasAbilities) {
        history.replace(isOnboarded() ? '/unauthorized' : '/introduction', {
          required_abilities: requiredAbilities,
          type: props.type,
          requested_route: location.pathname
        });
      }
    }
  };

  useEffect(() => {
    if (uuid && props.type) {
      fetchPermissions({
        scope: {
          uuid,
          type: props.type
        }
      });
    }
  }, [sanitisedPath]);

  useEffect(() => {
    if ((!isLoadingUser && !isLoadingAbilities)) {
      checkAbilities();
    }
  }, [abilities, requiredAbilities, isLoadingUser, isLoadingAbilities]);

  useEffect(() => {
    checkAbilities();
  }, []);

  useEffect(() => {
    if (!isLoadingUser && !isLoadingAbilities && !permissionsFetchedForRoute) {
      fetchPermissions();
      setPermissionsFetchedForRoute(true);
    }
  }, [isLoadingUser]);

  return (
    <></>
  );
};
