import { IonAvatar, IonImg } from '@ionic/react';
import React, { useMemo } from 'react';

import styles from './Avatar.module.scss';
import placeholder from '../assets/images/placeholder-user.png';
import useProfilePicture from '../hooks/useProfilePicture';

type AvatarSource = 'userImage' | 'placeholder';

export interface AvatarProps extends React.HTMLAttributes<HTMLIonImgElement & HTMLIonAvatarElement> {
  avatarSource: AvatarSource;
  className?: string;
  type?: 'inline' | 'block';
}

const defaultStyles = {
  size: '50px'
};

const CustomAvatar: React.FC<AvatarProps> = ({ avatarSource, className, style, ...props }) => {
  const sizeProps = {
    width: style?.width || defaultStyles.size,
    height: style?.height || defaultStyles.size
  };

  const avatarSources: Record<AvatarProps['avatarSource'], string> = {
    userImage: useProfilePicture(),
    placeholder,
  };

  const imageAlt: Record<AvatarProps['avatarSource'], string> = {
    userImage: 'employee profile picture',
    placeholder: 'user placeholder image',
  };

  const alt = imageAlt[avatarSource];
  const avatarSrc = avatarSources[avatarSource];
  const type = (props.type || 'block') === 'block';
  const classNames = useMemo(() => {
    const names = [className];

    if (type) {
      names.push(styles.block);
    }

    return names;
  }, [className, type]);

  return (
    <IonAvatar className={classNames.join('')} style={sizeProps}>
      <IonImg {...props}
        style={{
          width: sizeProps.width,
          height: sizeProps.height,
        }}
        src={avatarSrc}
        alt={alt} />
    </IonAvatar>
  );
};

const AvatarImage = (props: AvatarProps) => {
  return <CustomAvatar {...props} />;
};

const Avatar = {
  AvatarImage
};
export default Avatar;
