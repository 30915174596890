import { IonContent, IonModal } from '@ionic/react';

import styles from './BottomSheet.module.scss';
import type BottomSheetProps from './InputModal/interface';

const BottomSheet: React.FC<BottomSheetProps> = (props, { contentProps }) => {
  return (
    <IonModal
      style={{ '--height': props.sheetHeight || '500px' }}
      mode='ios'
      {...props}
      isOpen={props.isOpen}
      initialBreakpoint={props.initialBreakpoint || 1} breakpoints={props.breakpoints || [0, 1]}
    >
      <IonContent {...contentProps} className={styles.bottomSheetContent}>
        {props.children}
      </IonContent>
    </IonModal>
  );
};
export default BottomSheet;
