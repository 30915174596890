import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonIcon, IonModal, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import classNames from 'classnames';
import { close } from 'ionicons/icons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './InputModal.module.scss';
import type { InputModalProps } from './interface';
import SingleInput from './SingleInput';
import BigUp from '../../';
import checkmark from '../../../icons/checkmark.svg';

const InputModal: React.FC<InputModalProps> = (props) => {
  const { t } = useTranslation();

  const {
    button: { disabled, loading, onClick, title: buttonTitle, type },
    input,
    maxHeight,
    modal: { description, isOpen, onDismiss, successMessage, title },
    onSubmit,
    scrollY = false,
    textAlignment = 'center'
  } = props;

  const alignment = useMemo(
    () => {
      switch (textAlignment) {
        case 'right':
          return 'ion-justify-content-end';
        case 'center':
          return 'ion-justify-content-center';
        default:
          return 'ion-justify-content-start';
      }
    },
    [textAlignment]
  );

  const typographyAlignment = useMemo(
    () => {
      switch (textAlignment) {
        case 'right':
          return 'ion-text-end';
        case 'center':
          return 'ion-text-center';
        default:
          return 'ion-text-start';
      }
    },
    [textAlignment]
  );

  return (
    <IonModal
      isOpen={isOpen}
      onIonModalDidDismiss={onDismiss}
      className={styles['input-modal']}
      style={{
        '--max-height': maxHeight ?? '300px'
      }}
    >
      <IonToolbar mode='ios' color={'light'} >
        <IonTitle className={styles['input-modal__title']}>{title}</IonTitle>
        <IonButtons slot='end'>
          <IonButton onClick={onDismiss}>
            <IonIcon slot="icon-only" icon={close} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent scrollY={scrollY}>
        <form onSubmit={onSubmit}>
          <IonGrid>
            {description && <IonRow
              className={
                alignment
              }
            >
              <IonCol
                size='auto'
                className={
                  classNames(
                    'ion-no-padding ion-padding-horizontal',
                    typographyAlignment
                  )
                }>
                <BigUp.Typography.Description>
                  <IonText>
                    {description}
                  </IonText>
                </BigUp.Typography.Description>
              </IonCol>
            </IonRow>}
            {!successMessage && (
              Array.isArray(input)
                ? input.map((inp, index) => (
                  <IonRow key={index}>
                    <IonCol className={
                      (index !== 0 && index !== input.length - 1)
                        ? 'ion-no-padding ion-padding-horizontal'
                        : 'ion-padding'
                    }>
                      <SingleInput {...inp} />
                    </IonCol>
                  </IonRow>
                ))
                : <IonRow>
                  <IonCol className='ion-padding'>
                    <SingleInput {...input} />
                  </IonCol>
                </IonRow>
            )}
            <IonRow className='ion-justify-content-center'>
              <IonCol size='auto'>
                {successMessage
                  ? <IonIcon icon={checkmark} className={successMessage ? styles['success-icon'] : ''} />
                  : (
                    <BigUp.Buttons.Primary
                      loading={loading}
                      onClick={onClick}
                      {...type !== undefined && {
                        type: 'submit'
                      }}
                      title={buttonTitle || t('Save')}
                      disabled={disabled}
                    />
                  )
                }
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </IonModal>
  );
};
export default InputModal;
