import { add, close } from 'ionicons/icons';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FilterChips from '../../Chips/FilterChips';
import BigUp from '../../index';
import toasters from '../../Toasts';
import type { ColorPatch } from '../ColorPatchPicker/colorPatches';
import type { InputProps } from '../Input.interface';

type TagsInputProps = InputProps<string> & {
  tagsPropertyName?: string;
}

const TagsInput: React.FC<React.PropsWithChildren<TagsInputProps>> = (props) => {
  const { t } = useTranslation();
  const { tagsPropertyName = 'tags' } = props;
  const methods = useFormContext<{ [key: string]: string[]|string|ColorPatch, tags_input: string }>();
  const tags = methods.watch(tagsPropertyName, []) as string[];
  const tagsInput = methods.watch('tags_input');
  const color = methods.watch('color') as ColorPatch;

  const handleAddTag = (forceValue?: string) => {
    if (!tagsInput && !forceValue) return;
    const tagValue = forceValue && forceValue !== '' ? forceValue : tagsInput;
    if (tags.some(tag => tag.toUpperCase() === tagValue.toUpperCase())) {
      toasters.createToast({
        message: t('Tag already exists'),
      }, 'warning');
      methods.setValue('tags_input', '');
      return;
    }
    methods.setValue(tagsPropertyName, [...tags, tagValue]);
    methods.setValue('tags_input', '');
  };

  return (<>
    <BigUp.OutlinedInput
      name={'tags_input'}
      customLabel={t('Tags')}
      register={'tags_input'}
      placeholder={t('Enter tag')}
      validation={{ }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          const value = e.currentTarget?.value ?? '';
          handleAddTag(`${value}`);
        }
      }}
    >
      <BigUp.Buttons.SecondaryIcon
        slot={'end'}
        icon={{ icon: add }}
        size={'small'}
        onClick={() => handleAddTag()}
      />
    </BigUp.OutlinedInput>
    <div className={'ion-margin-top'}>
      <FilterChips
        data={tags}
        renderChipLabel={(tag) => tag}
        chipIcon={close}
        onChipClick={
          (tag) => {
            methods.setValue(tagsPropertyName, tags.filter(t => t !== tag));
          }
        }
        color={color ?? undefined}
      />
    </div>
  </>);
};

export default TagsInput;
