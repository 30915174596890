import { IonCol, IonGrid, IonIcon, IonItem, IonRow } from '@ionic/react';
import { openOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TermsAndConditionsModal from './TermsAndConditionsModal';
import useModal from '../../hooks/useModal';
import i18n from '../../i18n';
import BigUp from '../UI';

const TermsAndConditionCheckbox: React.FC = () => {
  const [agreed, setAgreed] = useState(false);
  const methods = useFormContext();
  const termsAndConditionsModal = useModal();
  const { t } = useTranslation();

  const openTermsAndConditions = () => {
    termsAndConditionsModal.toggleModal();
  };

  const handleModal = (handling: 'accept-terms' | 'decline-terms') => {
    if (handling === 'accept-terms') {
      setAgreed(true);
      methods.setValue('terms_and_conditions', true);
    }
    if (handling === 'decline-terms') {
      setAgreed(false);
      methods.setValue('terms_and_conditions', false);
    }
    termsAndConditionsModal.closeModal();
  };

  return (
    <>
      <IonGrid>
        <IonRow className='ion-align-items-center ion-justify-content-between'>
          <IonCol size='10'>
            <IonItem style={{ maxWidth: '300px' }} button onClick={openTermsAndConditions} className='ion-no-padding'>
              <BigUp.Label.Regular className='ion-no-margin' label={i18n.t('Terms and conditions')} />
              <IonIcon icon={openOutline} />
            </IonItem>
          </IonCol>
          <IonCol size='auto'>
            <BigUp.Checkbox
              checked={agreed}
              labelPlacement='start'
              handleCheckbox={(checked) => {
                setAgreed(checked);
                methods.setValue('terms_and_conditions', checked);
              }}
              validation={{
                required: { value: true, message: t('You must agree to the terms and conditions') }
              }}
            />
          </IonCol>
        </IonRow>
      </IonGrid>

      <TermsAndConditionsModal
        handleModal={handleModal}
        modalStateHandlers={{
          open: termsAndConditionsModal.openModal,
          close: termsAndConditionsModal.closeModal,
          isOpen: termsAndConditionsModal.isModalOpen
        }}
      />
    </>
  );
};

export default TermsAndConditionCheckbox;
