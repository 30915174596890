import { IonContent } from '@ionic/react';
import { close } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';

import BigUp from '../UI';
import type { ModalProps } from './interface';
import { termsAndConditions } from './terms';

const TermsAndConditionsModal: React.FC<ModalProps> = ({ handleModal, modalStateHandlers }) => {
  const { t } = useTranslation();

  return (
    <BigUp.Modal.Wrapper
      height='90%'
      width='70%'
      header={{
        title: t('Terms and conditions'),
        end: {
          icon: close,
          onClick: () => modalStateHandlers.close()
        }
      }}
      isOpen={modalStateHandlers.isOpen}
      dismiss={modalStateHandlers.close}
    >
      <IonContent className='ion-padding'>
        <Markdown>{termsAndConditions}</Markdown>
      </IonContent>
    </BigUp.Modal.Wrapper>
  );
};

export default TermsAndConditionsModal;
