import { folderOpen, hammerOutline } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import MultiSelector from '../../components/UI/MultiSelector';
import type { MultiSelectorProps } from '../../components/UI/MultiSelector/interfaces';

interface DocumentFilterProps {
  sourceUrl: MultiSelectorProps['sourceUrl'];
  group?: MultiSelectorProps['group'];
}

const DocumentCategoryFilter: React.FC<DocumentFilterProps> = (props) => {
  const { group, sourceUrl } = props;
  const { t } = useTranslation();
  return (
    <MultiSelector
      title={t('Category')}
      identifier='categories'
      triggerProps={{
        icon: folderOpen,
        label: t('Category')
      }}
      sourceUrl={sourceUrl}
      callbacks={{
        parseResponse: (response: {
          id: string;
          name: string;
          slug: string;
          disabled: boolean;
        }[]) => {
          return response.map((item: any) => ({
            label: item.name,
            value: item.id,
            disabled: item.disabled,
            children: item.children?.map((child: any) => ({
              label: child.name,
              value: child.id,
              disabled: child.disabled,
            }))
          }));
        }
      }}
      group={group} />
  );
};

const DocumentActivityCodeFilter: React.FC<DocumentFilterProps> = (props) => {
  const { group, sourceUrl } = props;
  const { t } = useTranslation();

  return (
    <MultiSelector
      title={t('Activity Codes')}
      identifier='activity_codes'
      triggerProps={{
        icon: hammerOutline,
        label: t('Activity code'),
        chips: {
          triggerPlacement: 'inner-top',
          type: 'group'
        }
      }}
      sourceUrl={sourceUrl}
      callbacks={{
        parseResponse: (response: {
          id: string;
          code: string;
          name: string;
          disabled: boolean;
        }[]) => {
          return response.map((item: any) => ({
            label: item.name,
            value: item.id,
            disabled: item.disabled,
            children: item.children?.map((child: any) => ({
              label: child.name,
              value: child.id,
              disabled: child.disabled,
            }))
          }));
        }
      }}
      group={group} />
  );
};

const DocumentExtensionCodeFilter: React.FC<DocumentFilterProps> = (props) => {
  const { group, sourceUrl } = props;
  const { t } = useTranslation();
  return (
    <MultiSelector
      title={t('Type')}
      identifier='extensions'
      triggerProps={{
        label: t('Type'),
      }}
      sourceUrl={sourceUrl}
      callbacks={{
        parseResponse: (response: {
          extension: string;
          disabled: boolean;
        }[]) => {
          return response.map((item: any) => ({
            label: item.extension.toUpperCase(),
            value: item.extension,
            disabled: item.disabled,
            children: item.children?.map((child: any) => ({
              label: child.extension.toUpperCase(),
              value: child.extension,
              disabled: child.disabled,
            }))
          }));
        }
      }}
      group={group} />
  );
};

export {
  DocumentCategoryFilter,
  DocumentActivityCodeFilter,
  DocumentExtensionCodeFilter
};
