import { IonButton, IonIcon, IonLabel } from '@ionic/react';
import classNames from 'classnames';
import { chevronDown, chevronDownOutline } from 'ionicons/icons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { generateIcon } from './helpers';
import type { MultiSelectorDefaultTriggerProps } from './interfaces';
import styles from './MultiSelector.module.scss';
import BigUp from '../index';
import SelectOutlinedBase from '../Select/SelectOutlinedBase';

const MultiSelectorTrigger: React.FC<React.PropsWithChildren<MultiSelectorDefaultTriggerProps>> = (props) => {
  const { t } = useTranslation();

  const icon = useMemo(() => generateIcon({
    slot: 'start',
    icon: props.icon,
    className: styles.trigger__default_icon
  }), [props.icon]);

  const label = useMemo(() => {
    if (props?.children) {
      return (
        <IonLabel className={classNames('ion-no-margin', styles.trigger__default_label, props.labelClassName ?? '')}>
          {props?.children}
        </IonLabel>
      );
    }

    if (props?.label) {
      if (typeof props?.label === 'function') {
        return props.label(props?.selectedItems || []);
      }

      return props.label;
    }

    return <IonLabel
      className={classNames(styles.trigger__default_label, props.labelClassName ?? '')}>{t('Select value')}</IonLabel>;
  }, [props.label, props.children]);

  const generateLabel = ({ items, selectedItems }) => {
    if (selectedItems.length === 0) {
      return t('Select value');
    }

    const itemsFlat = items.flatMap(
      (item) => [item, ...(item.children && item.children.length > 0 ? item.children : [])]
    );

    if (selectedItems.length === 1) {
      const selectedItem = itemsFlat.find(i => i.value === selectedItems[0]);
      const label = (selectedItem?.shortLabel ?? selectedItem?.label) ?? '';
      if (selectedItem.icon) {
        return (
          <>
            <IonIcon
              icon={selectedItem.icon}
              slot={'start'}
              className={'ion-no-margin'}
            />
            <BigUp.Label.Regular
              label={label}
            />
          </>
        );
      }
    }

    if (selectedItems.length === itemsFlat.length) {
      return t('All selected');
    }

    if (selectedItems.length < 4) {
      return selectedItems.map((i) => {
        const selectedItem = itemsFlat.find(item => item.value === i);
        return selectedItem?.shortLabel ?? selectedItem?.label;
      }).join(', ');
    }

    return t('{amount} selected', { amount: selectedItems.length });
  };

  if (props.chips?.showAsSelect) {
    return (
      <SelectOutlinedBase label={props.label} onClick={props.onClick}>
        {props.items && props.items.length > 0 && (
          generateLabel({
            items: props.items,
            selectedItems: props.selectedItems
          })
        )}
        <IonIcon icon={chevronDown} slot={'end'} />
      </SelectOutlinedBase>
    );
  }

  return (
    <IonButton
      fill='outline'
      onClick={props.onClick}
      className={classNames(styles.trigger__default, props.className ?? '', icon ? '' : styles.trigger__default_without_icon)}>
      {icon && icon}
      {label}
      {(!props.children || !props.hideChevron) && (
        <IonIcon
          icon={chevronDownOutline}
          slot='end'
          className={styles.trigger__default_icon_toggle}
        />
      ) }
    </IonButton>
  );
};

export default MultiSelectorTrigger;
