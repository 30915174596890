import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonPopover } from '@ionic/react';
import type { ComponentProps, MouseEventHandler } from 'react';
import React from 'react';

import styles from './Popover.module.scss';

export interface PopoverItem {
  value: string;
  label: string;
  onClick: () => void;
  icon?: ComponentProps<typeof IonIcon>;
  role?: string;
}

interface PopoverHandling {
  usesID?: boolean;
  items: PopoverItem[];
  actionId: string;
}

const Popover: React.FC<PopoverHandling> = (props) => {
  const { usesID = false } = props;
  return (
    usesID
      ? (
        <IonPopover trigger={props.actionId} dismissOnSelect={true}>
          <IonContent scrollY className={styles['popover-element']}>
            <IonList className={'ion-no-padding'}>
              {props.items.map((item) => (
                <IonItem key={item.label} button={true} detail={false} onClick={(e) => item.onClick(e)}
                  className='ion-no-padding'>
                  {item.icon && <IonIcon icon={item.icon?.icon}
                    className={`${styles['popover-item-icon']} ${item.icon?.className}`} />}
                  <IonLabel className='ion-no-margin ion-padding-horizontal'>
                    {item.label}
                  </IonLabel>
                </IonItem>
              ))}
            </IonList>
          </IonContent>
        </IonPopover>
      )
      : (
        <IonContent scrollY className={styles['popover-element']}>
          <IonList className={'ion-no-padding'}>
            {props.items.map((item) => (
              <IonItem
                key={item.label}
                button={true}
                detail={false}
                onClick={item.onClick}
                className='ion-no-padding'
              >
                <IonLabel className='ion-no-margin ion-padding-horizontal'>
                  {item.label}
                </IonLabel>
                {item.icon && <IonIcon icon={item.icon?.icon} className={styles['popover-item-icon']} />}
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      )
  );
};
export default Popover;
