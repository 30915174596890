import { IonCol, IonIcon, IonRow } from '@ionic/react';
import { checkmark } from 'ionicons/icons';
import React from 'react';
import { Controller, type RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { colorPatches } from './colorPatches';
import styles from './ColorPatchPicker.module.scss';
import BigUp from '../../index';

interface ColorPatchPickerProps {
  name?: string;
  defaultValue?: string | number | readonly string[] | undefined;
  customLabel?: string;
  validation: RegisterOptions;
}

const ColorPatchPicker: React.FC<ColorPatchPickerProps> = ({ customLabel, defaultValue, name, validation }) => {
  const { t } = useTranslation();
  return (!name)
    ? <p>{t('Well this was awkward. Missing name for component.')}</p>
    : (
      <Controller
        render={({ field, formState }) => <IonRow>
          <IonCol size={'12'} className={'ion-no-padding ion-padding-vertical'}>
            {customLabel && (
              <BigUp.Label.Regular
                label={`${customLabel} ${validation?.required ? '*' : ''}`}
                className={`ion-no-margin ${styles['color-patch__label']}`}
                position='stacked'
                {...(formState.errors[name] ? { color: 'danger' } : {})}
              />
            )}
          </IonCol>
          <IonCol className={'ion-no-padding'}>
            {colorPatches.map(
              (color, i) =>
                <span
                  className={styles['color-patch']}
                  key={i}
                  onClick={() => field.onChange(color.key)}
                  style={{
                    background: color.value
                  }}
                >
                  {(field.value === color.key) && <IonIcon icon={checkmark} color={'white'} />}
                </span>
            )}
          </IonCol>
        </IonRow>
        }
        name={name}
        defaultValue={defaultValue}
        rules={validation}
      />
    );
};

export default ColorPatchPicker;
