
import type { E2U } from '@techlove/easy2use-typings';

import BigUp from '../../../components/UI';
import { ionicColours } from '../../../components/UI/variables';
import i18n from '../../../i18n';

interface DocumentTypeProperties {
  docColour: string;
  categoryName: string;
  route?: string;
  file?: boolean;
  resourceType?: string;
  state: Record<string, any>;
  icon?: string;
}

const getPropertiesByType: (type: string) => DocumentTypeProperties = (type) => {
  const document_base_resource = `App\\Models\\`;
  switch (type) {
    case `${document_base_resource}File`:
      return {
        docColour: ionicColours.danger,
        categoryName: i18n.t('File'),
        route: `/tools/{project_id}/documents/{document_id}`,
        file: true,
        resourceType: `${document_base_resource}File`,
        state: {}
      };
    case `${document_base_resource}Documents\\Document`:
      return {
        docColour: ionicColours.danger,
        categoryName: i18n.t('Generic Document'),
        route: `/tools/{project_id}/documents/{document_id}`,
        file: true,
        resourceType: `${document_base_resource}Documents\\Document`,
        state: {}
      };
    case `${document_base_resource}ControlOfExecution`:
      return {
        docColour: ionicColours.secondary,
        categoryName: i18n.t('Control of execution', 'Control of execution'),
        resourceType: `${document_base_resource}ControlOfExecution`,
        route: `/tools/{project_id}/control-of-execution/{resource_id}`,
        file: true,
        state: { subpanel: 'control-of-execution' }
      };
    case `${document_base_resource}WorkingEnvironment`:
      return {
        docColour: ionicColours.warning,
        categoryName: i18n.t('Working environment'),
        resourceType: `${document_base_resource}WorkingEnvironment`,
        route: `/tools/{project_id}/documents/reports/working_environment/{resource_id}`,
        state: {},
        icon: BigUp.Icons.Svg.WorkingEnvironment
      };
    case `${document_base_resource}WorkingEnvironmentEntryReport`:
      return {
        docColour: ionicColours.medium,
        categoryName: i18n.t('Working environment entry report'),
        resourceType: `${document_base_resource}WorkingEnvironmentEntryReport`,
        route: `/tools/{project_id}/documents/reports/working_environment_entry/{resource_id}`,
        state: {
        },
        icon: BigUp.Icons.Svg.WorkingEnvironment
      };
    case `${document_base_resource}ObservationReport`:
      return {
        docColour: ionicColours.success,
        categoryName: i18n.t('Observation report'),
        resourceType: `${document_base_resource}ObservationReport`,
        route: `/tools/{project_id}/reports/observations/{resource_id}`,
        state: {}
      };
    case `${document_base_resource}IncidentReport`:
      return {
        docColour: ionicColours.mediumShade,
        categoryName: i18n.t('Incident report'),
        resourceType: `${document_base_resource}IncidentReport`,
        route: `/tools/{project_id}/reports/incidents/{resource_id}`,
        state: {}
      };
    case `${document_base_resource}SupplierInvoice`:
      return {
        docColour: ionicColours.secondary,
        categoryName: i18n.t('Supplier invoices'),
        resourceType: `${document_base_resource}SupplierInvoice`,
        route: `/tools/{project_id}/documents/{document_id}`,
        file: true,
        state: {},
        icon: BigUp.Icons.Svg.FortnoxIcon
      };
    case `${document_base_resource}EconomyMonitoring`:
      return {
        docColour: ionicColours.medium,
        categoryName: i18n.t('Economy monitoring'),
        resourceType: `${document_base_resource}EconomyMonitoring`,
        route: `/financials/{project_id}/monitoring/{resource_id}`,
        state: {},
        icon: BigUp.Icons.Svg.Prognosis
      };
    case `${document_base_resource}CustomerInvoice`:
      return {
        docColour: ionicColours.success,
        categoryName: i18n.t('Customer invoices'),
        resourceType: `${document_base_resource}CustomerInvoice`,
        route: `/tools/{project_id}/documents/{document_id}`,
        file: true,
        state: {},
        icon: BigUp.Icons.Svg.FortnoxIcon
      };
    case `${document_base_resource}Precalculation`:
      return {
        docColour: ionicColours.mediumShade,
        categoryName: i18n.t('Precalculations'),
        resourceType: `${document_base_resource}Precalculation`,
        route: `/financials/{project_id}/precalculations/{resource_id}`,
        state: {
          subpanel: 'precalculations',
        },
        icon: BigUp.Icons.Svg.Calculation
      };
    case `${document_base_resource}EconomyDeviation`:
      return {
        docColour: ionicColours.success,
        categoryName: i18n.t('Deviations'),
        resourceType: `${document_base_resource}EconomyDeviation`,
        route: `/financials/{project_id}/deviations/{resource_id}`,
        state: {},
        icon: BigUp.Icons.Svg.Deviations
      };
    case `${document_base_resource}EconomyChange`:
      return {
        docColour: ionicColours.danger,
        categoryName: i18n.t('Change'),
        resourceType: `${document_base_resource}EconomyChange`,
        route: `/financials/{project_id}/changes/{resource_id}`,
        state: {},
        icon: BigUp.Icons.Svg.Changes
      };
    case `${document_base_resource}WorkPreparations\\WorkPreparationProtocolEntryReport`:
      return {
        docColour: ionicColours.success,
        categoryName: i18n.t('Work preparation entry report'),
        resourceType: `${document_base_resource}WorkPreparations\\WorkPreparationProtocolEntryReport`,
        route: `/tools/preparation/{resource_id}`,
        state: {},
        icon: BigUp.Icons.Svg.WorkPreparation
      };
    default:
      return {
        docColour: ionicColours.primary,
        categoryName: type,
        state: {}
      };
  }
};

export const getDocumentRoute = (row: E2U.V1.Models.Document, selectedProjectId: string) => {
  if (row.resource_type) {
    const type = getPropertiesByType(row.resource_type);
    if (!type || !type.route) {
      return `/tools/${selectedProjectId}/documents/${row.id}`;
    }
    return type
      .route
      .replace('{project_id}', selectedProjectId)
      .replace('{resource_id}', row?.resource_id ?? '');
  } else {
    return `/tools/${selectedProjectId}/documents/${row.id}`;
  }
};

export default getPropertiesByType;
