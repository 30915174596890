import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  Draft,
  PayloadAction,
  SliceCaseReducers
} from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';

export interface FileReducer {
  files: E2U.V1.Models.Document[] | undefined;
  selectedFile: E2U.V1.Models.Document | any | undefined;
  uploadedPhoto: E2U.V1.Models.File[] | undefined;
  filesPaginationData: E2U.V1.Objects.PaginationProperties | undefined;
  lastReloadTs: number | undefined;
  fileHistory: E2U.V1.Models.FileHistory[] | undefined;
}

export const fileSlice = createSlice<FileReducer,
  SliceCaseReducers<FileReducer>,
  string>({
    name: 'file',
    initialState: {
      files: undefined,
      selectedFile: undefined,
      filesPaginationData: undefined,
      uploadedPhoto: undefined,
      lastReloadTs: undefined,
      fileHistory: undefined
    },
    reducers: {
      setFiles: (
        state: Draft<FileReducer>,
        action: PayloadAction<E2U.V1.Models.Document[] | undefined>
      ) => {
        state.files = action.payload;
      },
      setSelectedFile: (
        state: Draft<FileReducer>,
        action: PayloadAction<E2U.V1.Models.Document | undefined>
      ) => {
        state.selectedFile = action.payload;
      },
      setUploadedPhoto: (
        state: Draft<FileReducer>,
        action: PayloadAction<E2U.V1.Models.File[] | undefined>
      ) => {
        state.uploadedPhoto = action.payload;
      },
      setFilesPaginationData: (
        state: Draft<FileReducer>,
        action: PayloadAction<E2U.V1.Objects.PaginationProperties | undefined>
      ) => {
        state.filesPaginationData = action.payload;
      },
      setShouldRefetchDocuments: (
        state: Draft<FileReducer>
      ) => {
        state.lastReloadTs = new Date().getTime();
      },
      setFileHistory: (
        state: Draft<FileReducer>,
        action: PayloadAction<E2U.V1.Models.FileHistory[] | undefined>
      ) => {
        state.fileHistory = action.payload;
      }
    }
  });

export const { setFileHistory, setFiles, setFilesPaginationData, setSelectedFile, setShouldRefetchDocuments, setUploadedPhoto } = fileSlice.actions;

export default fileSlice.reducer;
