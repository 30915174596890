import type { ReactNode } from 'react';
import { useMemo } from 'react';

const DesktopWrapper = (
  { alignment, children, limitHeight = false, paddedPage = true, width }:
    {
      alignment?: string,
      children: ReactNode,
      width?: 'var(--ion-desktop-max-width)' | 'var(--ion-desktop-mid-width)' | string
      paddedPage?: boolean,
      limitHeight?: boolean
    }
) => {
  const limitedHeightStyles = useMemo(() => {
    if (!limitHeight) return {};
    return {
      maxHeight: '100%',
      height: '100%'
    };
  }, [limitHeight]);

  return (
    <div style={{ display: 'flex', justifyContent: `${alignment || 'left'}`, ...limitedHeightStyles }}>
      <div className={
        paddedPage ? 'ion-padding' : ''
      } style={{ maxWidth: `${width || '100%'}`, width: '100%', marginBottom: 20, ...limitedHeightStyles }}>
        {children}
      </div>
    </div>
  );
};

export default DesktopWrapper;
