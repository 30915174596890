import { IonIcon, useIonPopover } from '@ionic/react';
import { lockClosed } from 'ionicons/icons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './LockableNameColumn.module.scss';
import BigUp from '../../index';

interface LockableNameColumnProps {
  value?: string;
  attributes?: object & { is_system_record: boolean; protected_at: string | null };
}

const LockableNameColumn: React.FC<React.PropsWithChildren<LockableNameColumnProps>> = ({ attributes, value }) => {
  const isLocked = useMemo(() => attributes?.is_system_record || attributes?.protected_at, [attributes]);
  const { t } = useTranslation();
  const [present] = useIonPopover(BigUp.Popovers.Message, {
    children: <BigUp.Label.Regular
      label={t('Locked tags are created by the system, and cannot be changed or de-attached.')}
      className={'ion-no-margin'}
    />,
  });

  const handleShowLockMessage = (e: React.MouseEvent) => {
    present({
      dismissOnSelect: true,
      event: e.nativeEvent,
      arrow: true,
      showBackdrop: false,
    });
  };

  return (
    <div>
      {value}
      {isLocked && (
        <IonIcon
          icon={lockClosed}
          className={styles['lockable-name']}

          onClick={(e: React.MouseEvent) => handleShowLockMessage(e)}
          role={'button'}
        />
      )}
    </div>
  );
};

export default LockableNameColumn;
