import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../../api/networking';
import BigUp from '../../../../components/UI';
import {
  patchColors,
} from '../../../../components/UI/Inputs/ColorPatchPicker/colorPatches';
import toasters from '../../../../components/UI/Toasts';
import { useAppSelector } from '../../../../hooks';

export type DocumentCategory = E2U.V1.Models.Type & { project_id?: string }; // TODO: Export to typings package
interface CategoryFormModalProps {
  isOpen?: boolean;
  selectedCategory?: E2U.V1.Models.Type;
  onDismiss: () => void;
  onSaved: (category: DocumentCategory) => void;
}

const CategoryFormModal: React.FC<CategoryFormModalProps> = ({ isOpen, onDismiss, onSaved, selectedCategory }) => {
  const { t } = useTranslation();
  const methods = useForm<DocumentCategory>();
  const selectedProjectId = useAppSelector(state => state.project.selectedProject?.id);

  const updateFormValuesIfApplicable = () => {
    if (!selectedCategory) {
      return;
    }
    if (selectedProjectId) {
      methods.setValue('project_id', selectedProjectId);
    }
    if (selectedCategory.id) {
      methods.setValue('id', selectedCategory.id);
    }
    if (selectedCategory.name) {
      methods.setValue('name', selectedCategory.name);
    }
    if (selectedCategory.color && patchColors.includes(selectedCategory.color)) {
      methods.setValue('color', selectedCategory.color);
    }
  };

  const handleDismiss = () => {
    methods.reset({});
    if (onDismiss) {
      onDismiss();
    }
  };

  const handleSubmit: SubmitHandler<DocumentCategory> = (data) => {
    const call = data.id
      ? networking.put(`/api/v1/document_types/${data.id}`, data)
      : networking.post(`/api/v1/projects/${selectedProjectId}/document_types`, data);
    return toasters.promiseToast(
      call,
      {
        pending: {
          message: t('Saving document category'),
        },
        success: {
          message: t('Document category created!')
        },
        error: {
          message: t('Failed to create document category')
        }
      }
    )
      .catch(
        (err) => {
          Sentry.captureException(err);
        }
      )
      .then(
        (response: E2U.V1.Response.Success<E2U.V1.Models.Type>) => onSaved && onSaved(response.data.data)
      );
  };

  useEffect(() => {
    updateFormValuesIfApplicable();
  }, [selectedCategory, isOpen, selectedProjectId]);

  return (
    <FormProvider {...methods}>
      <BigUp.Modal.InputModal
        modal={{
          isOpen: !!isOpen,
          onDismiss: () => handleDismiss(),
          title: t('Create new category')
        }}
        button={{
          title: t('Create'),
          disabled: methods.formState.isSubmitting || !methods.formState.isValid,
          loading: false,
          type: 'submit',
        }}
        input={[
          {
            id: 'name',
            name: 'name',
            register: 'name',
            customLabel: t('Name'),
            placeholder: t('Enter category name'),
            validation: {
              required: {
                value: true,
                message: t('Category name is required')
              }
            }
          },
          {
            name: 'color',
            customLabel: t('Color'),
            placeholder: t('Pick a color'),
            is_color_patch_picker: true,
            validation: {
              required: {
                value: true,
                message: t('Color is required')
              }
            }
          }
        ]}
        onSubmit={methods.handleSubmit(handleSubmit)}
        maxHeight={'380px'}
        textAlignment={'left'}
      />
    </FormProvider>
  );
};

export default CategoryFormModal;
