import { IonAccordion, IonAccordionGroup, IonIcon, IonLabel } from '@ionic/react';
import { chevronDownOutline, chevronForwardOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';

import type { MultiSelectorItemProps } from './interfaces';
import styles from './MultiSelector.module.scss';
import MultiSelectorItem from './MultiSelectorItem';

const MultiSelectorParent: React.FC<{
  parent: MultiSelectorItemProps;
  items: MultiSelectorItemProps[];
  selectedItems: MultiSelectorItemProps[] | [];
  callbacks: {
    updateSelection: (value: any) => void;
  }
}> = (props) => {
  const { items, parent } = props;

  const ref = useRef<HTMLAnchorElement>(null);
  const [currentlyOpened, setCurrentlyOpened] = useState<string | undefined>(undefined);

  const toggleChildren = () => {
    ref.current?.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();

      setCurrentlyOpened(
        currentlyOpened => (typeof currentlyOpened === 'undefined' || currentlyOpened !== parent.value) ? parent.value : undefined
      );

      return false;
    });
  };

  useEffect(() => {
    toggleChildren();
  }, [ref]);

  return (
    <>
      <MultiSelectorItem
        item={parent}
        selectedItems={props.selectedItems}
        toggleValue={props.callbacks.updateSelection}>
        <IonLabel>
          <a href="#" ref={ref}>
            <IonIcon
              icon={currentlyOpened === parent.value ? chevronDownOutline : chevronForwardOutline}
              className={styles.icon} slot="start" />
            {parent.label}
          </a>
        </IonLabel>
      </MultiSelectorItem>
      <IonAccordionGroup value={currentlyOpened}>
        <IonAccordion value={parent.value} toggleIconSlot="start">
          <div slot="content">
            {items.length && items?.map((child, index) => (
              <MultiSelectorItem
                key={index}
                item={child}
                selectedItems={props.selectedItems}
                toggleValue={props.callbacks.updateSelection} />
            ))}
          </div>
        </IonAccordion>
      </IonAccordionGroup>
    </>
  );
};

export default MultiSelectorParent;
