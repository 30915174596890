import type { E2U } from '@techlove/easy2use-typings';
import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import ImagePreview from './Previewers/Image';
import NotSupported from './Previewers/NotSupported';
import PdfPreview from './Previewers/Pdf';
import MicrosoftOfficeViewer from './Previewers/Xlsx';
import PreviewHeader from './PreviewHeader';
import { networking } from '../../../api/networking';
import SideMenuV2Layout from '../../../components/UI/SideMenu/V2/SideMenuV2Layout';
import toasters from '../../../components/UI/Toasts';
import { MICROSOFT_WEB_VIEWER_FORMATS } from '../../../constants/microsoft';
import { useAppSelector } from '../../../hooks';
import storage from '../../../storage';
import { formatToNiceDate } from '../../../tools/formatDates';

const FilePreviewPage: React.FC = () => {
  const { t } = useTranslation();
  const { file_id } = useParams<{ file_id: string }>();

  const [fileBase64, setFileBase64] = useState<string | null>(null);
  const [fileMetadata, setFileMetadata] = useState<E2U.V1.Models.File & {
    mime_type: string;
  } | null>(null);
  const [cancelToken, setCancelToken] = useState<CancelTokenSource | null>(null);
  const fileTitle = useMemo(() => {
    if (fileMetadata?.name) {
      if (fileMetadata?.updated_at || fileMetadata?.created_at) {
        return `${fileMetadata.name} - ${formatToNiceDate(fileMetadata.updated_at || fileMetadata.created_at)}`;
      }
      return fileMetadata.name;
    }
    return t('Loading file...');
  }, [fileMetadata]);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  const fetchFile: (fileId: string) => Promise<E2U.V1.Response.Success<string>> = (id) =>
    networking.get(`/api/v1/files/${id}/export?base64=true`);

  const fetchMetadata: (cancelToken: CancelTokenSource | null, fileId: string) => Promise<E2U.V1.Response.Success<E2U.V1.Models.File & {
    mime_type: string;
  }>> = (cancelToken, id) => {
    return networking.get(`/api/v1/files/${id}?appends[]=mime_type`, {
      cancelToken: cancelToken?.token,
    });
  };

  const getCachedFile: (idAndTime: string) => Promise<string | undefined> = (s) => storage.get(`file-${s}`);

  const fetchAllFileData = () => {
    if (!file_id) return;
    if (cancelToken) return cancelToken.cancel();

    const newCancelToken = axios.CancelToken.source();
    setCancelToken(newCancelToken);
    toasters.promiseToast(
      new Promise((resolve, reject) => {
        fetchMetadata(cancelToken, file_id)
          .then((response) => {
            setFileMetadata(response.data.data);
            if (response.data.data?.updated_at) {
              const idAndTime = `${file_id}-${DateTime.fromISO(response.data.data.updated_at).toISO()}`;
              getCachedFile(idAndTime)
                .then((cachedFile) => {
                  if (cachedFile) {
                    setFileBase64(cachedFile);
                    resolve({ data: cachedFile });
                  } else {
                    fetchFile(file_id)
                      .then((response) => {
                        setFileBase64(response.data);
                        storage.set(`file-${idAndTime}`, response.data);
                        resolve(response);
                        console.log('Response', response);
                      })
                      .catch(reject);
                  }
                })
                .catch(reject);
            }
          })
          .catch(reject);
      }),
      {
        success: {
          message: t('File fetched successfully'),
        },
        pending: {
          message: t('Fetching file'),
        },
        error: {
          message: t('Error fetching file'),
        },
      }
    );
  };

  useEffect(() => {
    fetchAllFileData();
  }, [file_id]);

  console.log('File base 64', fileBase64);

  return (
    <SideMenuV2Layout title={fileTitle} paddedPage={isDesktop} limitHeight={true}>
      <PreviewHeader fileMetaData={fileMetadata} />
      {(fileBase64 && fileMetadata) && (
        fileMetadata.type === 'image'
          ? (
            <ImagePreview
              file={fileMetadata}
              src={`data:${fileMetadata.mime_type};base64,${fileBase64}`}
            />
          )
          : (
            fileMetadata.type === 'pdf'
              ? (
                <PdfPreview
                  file={fileMetadata}
                  src={`data:${fileMetadata.mime_type};base64,${fileBase64}`}
                />
              )
              : (
                (fileMetadata.filetype && MICROSOFT_WEB_VIEWER_FORMATS.includes(fileMetadata.filetype))
                  ? (
                    <MicrosoftOfficeViewer
                      file={fileMetadata}
                    />
                  )
                  : (
                    <NotSupported file={fileMetadata} />
                  )
              )
          )
      )}
    </SideMenuV2Layout>
  );
};

export default FilePreviewPage;
