import { IonContent } from '@ionic/react';
import { cloudUpload } from 'ionicons/icons';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { NavigatedComponentProps } from './types';
import BigUp from '../../..';
import folder from '../../../../../components/icons/folder/folder.svg';
import { useFileReader } from '../../../../../hooks/useFileReader';
import DragAndDrop from '../../../DragAnddrop/DragAndDrop';
import EmptyList from '../../../EmptyList';
import type { FilePreviewProps } from '../../../Preview/types';

const DeviceFiles: React.FC<NavigatedComponentProps> = (props) => {
  const { error, loading, readFiles, result, thumbnails } = useFileReader();
  const { t } = useTranslation();
  const fileProps = props.componentProps.fileProps;
  const files = fileProps.filesToUpload;

  useEffect(() => {
    if (files && files.length > 0) {
      readFiles(files);
    }
  }, [files, readFiles]);

  return (
    <IonContent
      fullscreen
      className="ion-padding"
      style={{
        '--background': 'var(--color-white)'
      }}
    >
      <DragAndDrop onFilesSelect={(files) => { fileProps.handleFileSelection(files); }}>
        <EmptyList
          icon={{
            icon: cloudUpload,
            size: 80
          }}
          title={t('Click to upload files')}
          message={t('To upload files, click here')}
        />
      </DragAndDrop>
      <BigUp.Preview.PreviewListWrapper toggle={props.componentProps.listToggle ?? false}>
        {(files && files.length !== 0)
          ? (
            files.map((file, index) => {
              const listItemProps: FilePreviewProps = {
                fileName: file.name,
                fileType: file.type,
                fileUrl: thumbnails[index] || '',
                onDelete: () => fileProps.removeFileFromFilesToUpload(file),
                result,
              };
              return (
                <React.Fragment key={file.name}>
                  {!props.componentProps.listToggle
                    ? (
                      loading
                        ? (
                          <BigUp.Preview.PreviewCard
                            fileName={t('loading...')}
                            fileType={t('loading...')}
                            fileUrl=''
                            onDelete={() => { }}
                          />
                        )
                        : <BigUp.Preview.PreviewCard {...listItemProps} />
                    )
                    : <div style={{
                      margin: '16px 0',
                    }}>
                      <BigUp.Preview.PreviewItem {...listItemProps} />
                    </div>
                  }
                </React.Fragment>
              );
            })
          )
          : (
            <EmptyList
              title={t('No files uploaded')}
              message={t('Upload files to create documents')}
              icon={{
                icon: folder,
                size: 100
              }}
            />
          )
        }
        {error && <p>{error.message}</p>}
      </BigUp.Preview.PreviewListWrapper>
    </IonContent>
  );
};

export default DeviceFiles;
