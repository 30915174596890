import { IonIcon, IonItem, IonLabel, IonSkeletonText, IonSpinner, IonText, IonThumbnail } from '@ionic/react';
import { documentAttach, trash } from 'ionicons/icons';

import styles from './Item.module.scss';
import type { FilePreviewProps } from './types';

const PreviewItem: React.FC<FilePreviewProps> = ({
  endLabel,
  fileName,
  fileType,
  fileUrl,
  lines = 'full',
  onClick,
  onDelete,
  previewLoading
}) => {
  return (
    <IonItem
      {...(onClick && {
        onClick: () => onClick(),
        button: true,
        detail: false
      })
      }
      className={`${styles['preview-list-item']} ion-no-padding`} lines={lines} >
      {(previewLoading) && (
        <IonThumbnail slot="start">
          <IonSkeletonText animated={true}></IonSkeletonText>
        </IonThumbnail>
      )}

      {(fileType.includes('image') && !previewLoading) && (
        <IonThumbnail slot="start">
          <img alt={fileName} src={fileUrl} />
        </IonThumbnail>
      )}

      {!fileType.includes('image') && (
        <IonIcon className={styles['fallback-icon']} slot='start' icon={documentAttach} size='large' />
      )}

      <div className={styles['row-container']}>
        <IonText className='ion-text-start'>
          <IonLabel className={`${styles.label__name} ion-no-margin`}>
            <h4>{fileName}</h4>
          </IonLabel>
        </IonText>
        {endLabel && (
          <IonText>
            <IonLabel className={`${styles.label__end} ion-no-margin`}>
              {endLabel}
            </IonLabel>
          </IonText>
        )}
      </div>
      {onDelete && (
        <IonIcon
          onClick={() => onDelete()}
          icon={trash}
          slot="end"
        />
      )}
    </IonItem>
  );
};

export default PreviewItem;
