import type { SourceResult, Sources } from './interface';
import i18n from '../../../i18n';

interface SourceHandler {
  type: Sources;
  uuid: string | undefined;
  title: string | undefined;
  baseType: string | undefined;
  routePrefix: string | undefined;
}
const handleSource = ({ baseType, routePrefix, title, type, uuid }: SourceHandler): SourceResult => {
  switch (type) {
    case 'deviations':
      return {
        name: i18n.t('Deviations'),
        routes: {
          create: `/financials/${uuid}/deviations/create`,
          base: `/financials/${uuid}/deviations`,
        }
      };
    case 'changes':
      return {
        name: i18n.t('Changes'),
        routes: {
          create: `/financials/${uuid}/changes/create`,
          base: `/financials/${uuid}/changes`,
        }
      };
    case 'monitoring':
      return {
        name: i18n.t('Reports'),
        routes: {
          base: `/financials/${uuid}/monitoring`,
        }
      };
    default:
      return {
        name: title ?? '',
        routes: {
          base: `/${baseType ?? 'tools'}/${uuid}${routePrefix ?? ''}/${type}`,
        }
      };
  }
};

const buttonTextMap: Record<string, string> = {
  deviations: i18n.t('Add deviation'),
  changes: i18n.t('Add change'),
};

const types: Sources[] = ['deviations', 'changes'];

export { handleSource, buttonTextMap, types };
