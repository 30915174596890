import { IonCol, IonRow, IonSkeletonText } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useAppSelector } from '../../../../../hooks';
interface PrecalculationListTitleProps {
}

const PrecalculationListTitle: React.FC<PrecalculationListTitleProps> = () => {
  const { t } = useTranslation();
  const selectedProject = useAppSelector(state => state.project?.selectedProject);
  const {
    uuid
  } = useParams<{
    precalculationId: string | undefined,
    uuid: string | undefined,
    historicalPrecalculationId: string | undefined
  }>();

  if (uuid) {
    if (selectedProject?.name) {
      return (<>
        {t('Precalculations for {name}', {
          name: selectedProject?.name
        })}
      </>);
    }
    return (<IonRow className={'ion-no-padding ion-no-margin ion-align-items-center ion-justify-content-start'}>
      <IonCol className={'ion-no-padding ion-no-margin'} size={'auto'}>{t('Precalculations for {name}', { name: '' })}</IonCol>
      <IonCol className={'ion-no-padding ion-no-margin ion-margin-start'} size={'auto'}><IonSkeletonText animated style={{ width: '60px' }} /></IonCol>
    </IonRow>);
  }
  return <>{ t('My precalculations') }</>;
};

export default PrecalculationListTitle;
