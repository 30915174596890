import { IonImg, IonList, IonRouterLink, IonThumbnail } from '@ionic/react';

import styles from './LogoThumbnail.module.scss';
import type { Images } from './LogoThumbnailTypes';

const items: Images[] = [{ src: '', text: 'logo' }];

const LogoThumbnail = () => {
  return (
    <IonList className={styles.thumb_logo_container}>
      {items.map((image, i) => (
        <div key={i}>
          <IonThumbnail slot="start">
            <IonRouterLink href="/">
              <IonImg src={image.src} />
            </IonRouterLink>
          </IonThumbnail>
        </div>
      ))}
    </IonList>
  );
};

export default LogoThumbnail;
