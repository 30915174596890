import { Browser } from '@capacitor/browser';
import { IonCol, IonGrid, IonRow, useIonRouter, useIonViewDidEnter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../../api/networking';
import BigUp from '../../../../components/UI';
import toasters from '../../../../components/UI/Toasts';
import { useAppSelector } from '../../../../hooks';

interface NotSupportedProps {
  file: E2U.V1.Models.File;
}
const NotSupported: React.FC<NotSupportedProps> = ({ file }) => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const selectedProjectId = useAppSelector(state => state.project.selectedProject?.id);

  const downloadFile = () => {
    toasters.promiseToast(
      networking.get(`/api/v1/files/${file.id}/share`),
      {
        pending: {
          message: t('Preparing download'),
        },
        success: {
          message: t('Download started'),
        },
        error: {
          message: t('Failed to start download'),
        },
      }
    )
      .then((response: E2U.V1.Response.Success<{ url: string }>) => {
        Browser.open({ url: response.data.data.url })
          .catch((error) => {
            Sentry.captureException(error);
          });
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  useEffect(() => {
    downloadFile();
  }, [file]);

  const handleNavigateBack = () => {
    if (router.canGoBack()) {
      router.goBack();
      return;
    }
    if (selectedProjectId) {
      router.push(`/tools/${selectedProjectId}/documents`);
    }
    router.push('/tools');
  };

  return (
    <IonGrid className={'ion-padding'}>
      <IonRow>
        <IonCol className={'ion-margin-top'}>
          <BigUp.Label.V2Thick label={t('File preview not supported')} />
          <p>{t('The file is downloaded in a new window to utilize the units built-in viewing capabilities.')}</p>
          <BigUp.Buttons.Secondary
            size={'default'}
            onClick={() => handleNavigateBack()}
          >
            {t('Go back')}
          </BigUp.Buttons.Secondary>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default NotSupported;
