import { IonButton, IonContent, IonHeader, IonImg, IonText } from '@ionic/react';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles/OnboardingFinished.module.scss';
import useFetchProjects from '../../../../hooks/useFetchProjects';
import { setOnboardingStageOne, setOnboardingStageThree, setOnboardingStageTwo } from '../../../../reducers/onboarding';
import store from '../../../../store';
import Curve from '../containers/CurveTop';

interface OnboardingFinishProps {
  toggle: () => void
}

const OnboardingFinish: React.FC<OnboardingFinishProps> = (props) => {
  const { t } = useTranslation();
  const fetchProjects = useFetchProjects();

  const closeOnboarding = () => {
    fetchProjects(true);
    props.toggle();
    store.dispatch(setOnboardingStageOne({}));
    store.dispatch(setOnboardingStageTwo({}));
    store.dispatch(setOnboardingStageThree({}));
  };

  return (
    <>
      <IonHeader className={classNames(styles['finished-header'], 'ion-no-border')} />
      <Curve>
        <IonContent className={classNames(styles['finish-content'])}>
          <div className={styles['finish-container']}>
            <IonText>
              <h3>{t('Finished!')}</h3>
              <h5>{t("You've completed tour onboarding and the form has now been sent to the administration for control")}</h5>
            </IonText>
            <div className={styles['finish-gif']}>
              <IonImg src={''} />
            </div>
            <div className={styles['finish-bottom-container']}>
              <div className={styles['bottom-btn-container']}>
                <IonButton color="onboarding" routerLink='/tools' onClick={closeOnboarding}>
                  {t('finish')}
                </IonButton>
              </div>
            </div>
          </div>
        </IonContent>
      </Curve>
    </>
  );
};
export default OnboardingFinish;
