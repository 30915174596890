import { IonIcon, IonLabel } from '@ionic/react';
import classNames from 'classnames';
import React from 'react';

import type { IndicatorProps, LabelProps } from './interface';
import styles from './Label.module.scss';

const Regular: React.FC<LabelProps> = ({ children, label, ...props }) => {
  return (
    <IonLabel {...props} style={{ fontSize: props.style?.fontSize, color: props.color ?? undefined }}>
      {label}
      {children}
    </IonLabel>
  );
};

const Thick: React.FC<LabelProps> = ({ children, label, ...props }) => {
  return (
    <IonLabel {...props}
      className={`${styles.thickLabel} ${props.className || 'ion-no-margin'}`}
      style={{ color: props.color || 'medium' }}
    >
      {label}
      {children}
    </IonLabel>
  );
};
const V2Thick: React.FC<LabelProps> = ({ icon, label, ...props }) => {
  return (
    <IonLabel {...props} className={`${styles['thick-label-v2']} ion-no-margin`}>
      {label}
      {icon && <IonIcon
        icon={icon.icon}
        className={classNames(styles['thick-label-v2__icon'], icon.onClick && styles['thick-label-v2__icon__clickable'])}
        onClick={(e) => icon.onClick && icon.onClick(e)}
        onMouseOver={(e) => icon.onMouseOver && icon.onMouseOver(e)}
      />}
    </IonLabel>
  );
};

const Indicator: React.FC<IndicatorProps> = ({ indicator, label }) => {
  const { color, shape } = indicator;
  const { end, gap, start } = label;

  return (
    <div className={styles.indicator}>
      {shape !== 'none' && <span className={styles.indicator__shape} style={{ backgroundColor: color }} />}
      <div className={styles.indicator__label} style={{ gap }}>
        <Regular {...start} className={styles['indicator__label-start']} />
        <Regular {...end} className={styles['indicator__label-end']} />
      </div>
    </div>
  );
};

const Label = {
  Regular,
  Thick,
  V2Thick,
  Indicator,
};

export default Label;
