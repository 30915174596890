import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
  isPlatform
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { close } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './DocumentFormModal.module.scss';
import DocumentForm from '../../pages/Document/DocumentForm';

interface DocumentFormModalProps {
  open: boolean;
  onClose: () => void;
  onSaved: (type: E2U.V1.Models.Document) => void;
}

const DocumentFormModal: React.FC<DocumentFormModalProps> = ({ onClose, onSaved, open }) => {
  const { t } = useTranslation();
  return (
    <IonModal
      isOpen={open}
      onIonModalDidDismiss={() => onClose()}
      className={styles.DocumentFormModal}>
      <IonHeader className={'ion-no-border'}>
        <IonToolbar>
          <IonTitle>{t('Create document')}</IonTitle>
          <IonButtons slot="end">
            <IonButton strong={true} onClick={() => onClose()}>
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <DocumentForm
          handleDocumentSaved={(document: E2U.V1.Models.Document) => onSaved(document)}
          handleCloseModal={onClose}
        />
      </IonContent>
    </IonModal>
  );
};

export default DocumentFormModal;
