import { IonCol, IonGrid, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useState } from 'react';

import ZoomableBase from './ZoomableBase';
import { useAppSelector } from '../../../../hooks';

interface ImagePreviewProps {
  src: string;
  file: E2U.V1.Models.File
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ file, src }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const handleIsLoaded = () => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 150);
  };

  return (
    <ZoomableBase amountOfPages={1} isLoading={!file || !src} allPagesLoaded={isLoaded}>
      <IonGrid>
        <IonRow>
          <IonCol
            className={'zoomable-page'}
            {...(!isDesktop ? { size: 'auto' } : {})}
          >
            <img
              src={src}
              alt={file.name}
              width={'100%'}
              onLoad={() => handleIsLoaded()}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </ZoomableBase>
  );
};

export default ImagePreview;
