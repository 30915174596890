import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import type { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { networking } from '../api/networking';
import toasters from '../components/UI/Toasts';

type File = E2U.V1.Models.File;

interface UseFilePreviewsResult {
  fileUrls: Record<string, string>;
  loadingPreview: boolean;
  fetchFile: (file: File) => void;
}

const useFilePreviews = (
  files?: File[],
  errorCallback?: (error: E2U.V1.Response.Error) => void
): UseFilePreviewsResult => {
  const [fileUrls, setFileUrls] = useState<Record<string, string>>({});
  const [loadingPreview, setLoadingPreview] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleError = (error: E2U.V1.Response.Error) => {
    if (errorCallback) {
      errorCallback(error);
    } else {
      toasters.createToast({ message: t('Could not get file') }, 'error');
    }

    Sentry.captureException(error);
  };

  const fetchFile = (file: File) => {
    if (typeof file.id !== 'string') {
      return;
    }
    setLoadingPreview(true);
    networking
      .get(file.export_url + '?base64=true')
      .then((response: AxiosResponse<string>) =>
        setFileUrls((prev) => ({
          ...prev,
          [file.id as string]: `data:${response.headers['Content-Type']};base64,${response.data}`,
        })),
      )
      .catch(handleError)
      .finally(() => {
        setLoadingPreview(false);
      });
  };

  useEffect(() => {
    if (files) {
      files.forEach((file) => fetchFile(file));
    }
  }, [files]);

  return { fileUrls, loadingPreview, fetchFile };
};

export default useFilePreviews;
