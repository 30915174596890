
import { IonCol, IonGrid, IonModal, IonRow, IonSearchbar, IonToolbar, isPlatform } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BigUp from '../..';
import { networking } from '../../../../api/networking';
import { useAppSelector } from '../../../../hooks';
import useCameraUpload from '../../../../hooks/useCameraUpload';
import useFileUpload from '../../../../hooks/useFileUpload';
import useModal from '../../../../hooks/useModal';
import i18n from '../../../../i18n';
import { importData } from '../../../../tools/importData';
import toasters from '../../Toasts';
import ModalContent from './Contents/ModalContent';
import type { TabValues } from './Contents/types';
import styles from './index.module.scss';

interface FileModal {
  endpoint?: string;
  onUpload?: () => void;
  modal: {
    dismiss: () => void;
    isOpen: boolean;
  }
}
const generateHeaderTitle = new Map<TabValues, string>([
  ['my-device', i18n.t('My device')],
  ['bigup', i18n.t('BigUp')],
  ['latest', i18n.t('Latest')],
]);

const FileUpload: React.FC<FileModal> = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState<TabValues>('my-device');
  const [toggle, setToggle] = useState(false);
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const selectedDocumentCategory = useAppSelector(state => state.document.selectedCategory);

  const isDesktop = isPlatform('desktop') ? 'desktop' : 'mobile';
  const { t } = useTranslation();
  const modal1 = useModal();
  const cameraProps = useCameraUpload();
  const fileProps = useFileUpload();

  const categoryTags = useMemo(() => selectedDocumentCategory?.category_tags ?? [], [selectedDocumentCategory]);

  const didPresent = () => {
    if (fileProps.filesToUpload.length === 0 && isPlatform('mobile')) {
      importData(fileProps.handleFileSelection);
    }
  };

  const dismiss = () => {
    props.modal.dismiss();
    fileProps.resetFilesToUpload();
  };

  const handleToasters = (successful: boolean) => {
    if (successful) {
      fileProps.setUploadedFiles([]);
    }
  };

  const createDocumentFromFiles = () => {
    setLoading(true);
    const uploadPromises = fileProps
      .onlyUploadFiles()
      .map((filePromise) =>
        filePromise
          .then((fileResponse) =>
            networking.post(`/api/v1/projects/${selectedProject?.id}/documents`, {
              name: fileResponse.data.data.name,
              description: '',
            })
              .then((response: E2U.V1.Response.Success<E2U.V1.Models.Document>) => {
                const documentId = response.data.data.id;
                const fileId = fileResponse.data.data.id;
                return networking.post(`/api/v1/documents/${documentId}/files/${fileId}`)
                  .then(() => {
                    fileProps.resetFilesToUpload();
                    if (categoryTags && categoryTags.length) {
                      categoryTags.forEach(
                        (tag) => {
                          networking.post(`/api/v1/documents/${documentId}/related_tags/${tag.id}`);
                        }
                      );
                    }
                    dismiss();
                  });
              })
              .finally(() => {
                setLoading(false);
              })
          )
      );

    const allUploads = Promise.allSettled(uploadPromises);

    toasters.promiseToast(
      allUploads,
      {
        pending: {
          message: t('Uploading files'),
        },
        success: {
          message: t('All files uploaded successfully'),
        },
        error: {
          message: t('Some files could not be uploaded'),
        },
      }
    )
      .then((results: PromiseSettledResult<E2U.V1.Models.Document>[]) => {
        const successful = results.every((result) => result.status === 'fulfilled');
        handleToasters(successful);
      })
      .catch((error) => {
        handleToasters(false);
        Sentry.captureException(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    if (currentTab === 'my-device' && props.onUpload) {
      props.onUpload();
      return;
    }
    if (currentTab === 'my-device') {
      createDocumentFromFiles();
    }
  };

  useEffect(() => {
    modal1.closeModal();
    return () => {
      fileProps.resetFilesToUpload();
    };
  }, []);

  return (
    <IonModal
      isOpen={props.modal.isOpen}
      onDidDismiss={() => dismiss()}
      onDidPresent={didPresent}
      backdropDismiss={true}
      className={styles[`file-upload-modal--${isDesktop}`]}
    >
      <BigUp.Modal.ModalHeader
        title={generateHeaderTitle.get(currentTab) || '' as any}
        end={{
          button: {
            title: t('Save'),
            disabled: fileProps.filesToUpload.length === 0 || loading || currentTab !== 'my-device',
            type: 'submit',
          },
          onClick: handleSubmit
        }}
        start={{
          button: {
            title: t('Cancel'),
          },
          onClick: props.modal.dismiss
        }}
      />

      <IonToolbar className='ion-no-padding'
        style={{
          boxShadow: '0px 6.814px 20.814px 0px #F2F2F2',
          '--background': 'white'
        }}>
        {(currentTab === 'bigup' || currentTab === 'latest')
          ? <IonSearchbar
            className={'ion-padding-horizontal ion-no-padding '}
            style={{
              '--box-shadow': '0px 0px 0px 1px #E0E0E0',
              '--background': 'var(--color-white)',
              '--placeholder-color': '#A3A3A3',
              '--icon-color': '#A3A3A3',
              paddingBottom: 0
            }}
            mode={'ios'}
          />
          : null
        }
        <IonGrid className='ion-no-padding ion-padding-horizontal'>
          <IonRow className='ion-justify-content-end'>
            <IonCol size='auto'>
              <BigUp.Buttons.ListToggle
                toggle={toggle}
                handleClick={() => setToggle(!toggle)}
                disable={fileProps.filesToUpload.length === 0 || loading}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>

      <ModalContent
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
        listToggle={toggle}
        cameraProps={cameraProps}
        fileProps={fileProps}
      />

    </IonModal>
  );
};

export default FileUpload;
