import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../api/networking';
import BigUp from '../../../components/UI';
import {
  patchColors,
} from '../../../components/UI/Inputs/ColorPatchPicker/colorPatches';
import toasters from '../../../components/UI/Toasts';
import { useAppSelector } from '../../../hooks';

export type TagGroup = E2U.V1.Models.TagCategory & { project_id?: string }; // TODO: Export to typings package
interface TagGroupFormModalProps {
  isOpen?: boolean;
  selectedCategory?: E2U.V1.Models.TagCategory;
  onDismiss: () => void;
  onSaved: (category: TagGroup) => void;
}

const TagGroupFormModal: React.FC<TagGroupFormModalProps> = ({ isOpen, onDismiss, onSaved, selectedCategory }) => {
  const { t } = useTranslation();
  const methods = useForm<E2U.V1.Models.TagCategory & { project_id: string }>();
  const selectedProjectId = useAppSelector(state => state.project.selectedProject?.id);
  const color = methods.watch('color');

  const updateFormValuesIfApplicable = () => {
    if (!selectedCategory) {
      return;
    }
    if (selectedProjectId) {
      methods.setValue('project_id', selectedProjectId);
    }
    if (selectedCategory.id) {
      methods.setValue('id', selectedCategory.id);
    }
    if (selectedCategory.name) {
      methods.setValue('name', selectedCategory.name);
    }
    if (selectedCategory.color && patchColors.includes(selectedCategory.color)) {
      methods.setValue('color', selectedCategory.color);
    }
  };

  const handleDismiss = () => {
    methods.reset({});
    if (onDismiss) {
      onDismiss();
    }
  };

  const handleSubmit: SubmitHandler<TagGroup> = (data) => {
    const call = data.id
      ? networking.put(`/api/v1/tag_categories/${data.id}`, data)
      : networking.post(`/api/v1/projects/${selectedProjectId}/tag_categories`, data);
    const promise = new Promise((resolve, reject) => {
      call
        .then((response: E2U.V1.Response.Success<E2U.V1.Models.TagCategory>) => {
          if (data.tags && data.tags.length) {
            const tagCalls = data.tags.map((tag) => {
              return networking.post(`/api/v1/tag_categories/${response.data.data.id}/tags`, {
                name: tag
              });
            });
            Promise.all(tagCalls)
              .then(() => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return toasters.promiseToast(
      promise,
      {
        pending: {
          message: t('Saving tag group'),
        },
        success: {
          message: t('Tag group created!')
        },
        error: {
          message: t('Failed to create tag group')
        }
      }
    )
      .catch(
        (err) => {
          Sentry.captureException(err);
        }
      )
      .then(
        (response: E2U.V1.Response.Success<E2U.V1.Models.TagCategory>) => onSaved && onSaved(response.data.data)
      );
  };

  useEffect(() => {
    updateFormValuesIfApplicable();
  }, [selectedCategory, isOpen, selectedProjectId]);

  return (
    <FormProvider {...methods}>
      <BigUp.Modal.InputModal
        scrollY={true}
        modal={{
          isOpen: !!isOpen,
          onDismiss: () => handleDismiss(),
          title: selectedCategory ? t('Change tag group') : t('Create tag group')
        }}
        button={{
          title: selectedCategory ? t('Save') : t('Create'),
          disabled: methods.formState.isSubmitting || !methods.formState.isValid,
          loading: false,
          type: 'submit',
        }}
        input={[
          {
            id: 'name',
            name: 'name',
            register: 'name',
            customLabel: t('Name'),
            placeholder: t('Enter group name'),
            validation: {
              required: {
                value: true,
                message: t('Group name is required')
              }
            }
          },
          {
            name: 'color',
            customLabel: t('Color'),
            placeholder: t('Pick a color'),
            is_color_patch_picker: true,
            validation: {
              required: {
                value: true,
                message: t('Color is required')
              }
            }
          },
          {
            name: 'tags_input',
            customLabel: t('Tags'),
            placeholder: t('Create one or many tags'),
            is_tags_input: true,
            validation: {},
            defaultValue: ''
          }
        ]}
        onSubmit={methods.handleSubmit(handleSubmit)}
        maxHeight={'520px'}
        textAlignment={'left'}
      />
    </FormProvider>
  );
};

export default TagGroupFormModal;
