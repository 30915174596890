import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import BigUp from '..';
import { InputSkeleton } from './Input';
import type { InputProps } from './Input.interface';
import styles from './Input.module.scss';

const OutlinedInput: React.FC<React.PropsWithChildren<InputProps<any>>> = (props) => {
  const methods = useFormContext();

  const errorStyle = useMemo(() => {
    const hasError = !!methods.formState.errors[props.register];

    return {
      '--border-color': hasError ? 'var(--ion-color-danger)' : '',
      '--background': hasError ? 'var(--ion-color-danger-shade)' : '',
    };
  }, [methods.formState.errors[props.register], props.register]);

  const { children, ...rest } = props;

  return (
    <>
      {rest.customLabel && (
        <BigUp.Label.Regular
          label={`${rest.customLabel} ${rest.validation.required ? '*' : ''}`}
          className={`ion-no-margin ${styles['outlined-input-label']}`}
          position='stacked'
          {...(methods.formState.errors[props.register] ? { color: 'danger' } : {})}
        />
      )}
      <InputSkeleton
        showErrorBadge={rest.showErrorBadge || false}
        mode='md'
        fill='outline'
        style={errorStyle}
        className={`${styles['outlined-input']}`}
        {...rest}
      >
        {children}
      </InputSkeleton>
    </>
  );
};

export default OutlinedInput;
