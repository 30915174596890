import type { E2U } from '@techlove/easy2use-typings';
import { useParams, useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useThrottledCallback } from 'use-debounce';

import { networking } from '../api/networking';
import { useAppSelector } from '../hooks';
import { setIsLoading } from '../reducers/loading';
import { setSelectedProject } from '../reducers/project';
import store from '../store';

const useFetchProject = () => {
  const { uuid: projectUuid, ...rest } = useParams<{ uuid: string | undefined }>();
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const projectIsLoading = useAppSelector((state) => state.loading.isLoading.selectedProject);

  return useThrottledCallback((force = false) => {
    if (projectIsLoading || !projectUuid || (selectedProject?.id === projectUuid && !force)) return;
    store.dispatch(setIsLoading({ name: 'selectedProject', value: true }));

    networking.get(`/api/v1/projects/${projectUuid}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Project>) => {
        store.dispatch(setSelectedProject(response.data.data));
      })
      .catch((error) => {
        console.warn(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'selectedProject', value: false }));
      });
  }, 1000);
};

export default useFetchProject;
