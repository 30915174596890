import { IonTitle } from '@ionic/react';

import type { TitleProps } from './interface';
import styles from './Titles.module.scss';
const Title: React.FC<TitleProps> = ({ children, label, ...props }) => {
  const { size = 'large' } = props;
  return (
    <IonTitle
      {...props}
      {...props.style
        ? { style: props.style }
        : { style: { fontWeight: '600', fontSize: '1.3rem', letterSpacing: '0.14px' } }
      }
      className={`ion-no-padding ${styles[`title-size--${size}`]}`}
      size='large'>{label || children}</IonTitle>
  );
};

export default Title;
