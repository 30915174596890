import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import classNames from 'classnames';
import { add, chevronBack, chevronForward, expandOutline, remove } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useControls } from 'react-zoom-pan-pinch';

import styles from './Controls.module.scss';
import BigUp from '../../../../../components/UI';
import { useAppSelector } from '../../../../../hooks';

interface ControlsProps {
  amountOfPages: number;
  allLoaded: boolean;
  currentScale: number;
  initialScale: number;
}

const Controls: React.FC<ControlsProps> = ({ allLoaded = false, amountOfPages, currentScale = undefined, initialScale = undefined }) => {
  const { zoomIn, zoomOut, zoomToElement } = useControls();
  const [currentPage, setCurrentPage] = useState(1);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const isZoomedIn = useMemo(() => (currentScale && initialScale) && currentScale > initialScale, [currentScale]);

  const zoom = (selector: string) => {
    const element = document.querySelector(selector);
    if (element) {
      zoomToElement(element as HTMLElement);
    }
  };

  const zoomToCurrentPage = () => {
    zoom(`.zoomable-page:nth-child(${currentPage})`);
  };

  useEffect(() => {
    zoomToCurrentPage();
  }, [currentPage, isDesktop, amountOfPages, allLoaded]);

  const handlePageChange = (direction: 'prev' | 'next') => {
    if (!amountOfPages || amountOfPages === 1) {
      return;
    }
    if (direction === 'prev' && currentPage > 0) {
      setCurrentPage(currentPage - 1);
    } else if (direction === 'next' && currentPage < amountOfPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleResetZoom = () => {
    zoomToCurrentPage();
  };

  return (<IonGrid className={styles['controls-container']}>
    <IonRow className={`ion-justify-content-evenly ${styles['controls-item']}`}>
      {(amountOfPages && amountOfPages > 1) && <>
        <IonCol size={'auto'} className='ion-padding-start'>
          <IonIcon
            onClick={() => handlePageChange('prev')}
            icon={chevronBack}
            role={'button'}
            className={classNames(styles['controls-icon'], currentPage === 0 && styles['controls-icon__disabled'])}
          />
        </IonCol>
        <IonCol size={'auto'}>
          <IonIcon
            onClick={() => handlePageChange('next')}
            icon={chevronForward}
            role={'button'}
            className={classNames(styles['controls-icon'], currentPage === amountOfPages - 1 && styles['controls-icon__disabled'])}
          />
        </IonCol>
      </>}
      <IonCol size={'auto'}>
        <IonIcon
          onClick={() => zoomIn()}
          icon={add}
          role={'button'}
          className={styles['controls-icon']}
        />
      </IonCol>
      <IonCol size={'auto'}>
        <IonIcon
          onClick={() => handleResetZoom()}
          icon={isZoomedIn ? BigUp.Icons.Svg.Preview.ZoomOut : BigUp.Icons.Svg.Preview.ZoomIn}
          role={'button'}
          className={styles['controls-icon']}
        />
      </IonCol>
      <IonCol size={'auto'} className='ion-padding-end'>
        <IonIcon
          onClick={() => zoomOut()}
          icon={expandOutline}
          role={'button'}
          className={styles['controls-icon']}
        />
      </IonCol>
    </IonRow>
  </IonGrid>);
};

export default Controls;
