import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useState } from 'react';

import BigUp from '../../../components/UI';
import useFilePreviews from '../../../hooks/useFilePreview';
import CategoryTitleColumn from '../Category/CategoryTitleColumn';

const FilePreviewColumn: React.FC<{
  attributes: E2U.V1.Models.File;
}> = (props) => {
  const { attributes } = props;
  const [failedToFetch, setFailedToFetch] = useState<boolean>(false);
  const { fetchFile, fileUrls, loadingPreview } = useFilePreviews([], () => setFailedToFetch(true));

  useEffect(() => {
    fetchFile(attributes);
  }, [attributes.id]);

  return failedToFetch
    ? <CategoryTitleColumn attributes={attributes} value={attributes.name} />
    : (
      <BigUp.Preview.PreviewItem
        lines='none'
        fileName={attributes.name}
        fileType={attributes.type}
        previewLoading={loadingPreview}
        fileUrl={fileUrls[attributes.id as string]}
      />
    );
};

export default FilePreviewColumn;
