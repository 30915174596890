import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect } from 'react';

import { networking } from '../../../../api/networking';
import SkeletonTextThreeLines from '../../../../components/SkeletonComponents/SkeletonTextThreeLines';
import { useAppSelector } from '../../../../hooks';
import { setIsLoading } from '../../../../reducers/loading';
import store from '../../../../store';

interface MicrosoftOfficePreviewProps {
  file: E2U.V1.Models.File
}

const MicrosoftOfficePreview: React.FC<MicrosoftOfficePreviewProps> = ({ file }) => {
  const isLoadingTempLink = useAppSelector(state => state.loading.isLoading.tempLink);
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  const [embedUrl, setEmbedUrl] = React.useState<string | undefined>(undefined);

  const generateTemporaryLink = () => {
    if (!file) {
      return;
    }
    store.dispatch(setIsLoading({ name: 'tempLink', value: true }));
    setEmbedUrl(undefined);
    networking.get('/api/v1/files/' + file.id + '/share')
      .then((response: E2U.V1.Response.Success<{
        url: string;
      }>) => {
        setEmbedUrl(`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
          response.data.data.url
        )}`);
      })
      .catch((err) => {
        Sentry.captureException(err);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'tempLink', value: false }));
      });
  };

  useEffect(() => {
    generateTemporaryLink();
  }, []);

  return (
    <div style={{
      width: '100%',
      height: isDesktop
        ? '90%'
        : '100%'
    }}>
      {(!embedUrl || isLoadingTempLink)
        ? <SkeletonTextThreeLines />
        : (
          <iframe
            src={embedUrl}
            width="100%"
            height="100%"
            frameBorder="0"
            title="Excel Viewer"
          />
        )}
    </div>
  );
};

export default MicrosoftOfficePreview;
