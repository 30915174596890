import { IonCol, IonGrid, IonLabel, IonListHeader, IonRow, useIonViewWillEnter } from '@ionic/react';
import { addSharp, chatboxOutline } from 'ionicons/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import type { WorkPreparation, WorkPreparationRows } from '..';
import { networking } from '../../../../api/networking';
import BigUp from '../../../../components/UI';
import EmptyList from '../../../../components/UI/EmptyList';
import { useAppSelector } from '../../../../hooks';
import type { CameraUploadResult } from '../../../../hooks/useCameraUpload';
import type { FileUploadResult } from '../../../../hooks/useFileUpload';

interface SectionProps {
  selectedRows?: any[];
  cameraProps: CameraUploadResult;
  fileProps: FileUploadResult;
}

const Sections: React.FC<SectionProps> = (props) => {
  const [sections, setSections] = useState<any[]>([]);
  const [rowState, setRowState] = useState<{
    [key: string]: boolean
  }>({});
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const history = useHistory();
  const { t } = useTranslation();
  const methods = useFormContext<WorkPreparation>();
  const rows = methods.watch('rows');

  const getProjectNodes = () => {
    if (!selectedProject?.id) return;
    networking.get(`/api/v1/projects/${selectedProject?.id}/work_preparation_users_choices`)
      .then((res) => {
        const nodes = res.data.data.map((record: any) => record.sections);
        const flattenedSections = nodes.flat();
        setSections(flattenedSections);
        const rowStateFromForm = rows.reduce((acc: any, row: any) => {
          acc[row.id] = { isModalOpen: false };
          return acc;
        }, {});
        setRowState(rowStateFromForm);

        setSections(res.data.data);
      });
  };

  const toggleModal = (id: string, isOpen: boolean) => {
    setRowState({
      ...rowState,
      [id]: isOpen
    });
  };

  const handleAssessmentRedirect = (sectionID: string) => {
    if (selectedProject?.id) {
      history.push({
        pathname: `/tools/work-preparation/assessments/${selectedProject?.id}`,
        state: { section: sectionID },
      });
    }
  };

  const handleRowChecked = (rowIndex: number, row: any) => {
    const newRows = [...rows];
    if (rowIndex === -1) {
      newRows.push({
        id: row.id,
        active: true,
        description: '',
        files: []
      });
    } else {
      newRows[rowIndex].active = !newRows[rowIndex].active;
    }
    methods.setValue('rows', newRows);
  };

  const items = (passedRows: any[], requiredNote: boolean) => passedRows.map((row: any, index) => {
    const existingRowIndex = rows.findIndex((r: any) => r.id === row.id);
    const existingRow = rows[existingRowIndex];
    const noteReq = requiredNote;
    return {
      id: row.id,
      content: (
        <>
          <div style={ {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            paddingBottom: '8px',
            paddingTop: '8px'
          } }>
            <div style={ { display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' } }>
              <BigUp.Checkbox
                style={ { marginRight: 8 } }
                labelPlacement="start"
                labelProps={ {
                  label: (row.name ?? ''),
                } }
                checked={ existingRow?.active }
                handleCheckbox={ () => handleRowChecked(existingRowIndex, row) }
              />
              <br/>
              { existingRow?.active && (
                <BigUp.Buttons.Icon
                  onClick={ () => toggleModal(row.id, true) }
                  fill='clear'
                  className='ion-no-margin'
                  padding={ 20 }
                  icon={ {
                    icon: chatboxOutline,
                    color: existingRow?.description ? 'success' : noteReq ? 'danger' : 'primary',
                    size: 'large',
                  } }
                  color={ 'light' }
                />
              ) }
            </div>
            <IonLabel color='medium' className='ion-no-margin ion-text-nowrap' style={ { width: '80%' } }>
              { existingRow?.description as string }
            </IonLabel>
          </div>
          <BigUp.MediaUploader
            cameraProps={ props.cameraProps }
            fileProps={ props.fileProps }
            title={ row.name }
            id={ row.id }
            index={ index }
            isOpen={ rowState[row.id] ?? false }
            dismiss={ () => toggleModal(row.id, false) }
          />
        </>
      )
    };
  });

  useEffect(() => {
    if (selectedProject?.id) {
      getProjectNodes();
    }
  }, [selectedProject?.id]);

  useIonViewWillEnter(() => {
    getProjectNodes();
  }, [sections]);

  return (
    <>
      {!selectedProject?.id && (
        <EmptyList
          message={t('Please select a project to continue.')}
          title={t('No project selected')}
        />
      )}

      {sections && sections.map((section) => (
        <React.Fragment key={section.id}>
          {section.rows.length === 0
            ? (
              <>
                <div className={'ion-margin-horizontal'}>
                  <IonListHeader className='ion-no-padding' style={{ paddingLeft: 8, paddingBottom: 4, fontSize: '18px' }}>
                    <BigUp.Label.Thick label={section.name} color={'medium'} />
                  </IonListHeader>
                </div>
                <IonGrid>
                  <IonRow className='ion-justify-content-between'>
                    <IonCol size='auto'>
                      <BigUp.Label.Regular label={t('No rows has been selected')} />
                    </IonCol>
                    <IonCol size='auto'>
                      <BigUp.Buttons.Regular
                        title={t('Add {section} assessment', 'Add {section} assessment', { section: section.name })}
                        onClick={() => handleAssessmentRedirect(section.id)}
                        fill='clear'
                        size='small'
                        icon={{
                          icon: addSharp,
                          slot: 'start'
                        }}
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </>
            )
            : (
              <BigUp.ItemList
                header={{ title: t(section.name) }}
                items={items(section.rows, section.required_note)}
                actionButton={{
                  title: t('Add {section} assessment', 'Add {section} assessment', { section: section.name }),
                  onClick: () => handleAssessmentRedirect(section.id),
                }}
              />
            )}

        </React.Fragment>
      ))}
    </>
  );
};

export default Sections;
