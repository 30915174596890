import { IonButton, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { closeSharp } from 'ionicons/icons';
import React, { useEffect } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { networking } from '../../../../api/networking';
import DesktopWrapper from '../../../../components/DesktopWrapper';
import BigUp from '../../../../components/UI';
import EmptyList from '../../../../components/UI/EmptyList';
import toasters from '../../../../components/UI/Toasts';
import { useAppSelector } from '../../../../hooks';
import { useDeleteConfirmation } from '../../../../hooks/useDeleteConfirmation';
import useFileUpload from '../../../../hooks/useFileUpload';
import { setSelectedWorksite } from '../../../../reducers/worksites';
import store from '../../../../store';
import FileSelectionButton from '../../../Tools/ControlOfExecution/AddFilesModal/FileSelectionButton';
import type { Worksite } from '../../worksite';

const LayerUpload: React.FC = () => {
  const colourKey = 'color';
  const colorRef = React.useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const history = useHistory();
  const { worksite_id } = useParams<{ worksite_id: string | undefined }>();
  const { getUploadedFiles, handleFileSelection, onlyUploadFiles } = useFileUpload();
  const { handleDeleteConfirmation } = useDeleteConfirmation({
    confirmButton: {
      text: 'Test'
    }
  });

  const files = getUploadedFiles() ?? [];
  const worksite = useAppSelector(state => state.worksites.selectedWorksite);
  const selectedProject = useAppSelector(state => state.project.selectedProject);

  const methods = useForm<{ layers: Worksite.Layers[] | E2U.V1.Models.WorkSiteLayer[] }>({
    mode: 'onChange',
    defaultValues: {
      layers: [],
    }
  });

  const layers = methods.watch('layers');

  const getWorksiteById = async () => {
    if (!worksite_id) return;
    const searchParams = new URLSearchParams();

    searchParams.append('with[]', 'layers');
    try {
      const response = await networking.get(`/api/v1/work_sites/${worksite?.id}?${searchParams.toString()}`);
      store.dispatch(setSelectedWorksite(response.data.data));
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const removeFile = (fileId: any, layerId: any) => {
    toasters.promiseToast(Promise.all([
      networking.delete(`/api/v1/work_site_layers/${layerId}`),
      networking.delete(`/api/v1/files/${fileId}`)
    ]), {
      pending: t('Deleting layer'),
      success: t('Layer deleted successfully'),
      error: t('Could not delete layer'),
    }).then(() => {
      getWorksiteById();
    });
  };

  const handleFilesChanged = (files: E2U.V1.Models.File[]) => {
    const newLayers = [...layers];
    files.filter((file) => !newLayers.some((layer) => !layer.file
      ? (layer.file_id === file.id)
      : (layer.file.file_hash === file.file_hash)))
      .forEach((file) => {
        newLayers.push({
          order: newLayers.length,
          name: file.name,
          color: '#5d7292',
          file
        });
      });
    methods.setValue('layers', newLayers);
  };

  const handleLayerNameChange = (index: number, name: string) => {
    const newLayers = [...layers];
    newLayers[index].name = name;
    methods.setValue('layers', newLayers);
  };

  const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    toasters.promiseToast(
      new Promise((resolve, reject) => Promise.allSettled(onlyUploadFiles())
        .then((response) => {
          const layersToBeCreated = layers.filter((layer) => !layer.file_id);
          const layersToBeUpdated = layers.filter((layer) => layer.file_id);
          Promise.allSettled([
            ...response.map((result, i) => {
              return new Promise((resolve, reject) => {
                if (result.status === 'fulfilled') {
                  const { value } = result;
                  data.work_site_id = worksite?.id;
                  data.file_id = value.data.data.id;
                  data.name = layersToBeCreated[i].name;
                  data.color = layersToBeCreated[i].color;

                  networking.post(`/api/v1/work_site_layers`, data)
                    .then((response: E2U.V1.Response.Success<E2U.V1.Models.WorkSiteLayer>) => {
                      resolve(response.data.data);
                    }).catch((error) => {
                      reject(error);
                    });
                } else {
                  reject(new Error('Failed to upload file'));
                }
              });
            }),
            ...layersToBeUpdated.map((layer) => {
              return new Promise((resolve, reject) => {
                networking.put(`/api/v1/work_site_layers/${layer.id}`, layer)
                  .then((response: E2U.V1.Response.Success<E2U.V1.Models.WorkSiteLayer>) => {
                    resolve(response.data.data);
                  }).catch((error) => {
                    reject(error);
                  });
              });
            })
          ]).then((response) => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
        })
        .catch((error) => {
          reject(error);
        })
      ),
      {
        pending: t('Saving layers'),
        success: t('Layers has been saved'),
        error: t('Could not save layers')
      }
    )
      .then(() => {
        history.push(`/tools/${selectedProject?.id}/worksites/${worksite?.id}`);
      });
  };

  useEffect(() => {
    if (files.length > 0) {
      handleFilesChanged(files);
    }
  }, [files]);

  useEffect(() => {
    getWorksiteById();
  }, []);

  useEffect(() => {
    if (worksite?.layers) {
      methods.setValue('layers', worksite.layers);
    }
  }, [worksite]);

  return (
    <DesktopWrapper>
      <IonGrid className="ion-padding">
        <IonRow className="ion-margin-top">
          <IonCol className="ion-text-end">
            <FileSelectionButton
              acceptedFileTypes={{
                'image/jpeg': ['.jpg', '.jpeg'],
                'image/png': ['.png'],
              }}
              onFilesSelect={(files: File[]) => handleFileSelection(files)}
              responsiveButton={false}
              label={t('Upload new layers')}
            />
          </IonCol>
        </IonRow>

      </IonGrid>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className='ion-padding'>
          <IonGrid>
            {layers.map((layer, index) => {
              return (
                <>
                  <IonRow key={index} className='ion-align-items-center ion-justify-content-evenly'>
                    <IonCol size='1' className='ion-margin-top ion-margin-end ion-text-start'>
                      <BigUp.ColorPicker
                        width={'60px'}
                        pickerRef={colorRef}
                        pickerId={colourKey}
                        onChange={(e: any) => methods.setValue(`layers.${index}.color`, e.target.value)}
                        value={layer.color}
                        defaultColor={'#000000'}
                      />
                    </IonCol>
                    <IonCol size='7' className='ion-text-start'>
                      <IonItem className='ion-no-padding'>
                        <IonInput
                          label={t('Layer name')}
                          labelPlacement='stacked'
                          value={layer.name}
                          placeholder={t('Layer name')}
                          onIonChange={(e) => handleLayerNameChange(index, e.detail.value!)}
                        />
                      </IonItem>
                    </IonCol>

                    <IonCol size='1' className='ion-text-end ion-margin-top'>
                      <IonIcon icon={closeSharp} size='large' color='medium' role='button' onClick={() => {
                        handleDeleteConfirmation(() => removeFile(layer.file_id, layer.id),
                          t('Confirm delete'),
                          t('Are you sure you want to delete layer {name}?', 'Are you sure you want to delete layer {name}?', {
                            name: layer.name
                          }),
                        );
                      }} />
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-justify-content-end'>
                    <IonCol size='5' className='ion-text-right'>
                      <IonItem className='ion-margin-start' lines='none'>
                        <Controller
                          control={methods.control}
                          name={`layers.${index}.is_visible` as any}
                          defaultValue={1}
                          render={({ field }) => (
                            <BigUp.Checkbox
                              checked={layer?.is_visible === 1}
                              handleCheckbox={(e) => field.onChange(e)}
                              labelPlacement='start'
                            >
                              {t('Active')}:
                            </BigUp.Checkbox>
                          )} />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </>
              );
            })}
          </IonGrid>
          {layers.length === 0
            ? <EmptyList title={t('No layers could be found')} message={''} />
            : (
              <IonGrid className='ion-padding'>
                <IonRow className='ion-justify-content-center'>
                  <IonCol sizeXl='6'>
                    <IonButton type={'submit'} color='secondary' expand={'block'}>
                      {t('Save')}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            )
          }
        </form>
      </FormProvider>
    </DesktopWrapper>
  );
};

export default LayerUpload;
