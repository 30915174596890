import React from 'react';

import BigUp from '../../../index';
import ColorPatchPicker from '../../../Inputs/ColorPatchPicker';
import TagsInput from '../../../Inputs/Tags';
import type { InputPropsWithCustomTypes } from '../interface';

const SingleInput: React.FC<InputPropsWithCustomTypes<any>> = (props) => {
  const {
    defaultValue,
    is_color_patch_picker,
    is_tags_input,
    name,
    validation,
    ...input
  } = props;

  return is_tags_input
    ? <TagsInput
      name={ name }
      defaultValue={ defaultValue }
      customLabel={input.customLabel}
      validation={validation}
    />
    : (is_color_patch_picker
      ? (
        <ColorPatchPicker
          name={ name }
          defaultValue={ defaultValue }
          customLabel={input.customLabel}
          validation={validation}
        />
      )
      : <BigUp.OutlinedInput
        { ...input }
        defaultValue={ defaultValue }
        validation={validation}
      />);
};
export default SingleInput;
