import { IonContent } from '@ionic/react';
import React from 'react';

import styles from './Popover.module.scss';

interface MessagePopover {
  children: React.ReactNode;
}

const Popover: React.FC<MessagePopover> = (props) => {
  const { children } = props;
  return (
    <div className={ 'ion-padding' }>
      { children }
    </div>
  );
};
export default Popover;
