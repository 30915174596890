import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonToolbar } from '@ionic/react';
import { close } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ProjectForm from './ProjectForm';
import DeleteRecord from '../../components/UI/DeleteRecord';
import { ionicColours } from '../../components/UI/variables';
import { useAppSelector } from '../../hooks';
import i18n from '../../i18n';

interface EditProjectProps {
  onCancel?: () => void;
  onDelete?: (id: string) => void;
  inEditMode: boolean;
  onModalClose?: () => void;
}

const EditProject: React.FC<EditProjectProps> = ({
  inEditMode,
  onCancel,
  onDelete,
  onModalClose
}) => {
  const { t } = useTranslation();

  const selectedProject = useAppSelector((state) => {
    return state.project.selectedProject as any;
  });

  return (
    <React.Fragment>
      <IonHeader className={'ion-no-border ion-margin-top  ion-margin-start'}>
        <IonToolbar className='ion-no-padding'>
          <div
            className='ion-text-left'
            style={{ borderLeft: `8px solid ${ionicColours.secondary}` }}
          >
            <h1 className='ios' style={{ paddingLeft: '10px' }}>
              {t('Edit project')}
            </h1>
          </div>

          <IonButtons slot="end" className='ion-margin-end'>
            <IonButton onClick={onModalClose}>
              <IonIcon
                icon={close}
                color={'dark'}
                size='large'
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <React.Fragment>
        <IonContent>
          <ProjectForm
            onCancel={onCancel}
            onModalClose={onModalClose}
          >
          </ProjectForm>
          <DeleteRecord
            label={i18n.t('Do you want to delete this project?')}
            record={selectedProject?.id}
            onDelete={() => { onDelete && onDelete(selectedProject.id); }}
          />
        </IonContent>
      </React.Fragment>
    </React.Fragment>
  );
};

export default EditProject;
