import {
  IonCol,
  IonRippleEffect,
  IonRow,
  IonText, useIonRouter
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { useAppSelector } from '../../../../../hooks';
import useAcl from '../../../../../hooks/useAcl';
import { setSelectedProject } from '../../../../../reducers/project';
import store from '../../../../../store';
import { generateNameColor } from '../../../../../tools/generateColor';
import styles from '../SideMenuV2.module.scss';

interface SideMenuV2SubmenuProps {
  submenuVisible?: boolean
}

const ToolsSubmenu: React.FC<SideMenuV2SubmenuProps> = (props) => {
  const router = useIonRouter();
  const location = useLocation();
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);
  const { clientAbilities, projects } = useAcl();
  const { t } = useTranslation();

  const checkRoutePath = (id: string) => {
    if (selectedProjectId && location.pathname.includes(selectedProjectId)) {
      return location.pathname.replace(selectedProjectId, id);
    }
    return '/tools/';
  };

  const projectsWithRoute: (E2U.V1.Models.Project & {
    active: boolean;
    route: string;
    fallback_color: string;
  })[] = useMemo(() => (projects ?? []).map((project) => ({
    ...project,
    route: checkRoutePath(project?.id ?? ''),
    active: selectedProjectId === project.id,
    fallback_color: generateNameColor(project?.id ?? ''),
  })), [projects, selectedProjectId]);

  return (
    <>
      {clientAbilities.includes('can_create_projects') && (
        <IonRow
          className={classNames('ion-no-margin', styles['side-menu--sub--row'])}
          onClick={() => router.push('/tools/create-project')}
        >
          <IonCol
            className={
              classNames(
                'ion-activatable ripple-parent',
                styles['side-menu--sub--item'],
              )
            }
            role={'button'}
          >
            <IonRow className={'ion-align-items-center'}>
              <IonCol size={'auto'}>
                <div
                  className={
                    classNames(styles['side-menu--sub--item--avatar-fallback'])
                  }
                />
              </IonCol>
              <IonCol
                className={classNames(
                  'ion-no-padding',
                  styles['side-menu--sub--item--title-holder']
                )}
              >
                <IonText className={styles['side-menu--sub--item--title']}>
                  <p className={'ion-no-margin'}>{t('Create project')}</p>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRippleEffect />
          </IonCol>
        </IonRow>
      )}
      {(projectsWithRoute.length > 0) && projectsWithRoute.map((project, i) =>
        <IonRow
          key={i}
          className={classNames('ion-no-margin', styles['side-menu--sub--row'])}
          onClick={() => {
            store.dispatch(setSelectedProject(project));
            router.push(project.route);
          }
          }
        >
          <IonCol
            className={classNames(
              'ion-activatable ripple-parent',
              styles['side-menu--sub--item'],
              project.active ? styles['side-menu--sub--item--active'] : ''
            )}
            role={'button'}
          >
            <IonRow className={'ion-align-items-center'}>
              <IonCol size={'auto'}>
                <div
                  className={classNames(styles['side-menu--sub--item--avatar-fallback'])}
                  style={{ backgroundColor: project.fallback_color }}
                />
              </IonCol>
              <IonCol className={classNames('ion-no-padding', styles['side-menu--sub--item--title-holder'])}>
                <IonText className={styles['side-menu--sub--item--title']}>
                  <p className={'ion-no-margin'}>{project.name}</p>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRippleEffect />
          </IonCol>
        </IonRow>
      )}
    </>
  );
};

export default ToolsSubmenu;
