import { IonList } from '@ionic/react';
import React from 'react';

import styles from './Wrapper.module.scss';

interface PreviewListWrapperProps {
  children: React.ReactNode;
  toggle?: boolean;
}

const PreviewListWrapper: React.FC<PreviewListWrapperProps> = (props) => {
  return (
    <IonList
      className={`${props.toggle
        ? styles['preview-list']
        : styles['preview-card-grid']
      } ion-margin-top`}
    >
      {props.children}
    </IonList>
  );
};

export default PreviewListWrapper;
