import { IonCol, IonGrid, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { add, layersSharp, pencilSharp } from 'ionicons/icons';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { networking } from '../../api/networking';
import DesktopWrapper from '../../components/DesktopWrapper';
import PaginateData from '../../components/Pagination/PaginationData';
import toasters from '../../components/Toasts/Toasts';
import BigUp from '../../components/UI';
import EmptyList from '../../components/UI/EmptyList';
import ItemShadowNoIcons from '../../components/UI/Items/components/ItemShadowNoIcons';
import { useAppSelector } from '../../hooks';
import { setIsLoading } from '../../reducers/loading';
import { setSelectedWorksite, setWorksites, setWorksitesPaginationData } from '../../reducers/worksites';
import store from '../../store';

const WorksitePage: React.FC = () => {
  const { t } = useTranslation();
  const isLoading = useAppSelector(state => state.loading.isLoading.worksites);
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const worksites = useAppSelector(state => state.worksites.worksites);
  const history = useHistory();
  const worksitePaginationData = useAppSelector(state => state.worksites.worksitePaginationData);

  const fetchWorksites = (page = 0) => {
    if (!selectedProject) return;
    store.dispatch(setIsLoading({ name: 'worksites', value: true }));
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('page', page.toString());
    urlSearchParams.append('with', 'layers');
    urlSearchParams.append('per_page', '10');
    networking.get(`/api/v1/projects/${selectedProject?.id}/work_sites?${urlSearchParams.toString()}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.WorkSite>>) => {
        store.dispatch(setWorksites(response.data.data.records));
        store.dispatch(setWorksitesPaginationData({
          current_page: response.data.data.current_page,
          total_pages: response.data.data.total_pages
        }));
      })
      .finally(() => store.dispatch(setIsLoading({ name: 'worksites', value: false })));
  };

  const redirectToEdit = (worksite: E2U.V1.Models.WorkSite['id']) => {
    history.push(`/tools/${selectedProject?.id}/worksites/${worksite}`);
    store.dispatch(setSelectedWorksite(worksite));
  };

  const handleMapRedirect = (worksite: E2U.V1.Models.WorkSite & { layers?: any }) => {
    if (!worksite.layers.length) {
      toasters.warning(t('Workspace has no layers.'));

      return;
    }

    store.dispatch(setSelectedWorksite(worksite));
    history.push(`/tools/${selectedProject?.id}/map/${worksite.layers[0].id}`);
  };

  useEffect(() => {
    fetchWorksites();
    store.dispatch(setSelectedWorksite(undefined));
  }, []);

  useEffect(() => {
    fetchWorksites();
  }, [selectedProject]);

  const handlePaginateWorksites = (step: number) => {
    const newPage = (worksitePaginationData?.current_page ?? 0) + step;
    store.dispatch(setWorksites([]));
    store.dispatch(setWorksitesPaginationData({
      ...worksitePaginationData,
      current_page: newPage
    }));
    fetchWorksites(newPage);
  };

  return (
    <DesktopWrapper>
      <IonGrid className='ion-padding-top ion-margin-top'>
        <IonRow>
          <IonCol>
            <BigUp.Title label={t('Worksites')} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol
            size='12'
            className='ion-text-right ion-margin-bottom ion-margin-right ion-no-padding'
          >
            <BigUp.Buttons.Secondary
              title={t('Add Worksite')}
              size='default'
              icon={{
                icon: add
              }}
              onClick={() => history.push(`/tools/${selectedProject?.id}/worksites/add`)}
            />
          </IonCol>
        </IonRow>
        {(worksites && worksites.length === 0) && !isLoading &&
          <EmptyList title={t('No worksites are available')} message={t('Create a new one to upload worksite maps and files')} />
        }
        {worksites?.map((worksite, i) => {
          return (
            <>
              <IonRow key={i} className='ion-align-items-center'>
                <IonCol sizeXl='12'>
                  <ItemShadowNoIcons
                    label={worksite.name}
                    subLabel={worksite.description}
                    description=''
                    hasIconLeft
                    deleteIcon={pencilSharp}
                    iconLeft={layersSharp}
                    onItemClick={() => handleMapRedirect(worksite)}
                    deleteHandler={() => redirectToEdit(worksite.id)}
                  />
                </IonCol>
              </IonRow>

            </>
          );
        })}
        {(worksites && worksites.length !== 0) && !isLoading && (
          <IonRow className='ion-margin-top'>
            <IonCol size={'12'}>
              {(worksitePaginationData && typeof worksitePaginationData.current_page !== 'undefined') && <PaginateData
                currentPage={worksitePaginationData.current_page}
                totalPages={worksitePaginationData.total_pages}
                pageStepper={(step) => handlePaginateWorksites(step)}
              />}
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
    </DesktopWrapper>

  );
};
export default WorksitePage;
