import type { IonIcon } from '@ionic/react';
import { IonActionSheet } from '@ionic/react';
import { type ComponentProps } from 'react';

export interface ActionSheetItem {
  value: string;
  label: string;
  onClick: (param?: Event) => void;
  icon?: ComponentProps<typeof IonIcon>;
  role?: string;
}
interface ActionSheetHandling {
  items: ActionSheetItem[];
  actionId: string;
  header?: string;
  height?: `${string}px` | `${string}vh`;
}

const ActionSheet: React.FC<ActionSheetHandling> = (props) => {
  return (
    <IonActionSheet
      style={{
        '--height': props.height || '50vh',
      }}
      trigger={props.actionId}
      header={props.header || ''}

      buttons={
        props.items.map((item) => ({
          text: item.label,
          role: item.value === 'delete'
            ? 'destructive'
            : item.value === 'cancel'
              ? 'cancel'
              : 'default',
          handler: item.onClick,
          icon: item.icon ? item.icon.icon : undefined,
        }))
      }
    />
  );
};

export default ActionSheet;
