export type PatchColors = 'purple' | 'blue' | 'lightblue' | 'green' | 'yellow' | 'orange' | 'red';
export const patchColors: string[] = ['purple', 'blue', 'lightblue', 'green', 'yellow', 'orange', 'red'];

export interface ColorPatch {
  key: PatchColors;
  value: string;
}

export const colorPatches: ColorPatch[] = [{
  key: 'purple',
  value: 'var(--color-patch-purple, linear-gradient(180deg, #AC32FA 0%, #7A32A8 100%))'
}, {
  key: 'blue',
  value: 'var(--color-patch-blue, linear-gradient(180deg, #008EFF 0%, #1469AD 100%))'
}, {
  key: 'lightblue',
  value: 'var(--color-patch-lightblue, linear-gradient(180deg, #00E7FF 0%, #149EAD 100%))'
}, {
  key: 'green',
  value: 'var(--color-patch-green, linear-gradient(180deg, #19E05E 0%, #218E47 100%))'
}, {
  key: 'yellow',
  value: 'var(--color-patch-yellow, linear-gradient(180deg, #FFD900 0%, rgba(203, 177, 27, 0.90) 100%))'
}, {
  key: 'orange',
  value: 'var(--color-patch-orange, linear-gradient(180deg, #F80 0%, rgba(193, 103, 0, 0.90) 100%))'
}, {
  key: 'red',
  value: 'var(--color-patch-red, linear-gradient(180deg, #FF0004 0%, #AD1417 100%))'
}];
