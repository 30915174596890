import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  useIonRouter, useIonViewWillLeave
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { add } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AclCurtain from '../../../components/AclCurtain';
import DocumentTypeFormModal from '../../../components/DocumentTypeFormModal';
import BigUp from '../../../components/UI';
import { MultiSelectorGroup } from '../../../components/UI/MultiSelector';
import type { MultiSelectorGroupValuesProps } from '../../../components/UI/MultiSelector/interfaces';
import SideMenuV2Layout from '../../../components/UI/SideMenu/V2/SideMenuV2Layout';
import { useAppSelector } from '../../../hooks';
import type { SourceUrlProps } from '../../../interfaces/SourceUrlProps';
import { formatToNiceDate } from '../../../tools/formatDates';
import CategoryTitleColumn from '../Category/CategoryTitleColumn';
import FilePreviewColumn from '../Columns/FilePreviewColumn';
import { DocumentActivityCodeFilter, DocumentCategoryFilter, DocumentExtensionCodeFilter } from '../Filters';
import { getDocumentRoute } from './documentSwitch';

const DocumentsListTable: React.FC = () => {
  const { t } = useTranslation();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const projectId = useMemo(() => selectedProject && selectedProject.id, [selectedProject]);
  const router = useIonRouter();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [activeFilters, setActiveFilters] = useState<MultiSelectorGroupValuesProps>({});
  const [lastTypesFetch, setLastTypesFetch] = useState<number | undefined>(undefined);
  const [createTypeModalOpen, setCreateTypeModalOpen] = useState<boolean>(false);
  const [previousDataSourceUrl, setPreviousDataSourceUrl] = useState<SourceUrlProps | undefined>(undefined);

  const defaultUrl = useMemo(() => {
    if (!projectId) {
      return undefined;
    }
    return {
      url: `/api/v1/projects/${projectId}/document_types`,
      args: {
        per_page: 9999,
      },
    };
  }, [projectId]);

  const dataSourceUrl = useMemo(() => {
    if (!projectId) {
      return undefined;
    }

    let args = { search: searchQuery };
    if (
      previousDataSourceUrl !== undefined &&
      typeof previousDataSourceUrl === 'object' &&
      typeof previousDataSourceUrl.args !== 'undefined'
    ) {
      args = { ...previousDataSourceUrl.args, ...args };
    }

    if (activeFilters) {
      args = { ...args, ...activeFilters };
    }

    return (searchQuery.length || Object.keys(activeFilters).length)
      ? {
        args,
        url: `/api/v1/projects/${projectId}/documents/files`,
      }
      : defaultUrl;
  }, [searchQuery, previousDataSourceUrl, activeFilters, projectId, defaultUrl]);

  const handleSearch = (query: string, currentUrl: SourceUrlProps | string) => {
    if (!query.length) {
      if (!searchQuery.length) {
        return;
      }

      setSearchQuery(query);
    } else {
      setSearchQuery(query);
    }

    if (typeof currentUrl === 'string') {
      setPreviousDataSourceUrl(undefined);
      return;
    }
    setPreviousDataSourceUrl(currentUrl);
  };

  const handleTypeSaved = () => {
    setLastTypesFetch(Date.now());
    setCreateTypeModalOpen(false);
  };

  useIonViewWillLeave(() => {
    setSearchQuery('');
    setActiveFilters({});
  });

  return (
    <SideMenuV2Layout title={t('Documents')} showDocumentActionsButton={true}>
      <IonGrid>
        <IonRow className={'ion-wrap-reverse'}>
          <IonCol>
            <IonGrid>
              {isDesktop && (
                <IonRow>
                  <IonCol>
                    <BigUp.Title label={t('Documents')} />
                  </IonCol>
                </IonRow>
              )}
              <IonRow className={'ion-align-items-center ion-justify-content-between'}>
                <IonCol>
                  <BigUp.Label.V2Thick label={t('Categories')} />
                </IonCol>
                <AclCurtain requiredAbilities={['can_view_document_types_in_projects', 'can_update_document_types_in_projects']}>
                  <IonCol size='auto'>
                    <IonButton onClick={() => router.push(`/tools/${selectedProject?.id}/settings/document-types`)} fill='clear'>
                      <IonIcon slot='icon-only' icon={BigUp.Icons.Svg.Settings} />
                    </IonButton>
                  </IonCol>
                </AclCurtain>
              </IonRow>
            </IonGrid>
            {(dataSourceUrl) && <BigUp.Table
              sourceUrl={dataSourceUrl}
              columns={
                [{
                  key: 'name',
                  label: t('Name'),
                  alignment: 'left',
                  sizes: {
                    xs: '7',
                    sm: '7',
                    md: '8',
                    lg: '9',
                    xl: '9'
                  },
                  sortable: true,
                  body: (row: E2U.V1.Models.Type | E2U.V1.Models.File) => {
                    return 'filetype' in row
                      ? <FilePreviewColumn attributes={row} />
                      : <CategoryTitleColumn />;
                  }
                }, {
                  key: 'last_document_change_ts',
                  label: t('Last updated'),
                  alignment: 'right',
                  sizes: {
                    xs: '5',
                    sm: '5',
                    md: '4',
                    lg: '3',
                    xl: '3'
                  }
                }]
              }
              filtersCustom={
                <MultiSelectorGroup callbacks={{
                  onSelectionChange: (selection: MultiSelectorGroupValuesProps) => {
                    if (!Object.keys(selection).length && !activeFilters.length) {
                      setPreviousDataSourceUrl(defaultUrl);

                      return;
                    }

                    const filters = Object.entries(selection).reduce((acc, [key, value]) => {
                      return {
                        ...acc,
                        [`filters[${key}]`]: value.join(','),
                      };
                    }, {});

                    setActiveFilters(filters);
                  }
                }}>
                  <DocumentCategoryFilter sourceUrl={{
                    url: `/api/v1/projects/${projectId}/documents/filters`,
                    args: {
                      'only[]': 'categories'
                    },
                  }} />
                  <DocumentActivityCodeFilter sourceUrl={{
                    url: `/api/v1/projects/${projectId}/documents/filters`,
                    args: {
                      'only[]': 'activity_codes'
                    },
                  }} />
                  <DocumentExtensionCodeFilter sourceUrl={{
                    url: `/api/v1/projects/${projectId}/documents/filters`,
                    args: {
                      'only[]': 'extensions'
                    },
                  }} />
                </MultiSelectorGroup>
              }
              reducers={{
                last_document_change_ts: (value: string) => formatToNiceDate(value),
              }}
              callbacks={{
                onSearch: handleSearch,
                onRenderComplete: (rows: E2U.V1.Models.Type[] | E2U.V1.Models.File[], setRows) => {
                  if (rows && rows.length && 'filename' in rows[0]) {
                    setRows(rows);
                  } else {
                    if (searchQuery.length || Object.keys(activeFilters).length) {
                      setRows(rows);
                    } else {
                      setRows([{
                        id: 'uncategorized',
                        name: t('Uncategorized'),
                      },
                      ...rows]);
                    }
                  }
                },
                onRowClick: (row: E2U.V1.Models.Type | E2U.V1.Models.File) => {
                  if ('document' in row) {
                    router.push(`/tools/${projectId}/file/${row.id}`);
                  } else {
                    router.push(`/tools/${projectId}/documents/category/${row.id}`);
                  }
                },
              }}
              timestamp={lastTypesFetch}
            />}

            <DocumentTypeFormModal
              open={createTypeModalOpen}
              onClose={() => setCreateTypeModalOpen(false)}
              onSaved={() => handleTypeSaved()}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </SideMenuV2Layout>
  );
};

export default DocumentsListTable;
