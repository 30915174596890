import { IonSpinner } from '@ionic/react';
import { checkmarkCircleSharp, closeCircleSharp } from 'ionicons/icons';
import { toast } from 'react-toastify';

import { ToastIcon, getDefaultToastConfig } from './config';
import type { UpdateToastersOptions } from './interface';

const promiseToast = (promise: Promise<any>, options: UpdateToastersOptions) => {
  const { error, pending, success } = options;
  const config = {
    error: {
      message: error?.message,
      icon: error?.icon || closeCircleSharp,
      iconColour: error?.iconColour || 'danger',
      background: error?.background || 'var(--ion-color-light)',
      textColour: error?.textColour || 'var(--ion-color-medium)',

    },
    success: {
      message: success?.message,
      icon: success?.icon || checkmarkCircleSharp,
      iconColour: success?.iconColour || 'success',
      background: success?.background || 'var(--ion-color-light)',
      textColour: success?.textColour || 'var(--ion-color-medium)',

    },
    pending: {
      message: pending?.message,
      icon: <IonSpinner name="circular" />,
      background: pending?.background || 'var(--ion-color-light)',
      textColour: pending?.textColour || 'var(--ion-color-medium)',
    },
  };

  return toast.promise<any>(promise, {
    error: {
      ...getDefaultToastConfig(
        config.error.background,
        config.error.textColour
      ),
      render: () => config.error.message,
      icon: () => ToastIcon(config.error.icon, config.error.iconColour),
    },
    success: {
      ...getDefaultToastConfig(
        config.success.background,
        config.success.textColour
      ),
      render: () => config.success.message,
      icon: () => ToastIcon(config.success.icon, config.success.iconColour),
    },
    pending: {
      ...getDefaultToastConfig(
        config.pending.background,
        config.pending.textColour
      ),
      render: () => config.pending.message,
      icon: () => config.pending.icon,
    }
  });
};

export default promiseToast;
