import { IonIcon } from '@ionic/react';
import React from 'react';

import type { MultiSelectorItemProps } from './interfaces';
import Avatar, { type AvatarProps } from '../../Avatar';

const generateIcon = (props: {
  icon: MultiSelectorItemProps['icon'],
  className?: string,
  slot?: string | undefined
}) => {
  let extraProps = {};
  const { className, icon, slot } = props;

  if (slot) {
    extraProps = Object.assign(extraProps, { slot }); // @todo: having slot="undefined" causes issues (sometimes)
  }

  if (icon) {
    if (React.isValidElement(icon)) {
      return React.cloneElement(icon, { className });
    } else {
      if (typeof icon === 'string') {
        return <IonIcon icon={icon} className={className} {...extraProps} />;
      } else if ('avatarSource' in icon) {
        return <Avatar.AvatarImage {...icon as AvatarProps} className={className} {...extraProps} />;
      }
    }
  }

  return icon;
};

export {
  generateIcon
};
