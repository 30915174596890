import { attachSharp } from 'ionicons/icons';

import BigUp from '../components/UI';

export const checkFileType = (name: string) => {
  let icon;
  const extension = name.split('.').pop();

  switch (extension) {
    case 'pdf':
      icon = BigUp.Icons.Svg.Extensions.Pdf;
      break;
    case 'png':
      icon = BigUp.Icons.Svg.Extensions.Png;
      break;
    case 'jpg':
      icon = BigUp.Icons.Svg.Extensions.Jpg;
      break;
    default:
      icon = attachSharp;
  }
  return icon;
};
