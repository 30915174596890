import { IonCheckbox, IonItem, IonLabel } from '@ionic/react';
import React, { useMemo } from 'react';

import { generateIcon } from './helpers';
import type { MultiSelectorItemProps } from './interfaces';
import styles from './MultiSelector.module.scss';

const MultiSelectorItem: React.FC<React.PropsWithChildren<any> & {
  item: MultiSelectorItemProps;
  selectedItems: MultiSelectorItemProps[] | [];
  toggleValue: (index: number) => void;
}> = (props) => {
  const { item } = props;
  const isChecked = useMemo(() => props.selectedItems.includes(item.value), [props.selectedItems, item.value]);

  const isDisabled = useMemo(() => {
    return item.children?.length
      ? item.children.every((child: MultiSelectorItemProps) => {
        return child?.disabled || false;
      })
      : item?.disabled;
  }, [item, isChecked]);

  const icon = useMemo(() => generateIcon({
    icon: item.icon,
    className: styles.icon
  }), [item.icon]);

  return (
    <IonItem>
      <IonCheckbox
        value={item.value}
        labelPlacement="start"
        disabled={isDisabled}
        checked={isChecked && !isDisabled}
        onIonChange={() => props.toggleValue(item.value)}>
        {props.children
          ? props.children
          : (
            <>
              {icon && icon}
              <IonLabel>{item.label}</IonLabel>
            </>
          )}
      </IonCheckbox>
    </IonItem>
  );
};

export default MultiSelectorItem;
