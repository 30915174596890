import classNames from 'classnames';
import React, { useState } from 'react';
import type { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import Controls from './Controls';
import SkeletonItem from '../../../../components/SkeletonComponents/SkeletonItem';

interface ZoomableBaseProps {
  amountOfPages: number;
  isLoading: boolean;
  allPagesLoaded?: boolean;
}

const ZoomableBase: React.FC<React.PropsWithChildren<ZoomableBaseProps>> = ({ allPagesLoaded = false, amountOfPages, children, isLoading }) => {
  const [currentScale, setCurrentScale] = useState<number>(0.7);

  const onTransformChange = (e: ReactZoomPanPinchRef) => {
    if (e.instance.transformState.scale) {
      setCurrentScale(e.instance.transformState.scale);
    }
  };

  return (
    <>
      {(!isLoading)
        ? <TransformWrapper
          pinch={{
            step: 5
          }}
          minScale={0.5}
          initialScale={0.7}
          limitToBounds={true}
          wheel={{
            step: 10,
            smoothStep: 0.005,
          }}
          onTransformed={onTransformChange}
        >
          {(amountOfPages) && <Controls
            amountOfPages={amountOfPages}
            allLoaded={allPagesLoaded}
            currentScale={currentScale}
            initialScale={0.7}
          />}
          <TransformComponent
            wrapperStyle={{
              width: '100%',
              height: '100%',
            }}
          >
            <div className={classNames('ion-padding')}>
              {children}
            </div>
          </TransformComponent>
        </TransformWrapper>
        : <SkeletonItem amount={5} />}
    </>
  );
};

export default ZoomableBase;
